import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as actions  from '../../actions/roughQuoteActions';
import { Row, Col } from 'reactstrap';
import { FaPlus, FaTrashAlt,FaExclamationTriangle } from 'react-icons/fa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from 'reactstrap';

const defaults = {
  roomName  : '',
  areaFt    : null,
  areaIn    : null,
  widthFt   : '',
  widthIn   : '',
  lengthFt  : '',
  lengthIn  : '',
  flooring  : 'none',
  vertical  : 'top',
  horizontal: 'center'
}

const getLegalCommand = (listRoom) => {
  for (let i = 0; i < listRoom.length; i++) {
    let curRoom  = listRoom[i];
    let widthFt  = parseInt(curRoom.widthFt);
    let widthIn  = parseInt(curRoom.widthIn);
    let lengthFt = parseInt(curRoom.lengthFt);
    let lengthIn = parseInt(curRoom.lengthIn);
         if (curRoom.roomName === ''                                                 ) return 'Room';
    else if ((isNaN(widthFt ) || widthFt  == 0) && (isNaN(widthIn ) || widthIn  == 0)) return 'Width';
    else if ((isNaN(lengthFt) || lengthFt == 0) && (isNaN(lengthIn) || lengthIn == 0)) return 'Length';
    else if (curRoom.flooring === 'none'                                             ) return 'Flooring';
  }
  return '';
}



// ----------------------------
// --- Main Component Class ---
// ----------------------------
class RoughQuoteByRoom extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      listRoom : [{ roomName            : defaults.roomName, 
                    areaFt              : defaults.areaFt, 
                    areaIn              : defaults.areaIn, 
                    widthFt             : defaults.widthFt, 
                    widthIn             : defaults.widthIn, 
                    lengthFt            : defaults.lengthFt, 
                    lengthIn            : defaults.lengthIn, 
                    flooring            : defaults.flooring,
                    average             : null,
                    flooringCost        : null,
                    flooringCostUom     : null,
                    underlaymentCost    : null,
                    underlaymentCostUom : null, 
                 }], // This is defaulted to 1 because we start with one room Panel
      selection: 'none',
      showMessage: false,
      message:''
    };
  }

  componentDidMount    = (         ) => {}
  componentWillUnmount = (         ) => {}
  componentDidUpdate   = (prevProps) => {}

  handleMessage = () => {
    this.setState({showMessage: !this.state.showMessage})
  }
  
  handleSelection = (index, event) => {
    let lstRoom = this.state.listRoom; 
    let room    = lstRoom[index];
    room.flooring = event.target.value;
    this.setState({listRoom: lstRoom})
  }
  
  handlePanelAdd = () => {
    let lstRoom = this.state.listRoom;
    lstRoom.push({ roomName            : defaults.roomName, 
                   areaFt              : defaults.areaFt, 
                   areaIn              : defaults.areaIn, 
                   widthFt             : defaults.widthFt, 
                   widthIn             : defaults.widthIn, 
                   lengthFt            : defaults.lengthFt, 
                   lengthIn            : defaults.lengthIn, 
                   flooring            : defaults.flooring,
                   average             : null,
                   flooringCost        : null,
                   flooringCostUom     : null,
                   underlaymentCost    : null,
                   underlaymentCostUom : null, 
               });
    this.setState({listRoom: lstRoom})
  }

  handleNext = () => {
    let listRoom     = this.state.listRoom;
    let legalCommand = getLegalCommand(listRoom);
         if (legalCommand === 'Room'    ) this.setState({showMessage: true, message: 'Room Name cannot be empty!'      })
    else if (legalCommand === 'Width'   ) this.setState({showMessage: true, message: 'Width cannot be empty or zero!'  })
    else if (legalCommand === 'Length'  ) this.setState({showMessage: true, message: 'Length cannot be empty or zero!' })
    else if (legalCommand === 'Flooring') this.setState({showMessage: true, message: 'Flooring has to be selected!'    })
    else {
      for (let i = 0; i < listRoom.length; i++) {
        let curRoom = listRoom[i];
        if (curRoom.widthIn  === '') curRoom.widthIn  = 0;
        if (curRoom.lengthIn === '') curRoom.lengthIn = 0;
      }
      this.props.updateData(listRoom);
    }
  }

  handleRoomDelete = (index, event) => {
    let lstRoom = this.state.listRoom;
    if (lstRoom.length > 1) {
      lstRoom.splice(index, 1);
      this.setState({ listRoom: lstRoom });
    }
  }

  handleRoomName = (index, event) => {
    let lstRoom = this.state.listRoom; 
    let room    = lstRoom[index];
    room.roomName = event.target.value;
    this.setState({listRoom: lstRoom})
  }

  handleRoomWidthFeet = (index, event) => {
    let newValue = parseInt(event.target.value); 
    let lstRoom  = this.state.listRoom; 
    let room     = lstRoom[index];
         if (!isNaN(newValue) && newValue <= 9999) room.widthFt = newValue;
    else if (event.target.value === ''           ) room.widthFt = defaults.widthFt;
    this.setState({ listRoom: lstRoom })
  }

  handleRoomWidthInches = (index, event) => {
    let newValue = parseInt(event.target.value); 
    let lstRoom  = this.state.listRoom; 
    let room     = lstRoom[index];
         if (!isNaN(newValue) && newValue <= 99) room.widthIn = newValue;
    else if (event.target.value === ''         ) room.widthIn = defaults.widthIn;
    this.setState({ listRoom: lstRoom })
  }

  handleRoomLengthFeet = (index, event) => {
    let newValue = parseInt(event.target.value); 
    let lstRoom  = this.state.listRoom; 
    let room     = lstRoom[index];
         if (!isNaN(newValue) && newValue <= 9999) room.lengthFt = newValue;
    else if (event.target.value === ''           ) room.lengthFt = defaults.lengthFt;
    this.setState({ listRoom: lstRoom })
  }

  handleRoomLengthInches = (index, event) => {
    let newValue = parseInt(event.target.value); 
    let lstRoom  = this.state.listRoom; 
    let room     = lstRoom[index];
         if (!isNaN(newValue) && newValue <= 99) room.lengthIn = newValue;
    else if (event.target.value === ''         ) room.lengthIn = defaults.lengthIn;
    this.setState({ listRoom: lstRoom })
  }

  render() {
    console.log('byRoom = ' , this.props.data);
    if (this.props.data.length > 0) {
      return <Redirect to='/rough-quote-product-details' />
    }

    return ( 
      <div>
          <Snackbar 
            anchorOrigin     = {{ vertical: 'top', horizontal: 'center', }}
            open             = {this.state.showMessage} 
            autoHideDuration = {5000} 
            onClose          = {this.handleMessage}
          >
            <Alert style={{backgroundColor: '#f50057'}}>
              <Typography variant="subtitle1" style={{color:'white',padding:5}} >
                <FaExclamationTriangle style={{marginBottom:5, marginRight:5}}/> { this.state.message }
              </Typography>  
            </Alert>
          </Snackbar>
          <Paper style={{padding:50}}>
            <Typography variant="h5" style={{marginBottom:30}} >
              Please enter the covered area of each room
            </Typography>  
            {
              this.state.listRoom.map((curRoom,i) =>
                <Row key={i}>
                  <Col xs='12' sm='12' md='4' lg='3' xl='3'>
                    <Typography variant="subtitle1">Room</Typography>  
                    <TextField id="outlined-basic" 
                               label="Name" 
                               variant="outlined" 
                               style={{width:'100%',margin:5}}
                               value={ curRoom.roomName } 
                               onChange={ this.handleRoomName.bind(this,i) } />
                  </Col>
                  <Col xs='12' sm='6' md='4' lg='2' xl='2'>
                    <Typography variant="subtitle1">Width</Typography>  
                    <TextField label="Feet"   
                               variant="outlined"
                               style={{width:70, margin:5}} 
                               value={ curRoom.widthFt }
                               onChange={ this.handleRoomWidthFeet.bind(this,i) }/> 
                    <TextField id="outlined-basic" 
                               label="Inches" 
                               variant="outlined"
                               style={{width:70,margin:5}}
                               value={ curRoom.widthIn }
                               onChange={ this.handleRoomWidthInches.bind(this,i) }/>
                  </Col>
                  <Col xs='12' sm='6' md='4' lg='2' xl='2'>
                    <Typography variant="subtitle1">Length</Typography>  
                    <TextField label="Feet"   
                               variant="outlined"
                               style={{width:70, margin:5}} 
                               value={ curRoom.lengthFt } 
                               onChange={ this.handleRoomLengthFeet.bind(this,i) }/> 
                    <TextField label="Inches" 
                               variant="outlined"
                               style={{width:70, margin:5}}
                               value={ curRoom.lengthIn }
                               onChange={ this.handleRoomLengthInches.bind(this,i) }/>
                  </Col>
                  <Col xs='12' sm='8' md='8' lg='3' xl='4'>
                    <Typography variant="subtitle1">Flooring</Typography>
                    <Select
                      value={ curRoom.flooring }
                      onChange={this.handleSelection.bind(this,i)}
                      style={{width:'100%', marginTop:10}}
                    >
                      <MenuItem value='none'>
                        Please Select
                      </MenuItem>
                      <MenuItem value='Rolled Carpet'>
                        Rolled Carpet
                      </MenuItem>
                      <MenuItem value='Rolled Vinyl'>
                        Rolled Vinyl
                      </MenuItem>
                      <MenuItem value='Vinyl Tile (VCT)'>
                        Vinyl Tile (VCT)
                      </MenuItem>
                      <MenuItem value='Vinyl Plank'>
                        Vinyl Plank
                      </MenuItem>
                      <MenuItem value='Laminate'>
                        Laminate
                      </MenuItem>
                      <MenuItem value='Laminate Tile'>
                        Laminate Tile
                      </MenuItem>
                      <MenuItem value='Hardwood'>
                        Hardwood
                      </MenuItem>
                      <MenuItem value='Engineered Hardwood'>
                        Engineered Hardwood
                      </MenuItem>
                      <MenuItem value='Ceramic Tile'>
                        Ceramic Tile
                      </MenuItem>
                    </Select>
                  </Col>
                  {
                    this.state.listRoom.length > 1 ? 
                      <Col xs='12' sm='4' md='4' lg='1' xl='1'>
                        <Button variant='contained' color='secondary' style={{marginTop:20}} onClick={this.handleRoomDelete.bind(this,i)}>
                          <FaTrashAlt style={{marginRight:5}}/> Delete 
                        </Button>
                      </Col>
                    : <div></div>
                  }
                </Row>
              )
            }
            <Row>
              <Col xs='12' sm='4' md='3' lg='4' xl='4'>
                <Button variant='contained' color='primary' style={{marginTop:10}} onClick={ this.handlePanelAdd }>
                  <FaPlus style={{marginRight:5}}/> Add Room 
                </Button>
              </Col>
            </Row>
            <Row style={{marginTop:100}}>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
              <Col xs='8' sm='8' md='6' lg='4' xl='4' style={{textAlign:'center'}}>
                <Link to="/rough-quote" style={{textDecoration:'none'}}>
                  <Button variant='contained' color='secondary' style={{marginRight:5}}>
                    Cancel 
                  </Button>
                </Link>
                <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleNext}>
                    Next
                  </Button>
              </Col>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
            </Row>
          </Paper>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {

  return { data: state.roughQuoteData }     
}

const mapDispatchToProps = dispatch => ({
  updateData: (data) => { return dispatch(actions.updateRoughQuoteData(data)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(RoughQuoteByRoom);
