import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonToolbar,
  ButtonGroup,
  ButtonDropdown,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


const styles = {
  cardHeader: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardRow: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  projectInfo: {
    margin: '0 -1.25rem 1.25rem -1.25rem',
    padding: '0 1.25rem 1.25rem 1.25rem',
    borderBottom: '1px solid #e3e8ec',
  },
  versionBtn: {
    marginRight: '1.25rem',
    borderRadius: '2px',
  },
  rowStyle: {
    padding:5,
    textAlign:'center'
  },
  buttonStyle: {
    padding:5,
    textAlign:'center',
    width:'100%'
  },
  colStyle: {
    padding:5,
    width:'100%',
    textAlign:'center'
  },
  headerStyle: {
    padding:5,
    marginBottom:'0.5em',
    width:'100%',
    textAlign:'center'
  }
};

class CreateRefigure extends Component {

  render() {
    return (
      <Card className='animated'>
        <CardHeader style={styles.cardRow}>
          <i className='material-icons md-18'>chevron_left</i>
          <Link to='/projects'>Projects</Link>
        </CardHeader>

        <CardBody>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs="10" sm="10" md="6" style={styles.headerStyle}>
              <h3>
                Select a Common Refigure Type:
              </h3>
            </Grid>
            <Grid item xs="10" sm="10" md="3" style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle}>
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/#` }>
                  Add or Remove Rooms
                </Link>
              </Button>
            </Grid>
            <Grid item xs="10" sm="10" md="3" style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle} >
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/#` }>
                  Change Lay Direction / Type
                </Link>
              </Button>
            </Grid>
            <Grid item xs="10" sm="10" md="3" style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle}>
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/#` }>
                  Add / Change Pattern Repeat
                </Link>
              </Button>
            </Grid>
            <Grid item xs="10" sm="10" md="3" style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle}>
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/#` }>
                Change Rolled Flooring Width
                </Link>
              </Button>
            </Grid>
            <Grid item xs="10" sm="10" md="3" style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle}>
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/#` }>
                  Change Flooring Product(s)
                </Link>
              </Button>
            </Grid>
            <Grid item xs="10" sm="10" md="3" style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle}>
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em',}} to={`/#` }>
                Add or Remove Labor / Supplies
                </Link>
              </Button>
            </Grid>
            <Grid item xs="10" sm="10" md="3"  style={{padding:5, width:'100%', textAlign:'center'}}>
              <h3>
                - OR -
              </h3>
            </Grid>
            <Grid item xs="10" sm="10" md="3"  style={styles.colStyle}>
              <Button  variant="contained" color="primary" style={styles.buttonStyle}>
                {/*<Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }></Link>*/}
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/#` }>
                Other (Contact PROvider)
                </Link>
              </Button>
            </Grid>
          </Grid>


          <Row style={styles.rowStyle}>
  
          </Row>
         
        </CardBody>
      </Card>
    );
  };
}

CreateRefigure.propTypes = {

};

export default CreateRefigure;
