import moment from 'moment-timezone';

function handleErrors (response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
}

// Returns a string representing the date, time, and timezone
// e.g., 2019-02-01 03:12 PM CST
function getSessionTime () {
  return moment().tz(moment.tz.guess()).format('YYYY-MM-DD LTS zz');
  //return moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss A zz');
}

const api_url = 'https://www.floorsoft.com/FloorWizard/fwapi/session';

class UserApi {
  static login(user, override) {
    user = Object.assign({}, user);
    const url = `${api_url}/logon`;
    const status = override
      ? 'Session Logon Status Request Override'
      : 'Session Logon Status Request Log On';
    const options = {
      'sessionStatus': status,
      'sessionLogon': user.username,
      'sessionPassword': user.password,
      'sessionTime': getSessionTime(),
      'sessionComponent': 'Session Component Refigurator',
      'computerName': 'user',  // This should be removed.. but currently causes an error.
      'computerAddress': '', // This should be removed.. but currently causes an error.
      'softwareVersion': '',
      'active': null,
    }
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options)
    };

    return new Promise((resolve, reject) => {
      fetch(url, settings)
        .then(handleErrors)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  static logout() {
    const url = `${api_url}/logoff`;
    const options = {
      'sessionStatus': 'Session Logon Status Request Log Off',
      'sessionToken': localStorage.getItem('access_token'),
      'sessionTime': getSessionTime()
    };
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options)
    };

    /*const settings = {
      method: 'POST',
      mode:'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'same-origin',
      body: JSON.stringify(options)
    };*/

    return new Promise((resolve, reject) => {
      fetch(url, settings)
        .then(handleErrors)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          throw(err);
        });
    });
  }

  static register(user) {
    /*
    return new Promise((resolve, reject) => {
      fetch('/api/clyp/login', settings)
        .then((response) => {
          return response.json();
        })
        .then((user) => {
          resolve(Object.assign({}, user));
        })
        .catch((err) => {
          throw(err);
        });
    });
    */
  }

}

export default UserApi;
