import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import lowesLogo from "./lowesHeaderLogo.PNG";

import { FaSearch, FaEye, FaEdit, FaClipboardList, FaUserClock, FaBell } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardDeck,
} from 'reactstrap';


import Button from '@material-ui/core/Button';



// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------

const styles = {
  rowStyle: {marginTop:25}
}

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class Details extends Component {
  constructor(props,context) {
    super(props,context);

    this.state = {
    };
  }

 
  // --- Render The Component
  render() {



    // --- Return The Formatted Page
    return ( 
      <div>
        <Row style={styles.rowStyle}>
          <Col style={{textAlign:"center"}}>  
            <img src={lowesLogo}  width="200" height="auto" alt="Lowe's" /> 
          </Col>
        </Row>
        <Row style={styles.rowStyle}>
          <Col lg="3" xl="3"></Col>
          <Col lg="6" xl="6" style={{textAlign:"center"}}>
            <h1>Schedule an In-Home Measure via API or Lowes.com</h1>
          </Col>
          <Col lg="3" xl="3"></Col>
        </Row>
        <Row style={styles.rowStyle}>
          <Col style={{textAlign:"center"}}  >
            <a href="https://www.lowes.com/l/schedule.html">
              <Button  variant="contained" color="primary" className='w-25'>Schedule In-Home Measure</Button>
            </a> 
          </Col>
        </Row>
          
          

      </div>
    );
  }
}


export default connect() (Details);
