import React, { Component } from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Container } from 'reactstrap';

import Header from './common/Header/Header';
import Sidebar from './common/Sidebar/Sidebar';
import NavBar from './common/Header/NavBar';
import Breadcrumb from './common/Breadcrumb';
// import Aside from './common/Aside';
import Footer from './common/Footer';

import Dashboard from './Dashboard/Dashboard';
import ProjectRefigures from './Projects/ProjectRefigures';
import Projects from './Projects/Projects';
import Project from './Projects/Project';
import RefigureDetails from './Projects/RefigureDetails';
import CreateRefigureMenu from './Projects/CreateRefigureMenu';
import ContactLog from './ContactLog/ContactLog.js';
import Alerts from './Alerts/Alerts.js';
import Warnings from './Warnings/Warnings.js';
import Statuses from './Statuses/Statuses';
import Statistics from './Statistics/Statistics.js';
import Details from './Details/Details.js';
import RoughQuoteMenu from './RoughQuote/RoughQuoteMenu';
import RoughQuoteByRoom from './RoughQuote/RoughQuoteByRoom';
import RoughQuoteByProject from './RoughQuote/RoughQuoteByProject';
import RoughQuoteProductDetails from './RoughQuote/RoughQuoteProductDetails';
import RoughQuoteSummary from './RoughQuote/RoughQuoteSummary';

import NotFoundPage from './common/NotFoundPage';
import ServerErrorPage from './common/ServerErrorPage';


const brandPrimary   = '#20a8d8';
const brandSuccess   = '#4dbd74';
const brandInfo      = '#63c2de';
const brandWarning   = '#f8cb00';
const brandDanger    = '#f86c6b';
const brandAttention = 'red';

const styles = {
  mainStyle         : { backgroundColor : 'rgba(0,0,0,0.1)',paddingTop:'15px' },
  fabStyleAttention : { position        :'fixed'           ,bottom:'2%',right:'2%',backgroundColor:brandAttention,color:'white'},
  fabStyleWarning   : { position        :'fixed'           ,bottom:'2%',right:'2%',backgroundColor:brandWarning  ,color:'white'},
  fabStyleNormal    : { position        :'fixed'           ,bottom:'2%',right:'2%',backgroundColor:brandSuccess  ,color:'white'},
};





class Home extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isMenuOpen: false,
    };
  }

  handleFav = () => {
    this.setState( { isMenuOpen : !this.state.isMenuOpen } )
  }

  render() {
    return (
      <div className='app'>
        {/*<Header />*/}
        <NavBar />
        <div className='app-body'>
          {/*<Sidebar {...this.props} />*/}
          <main className='main' style = { styles.mainStyle }>
            {/*<Breadcrumb />*/}
            <Container fluid>
              <Switch>
                <Route path='/dashboard' name='Dashboard' component={Dashboard} />
                <Route path='/projects/:id' name='Project' component={Project} />
                <Route path='/rough-quote' name='RoughQuote' component={RoughQuoteMenu} />
                <Route path='/rough-quote-by-room' name='RoughQuoteByRoom' component={RoughQuoteByRoom} />
                <Route path='/rough-quote-by-project' name='RoughQuoteByProject' component={RoughQuoteByProject} />
                <Route path='/rough-quote-product-details' name='RoughQuoteProductDetails' component={RoughQuoteProductDetails} />
                <Route path='/rough-quote-summary' name='RoughQuoteSummary' component={RoughQuoteSummary} />
                <Route path='/projectRefigures/:id' name='ProjectRefigures' component={ProjectRefigures} />
                <Route path='/create-refigure-menu' name='Create Refigure Menu' component={CreateRefigureMenu} />
                <Route path='/alerts' name='Alerts' component={Alerts} />
                <Route path='/warnings' name='Warnings' component={Warnings} />
                <Route path='/refigureDetails/:refigureId' name='RefigureDetails' component={RefigureDetails} />
                <Route path='/contactLog/:status' name='ContactLog' component={ContactLog} />
                <Route path='/projects' name='Projects' component={Projects} />
                <Route path='/stats' name='Statistics' component={Statistics} />
                <Route path='/details' name='Details' component={Details} />
                <Route path='/status' name='Statuses' component={Details} />
                <Route path='/help' name='Help Center' component={Dashboard} />
                <Route path='/settings' name='Settings' component={Dashboard} />
                <Redirect from='/' to='/dashboard' />
                <Route exact path='/NotFound' name='Not Found Page' component={NotFoundPage} />
                <Route exact path='/ServerError' name='Server Error Page' component={ServerErrorPage} />
              </Switch>
            </Container>

          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
