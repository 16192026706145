import RefigureApi from "../api/refigureApi";
//import { beginAjaxCall } from "./ajaxStatusActions";
import types from "./actionTypes";


export function refigureLoadSuccess(refigures) {
  return { type: types.REFIGURE_LOAD_SUCCESS, refigures };
}

export function refigureLoadFailure(message) {
  return { type: types.REFIGURE_LOAD_FAILURE, message };
}


export function loadRefigures(projectId) {
  return (dispatch) => {
    return RefigureApi.loadRefigures(projectId)
      .then((response) => {
        const msg = response.lstMessages;
        if (msg) { dispatch(refigureLoadFailure(msg[0]                     )); }
        else     { dispatch(refigureLoadSuccess(response.lstProjectRefigure)); }
      })
      .catch((err) => {
        throw(err);
      });
  }
}
