// TODO: remove this
/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types"
import moment from 'moment';

import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ButtonToolbar,
  ButtonGroup,
  ButtonDropdown,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// import ProjectsList from './ProjectsList'

import { Link } from 'react-router-dom';

import * as projectActions  from '../../actions/projectActions';

const styles = {
  truncate: {
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const { SearchBar } = Search;

const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
  let date = moment(cell);
  date = `${date.format('MMM D YYYY')} at ${date.format('h:mm:ss a')}`;
  return (
    <span>{ date }</span>
  );
};

const projectsColumns = [
  {
    sort: true,
    dataField: 'customerName',
    text: 'Customer Name',
  },
  {
    sort: true,
    dataField: 'address',
    text: 'Address',
  },
  {
    sort: true,
    dataField: 'latestActivity',
    text: 'Latest Activity',
    formatter: dateFormatter,
    // style: styles.truncate,
  },
  {
    sort: true,
    dataField: 'projectId',
    text: 'Project ID',
  },
  {
    sort: true,
    dataField: 'salesAssociate',
    text: 'Sales Associate',
  },
  {
    sort: true,
    dataField: 'storeLocation',
    text: 'Store Location',
  },
];

const searchMethodOptions = [
  'Customer Name',
  'Project ID',
];

function getProjectsData(projects) {
  let data = [];
  let date;
  projects.forEach((project, i) => {
    //date = moment(project.latestActivity);
    data.push({
      customerName: `${project.customerLastName}, ${project.customerFirstName}`,
      address: `${project.customerAddress ? project.customerAddress : '\u2014'}`,
      latestActivity: `${project.latestActivity}`,
      projectId: `${project.projectId}`,
      salesAssociate: `${project.salesPersonName}`,
      storeLocation: `${project.storeName}`,
    });
  });

  return data;
}

class Statuses extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      projectsData: [],
      searchMethodDropdownOpen: false,
      searchMethodRadioSelected: 0,
      activeRow: null,
      selectedRows: [],
    };
  }

  componentDidMount() {
    const { projects } = this.props;
    const { projectsData } = this.state;
    if (projects && projectsData.length < 1) {
      this.updateProjectsData(projects);
    }
  }

  componentDidUpdate(prevProps) {
    const { projects } = this.props;
    if (projects !== prevProps.projects) {
      this.updateProjectsData(projects);
    }
  }

  createCustomToolBar = (props) => {
    return (
      <div style={ { margin: '15px' } }>
        <div className='col-sm-2'>
          <Dropdown
            id='search-method-dropdown'
            isOpen={this.state.searchMethodDropdownOpen}
            toggle={this.toggleSearchMethodDropdown}
          >
            <DropdownToggle caret>
              {searchMethodOptions[this.state.searchMethodRadioSelected]}
            </DropdownToggle>
            <DropdownMenu right>
              {searchMethodOptions.map((option, i) =>
                <DropdownItem
                  key={option}
                  onClick={() => this.onSearchMethodRadioBtnClick(i)}
                  active={this.state.searchMethodRadioSelected === i}
                >{searchMethodOptions[i]}</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }

  updateProjectsData = (projects) => {
    this.setState({
      projectsData: getProjectsData(projects)
    });
  }

  toggleSearchMethodDropdown = () => {
    this.setState({
      searchMethodDropdownOpen: !this.state.searchMethodDropdownOpen,
    });
  }

  handleOnSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) {
      this.setState({
        selectedRows: [row.projectId],
      });
    } else {
      this.setState({
        selectedRows: [],
      });
    }
  }

  handleOnSelectAll = (isSelect, rows, e) => {
    if (isSelect) {
      this.setState({
        selectedRows: this.state.selectedRows,
      });
    } else {
      this.setState({
        selectedRows: [],
      });
    }
  }

  onRowSelected = (row, isSelected, evt) => {
    if (isSelected) {
      this.setState({
        activeRow: row,
        selectedRows: [row.projectId],
      });
    } else {
      this.setState({
        activeRow: null,
        selectedRows: [],
      });
    }

    return false;
  }

  onSearchMethodRadioBtnClick = (radioSelected) => {
    this.setState({
      searchMethodRadioSelected: radioSelected,
    });
  }

  handleDateBtnClick = () => {
    const { projectsData } = this.state;
    this.refs.table.handleFilterData({
      latestActivity: 'Oct 5 2018 at 2:29:27 pm',
      comparator: '<',
    });
  }

  noResults = () => {
    return 'No Projects';
  }

  render() {
    const { selectedRows, projectsData } = this.state;

    const defaultSorted = [{
      dataField: 'latestActivity',
      order: 'desc',
    }];

    const selectRow = {
      mode: 'checkbox',
      bgColor: 'rgba(85, 201, 255, 0.2)',
      // hideSelectColumn: true,
      hideSelectAll: true,
      clickToSelect: true,
      selected: selectedRows,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };

    return (
      <div className="animated">
        <Card>
          <CardHeader>
            <i className="icon-menu"></i>Statuses
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="2">
                <Dropdown
                  id='search-method-dropdown'
                  isOpen={this.state.searchMethodDropdownOpen}
                  toggle={this.toggleSearchMethodDropdown}
                >
                  <DropdownToggle caret>
                    {searchMethodOptions[this.state.searchMethodRadioSelected]}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {searchMethodOptions.map((option, i) =>
                      <DropdownItem
                        key={option}
                        onClick={() => this.onSearchMethodRadioBtnClick(i)}
                        active={this.state.searchMethodRadioSelected === i}
                      >{searchMethodOptions[i]}</DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Col>
              <Col sm="4">
                {this.state.selectedRows.length > 0 &&
                  <Button>
                    <Link to={'/projects/' + this.state.selectedRows[0]}>
                      View {this.state.selectedRows[0]}
                    </Link>
                  </Button>
                }
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <ToolkitProvider
                  bootstrap4
                  keyField='projectId'
                  data={projectsData}
                  columns={projectsColumns}
                  search
                >
                {
                  props => (
                    <div>
                      <SearchBar { ...props.searchProps } />
                      <hr />
                      <BootstrapTable
                        striped
                        hover
                        tabIndexCell
                        defaultSorted={defaultSorted}
                        bordered={false}
                        noDataIndication={this.noResults}
                        selectRow={selectRow}
                        pagination={paginationFactory()}
                        { ...props.baseProps }
                      />
                    </div>
                  )
                }
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Statuses.propTypes = {
  projects: PropTypes.array,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps (state, ownProps) {
  return {
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(projectActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Statuses);
