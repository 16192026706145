import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
//import { bindActionCreators } from "redux";
//import moment from 'moment';
import * as refigureActions  from '../../actions/refigureActions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Badge,Table,Row, Col, Button, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { showWaitScreen } from '../common/WaitScreen';
import delay from './delay';
import { FaEdit, FaBatteryHalf, FaSearch, FaEye } from 'react-icons/fa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';







//import { withStyles } from '@material-ui/core/styles';
import * as custom from '@material-ui/core';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
//import * as customButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';


// -------------------------------------------
// --- Search Mechanism Constant Variables ---
// -------------------------------------------
const { SearchBar } = Search;

// -----------------------------------------
// --- This Are The Columns On The Table ---
// -----------------------------------------
const tableColumns = [
  "Description",
  "Products",
  "Rooms",
  "",
];

const rowActionOptions = [
  'Actions',
  'Create Refigure',
  'Change Status',
  'View Sales Documents',
  'View FloorPlan',
];


// --------------------------------------------------
// --- Default Settings & Properties Of The Table ---
// --------------------------------------------------
const defaultSorted = [{
  dataField: 'refId',
  order: 'asc'
}];

const ExpandedRow = ({refigureId,isSelect}) => {
  return (
    <div>
      {refigureId &&
        <Button color="info">
          <Link style= {{color: 'white'}} to={`/refigureDetails/${refigureId}` }>
            View Refigure Details
          </Link>
        </Button>
      }
    </div>
  )
};

// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  cardHeader         : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  cardRow            : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  projectInfo        : { margin          : '0 -1.25rem 1.25rem -1.25rem', padding          : '0 1.25rem 1.25rem 1.25rem', borderBottom : '1px solid #e3e8ec' },
  versionBtn         : { marginRight     : '1.25rem'                    , borderRadius     : '2px',fontFamily  : 'Segoe Print'                , fontSize       : '12px'      },
  RefCardTitleStyle  : { fontFamily      : 'Segoe Print'                , fontSize         : '1.15rem' },
  RefButtonStyle     : { fontFamily      : 'Segoe Print'                , fontSize         : '12px', borderRadius   : '4px'  },
  RefCardStyle       : { backgroundColor : 'rgba(0,0,0,0.1)'            , marginTop        :'30px' , padding: '10px'},
  tableHeader        : { textAlign       : 'center'},
  tableRow           : { textAlign       : 'center'}
};

const getRoomList = (lstProduct) => {
  let lstRoom = [];
  for (let i = 0; i < lstProduct.length; i++) {
    const curProduct = lstProduct[i];
    for (let j = 0; j < curProduct.lstRoom.length; j++) {
      const curRoom = curProduct.lstRoom[j];
      lstRoom.push(curRoom);
    }
  }
  return lstRoom;
}

// ---------------
// --- Utility ---
// ---------------
/*const getWorkingProject = (projects,projectId) => {
  const wrkProject   = projects.filter(curProject => curProject.projectId === wrkProjectId);
  return wrkProject[0];
}*/

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class Project extends Component {
  timerId = 0;

  constructor(props,context) {
    super(props,context);


    this.state = {
      selectedRows: [], // This Keeps Track Of The Selected Row(s)
      refigureData:[],
      isLoading:true,
      rowActionSelected:rowActionOptions[0],

    };
  }

  componentDidMount = () => {
    this.props.loadRefigures(this.props.projectId);
    this.timerId = setTimeout(() => { this.setState( { isLoading:false } ) },delay)
  }

  componentWillUnmount = () => {
    clearTimeout(this.timerId);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState( { refigureData : this.props.refigures } )
    }
  }

  // --- This Handles The Action of The Table Everytime It Is Clicked
  handleOnSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) { this.setState({ selectedRows: [row.refId], }); }
    else          { this.setState({ selectedRows: [],          }); }
  }

  handleExpandRow = (row,isSelect) => {
    if (row) {
      return ( < ExpandedRow refigureId = { row.refId } isSelect = { isSelect } /> );
    }
  }

  pageRedirect = () => {
    if (this.props.refigures.length === 0) {
      return <Redirect to='/projects' />
    }
  }

  // --- Search Method Selection
  handleRowActionSelection = (event) => {
    this.setState({rowActionSelected : event.target.value})
  }


  // --- Render The Component
  render() {
    const { project, projectId } = this.props;
    const { refigureData       } = this.state;

    // --- Redirect To Projects If Refigure List Is Empty. Mainly Done In Case They Reload From This Page
    if (!project) {
      return <Redirect to='/projects' />
    }

    // --- This Set The Properties of thwe table when selected. It Also calls the The Action Handler When The Table Is Selected
    const selectRow = {
      mode             : 'checkbox',
      bgColor          : 'rgba(85, 201, 255, 0.2)',
      clickToSelect    : true,
      hideSelectAll    : true,
      hideSelectColumn : true,
      selected         : this.state.selectedRows,
      onSelect         : this.handleOnSelect,
    };

    const expandRow = {
      renderer: this.handleExpandRow,
      expanded: this.state.selectedRows, // This Represents The Current Selected Row....
    };

    // --- Return The Formatted Page
    return ( this.state.isLoading ? showWaitScreen() :
      <div className='animated'>
        <Card>
          <CardHeader style={styles.cardRow}>
            <i className='material-icons md-18'>chevron_left</i>
            <Link to='/projects'>Projects</Link>
          </CardHeader>
          { project && refigureData.length > 0 &&
            <CardBody>
              <div style={styles.projectInfo}>
                <Row>
                  <Col xs='12' style={styles.cardRow}>
                    <b>Project</b> : { project.lowesProjectId } | { project.salesPersonName } | { project.owningAccountName }
                  </Col>
                </Row>
                <Row>
                  <Col xs='12' style={styles.cardRow}>
                    <b>Customer</b> : { project.customerFirstName } { project.customerLastName } | { project.customerAddress } | <i className='material-icons md-12'>phone</i> { project.customerPhone } | <i className='material-icons md-12'>email</i> { project.customerEmail }
                  </Col>
                </Row>
                <Row style={{marginTop:"30px"}}>
                  <Table>
                    <thead>
                      <tr>
                        {
                          tableColumns.map((curColumn, i) =>
                            <th key = {i}  style={styles.tableHeader}> { curColumn } </th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                     {
                        refigureData.map((curRefigure,i) =>
                          <tr key = {i}>
                            <td style={styles.tableRow}>
                              { curRefigure.refigureName }
                            </td>
                            <td style={styles.tableRow}>
                              <Badge style={{padding:'10px',fontSize:'15px',color:'white'}} color="info" pill>
                                { curRefigure.lstFlooring.length }
                              </Badge>
                            </td>
                            <td style={styles.tableRow}>
                              <Badge style={{padding:'10px',fontSize:'15px',color:'white'}} color="info" pill>
                                { getRoomList(curRefigure.lstFlooring).length }
                              </Badge>
                            </td>
                            <td style={styles.tableRow}>
                              <Select
                                value={this.state.rowActionSelected}
                                onChange={this.handleRowActionSelection}
                                displayEmpty
                              >
                                <MenuItem value='Actions'>
                                  Actions
                                </MenuItem>
                                <MenuItem value='CreateRefigure'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curRefigure.refigureId}` }>
                                    <FaEdit /> Create Refigure
                                  </Link>
                                </MenuItem>
                                <MenuItem value='ChangeStatus'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curRefigure.refigureId}` }>
                                    <FaBatteryHalf /> Change Status
                                  </Link>
                                </MenuItem>
                                <MenuItem value='ViewSalesDocs'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curRefigure.refigureId}` }>
                                    <FaEye /> View Sales Documents
                                  </Link>
                                </MenuItem>
                                <MenuItem value='ViewFloorplan'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curRefigure.refigureId}` }>
                                    <FaEye /> View FloorPlan
                                  </Link>
                                </MenuItem>
                              </Select>
                            </td>
                          </tr>
                        )
                     }
                    </tbody>
                  </Table>
                </Row>
              </div>
            </CardBody>
          }
        </Card>

      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state, ownProps) {
  const wrkProjectId = parseInt(ownProps.match.params.id, 10);
  const wrkProject   = state.projects.filter(curProject => curProject.projectId === wrkProjectId);
  // --- This Will Create The Data That Will Fill The Rows Of The Table
  const refigureList = state.refigures.length === 0 ? [] : state.refigures.map(curRefigure => {
    // --- Return The Newly Formatted Array
    return {
      refigureId   : `${ curRefigure.refigureId  }`,
      refigureName : `${ curRefigure.description }`,
      lstFlooring  : curRefigure.lstFlooring,
    }
  });
  return {  projectId : wrkProjectId,
            project   : wrkProject[0],
            refigures : refigureList
         };
}

const mapDispatchToProps = dispatch => ({
  loadRefigures: (projectId) => { return dispatch(refigureActions.loadRefigures(projectId)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(Project);
