import { combineReducers } from 'redux';
import auth from './loginReducer';
import projects from './projectReducer';
import dashboardData from './dashboardReducer';
import roughQuoteData from './roughQuoteReducer';
import refigures from './refigureReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';

const rootReducer = combineReducers({
  auth: auth,
  roughQuoteData: roughQuoteData,
  dashboardData: dashboardData,
  projects: projects,
  refigures:refigures,
  ajaxCallsInProgress: ajaxCallsInProgress,
});

export default rootReducer;
