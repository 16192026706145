import initialState from './initialState';
import types from '../actions/actionTypes';

//const initialState = {
//  projects: [],
//  selProject : []
//};

/*export default function projectReducer(state = initialState.projects, action) {
  switch (action.type) {
    case types.LOAD_PROJECTS_SUCCESS:
      return [ ...state, ...action.projects ];
    default:
      return state;
  }
}*/

export default function projectReducer(state = initialState.projects, action) {
  switch (action.type) {
    case types.PROJECT_SEARCH_SUCCESS:
      const newState = []; // Create A new Array Represeting The New State And Concatenate The Current State
      return [ ...newState, ...action.projects ]; // Returns The New State, Adding The Action Coming From Screen. Adding The Selected Refigures In This Case..
    default:
      return state;
  }
}
