import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as actions  from '../../actions/roughQuoteActions';
import { Row, Col } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from 'reactstrap';

const defaults = {
  areaFt  : '',
  areaIn  : '',
  widthFt : '',
  widthIn : '',
  lengthFt: '',
  lengthIn: '',
  flooring: 'none'
}


const getLegalCommand = (listRoom) => {
  for (let i = 0; i < listRoom.length; i++) {
    let curRoom  = listRoom[i];
    let areaFt   = parseInt(curRoom.areaFt);
    let areaIn   = parseInt(curRoom.areaIn);
    let widthFt  = parseInt(curRoom.widthFt);
    let widthIn  = parseInt(curRoom.widthIn);
    let lengthFt = parseInt(curRoom.lengthFt);
    let lengthIn = parseInt(curRoom.lengthIn);
         if ( (isNaN(widthFt ) || widthFt  == 0) && (isNaN(widthIn ) || widthIn  == 0)
           && (isNaN(lengthFt) || lengthFt == 0) && (isNaN(lengthIn) || lengthIn == 0)
           && (isNaN(areaFt  ) || areaFt   == 0) && (isNaN(areaIn  ) || areaIn   == 0)) return 'Size';
    else if  (curRoom.flooring === 'none'                                             ) return 'Flooring';
  }
  return '';
}


// ----------------------------
// --- Main Component Class ---
// ----------------------------
class RoughQuoteByProject extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      areaFt  : defaults.areaFt,
      areaIn  : defaults.areaIn,
      widthFt : defaults.widthFt,
      widhtIn : defaults.widthIn,
      lengthFt: defaults.lengthFt,
      lengthIn: defaults.lengthIn,
      flooring: defaults.flooring,
      showMessage: false,
      message:''
    };
  }

  componentDidMount = () => {}

  componentWillUnmount = () => {}

  componentDidUpdate = (prevProps) => {}

  handleMessage   = (     ) => { this.setState({showMessage: !this.state.showMessage }) }
  handleSelection = (event) => { this.setState({ flooring  : event.target.value      }) }

  handleNext = () => {
    let data = [];
    data.push({ roomName            : null,
                average             : null,
                flooringCost        : null,
                flooringCostUom     : null, 
                underlaymentCost    : null, 
                underlaymentCostUom : null,
                areaFt              : this.state.areaFt, 
                areaIn              : this.state.areaIn, 
                widthFt             : this.state.widthFt, 
                widhtIn             : this.state.widhtIn, 
                lengthFt            : this.state.lengthFt, 
                lengthIn            : this.state.lengthIn,
                flooring            : this.state.flooring
              })
  let legalCommand = getLegalCommand(data);
         if (legalCommand === 'Size'    ) this.setState({showMessage: true, message: 'Size cannot be empty!'        })
    else if (legalCommand === 'Flooring') this.setState({showMessage: true, message: 'Flooring has to be selected!' })
    else                                  this.props.updateData(data)
  }

  handleAreFeet = (event) => {
    let curValue = this.state.areaFt;
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      newValue = parseInt(event.target.value);
      if (newValue > 999999) newValue = curValue;
      this.setState({ areaFt: newValue, widthFt: defaults.widthFt, widhtIn: defaults.widthIn, lengthFt: defaults.lengthFt, lengthIn: defaults.lengthIn })
    }
    else if (event.target.value === '') {
      this.setState({ areaFt: defaults.areaFt })
    }
  }

  handleAreaInches = (event) => {
    let curValue = this.state.areaIn;
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      newValue = parseInt(event.target.value);
      if (newValue > 99) newValue = curValue;
      this.setState({ areaIn: newValue, widthFt: defaults.widthFt, widhtIn: defaults.widthIn, lengthFt: defaults.lengthFt, lengthIn: defaults.lengthIn })
    }
    else if (event.target.value === '') {
      this.setState({ areaIn: defaults.areaIn })
    }
  }

  handleRoomWidthFeet = (event) => {
    let curValue = this.state.widthFt;
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      newValue = parseInt(event.target.value);
      if (newValue > 9999) newValue = curValue;
      this.setState({ widthFt: newValue, areaFt: defaults.areaFt, areaIn: defaults.areaIn })
    }
    else if (event.target.value === '') {
      this.setState({ widthFt: defaults.widthFt })
    }
  }

  handleRoomWidthInches = (event) => {
    let curValue = this.state.widhtIn;
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      newValue = parseInt(event.target.value);
      if (newValue > 99) newValue = curValue;
      this.setState({ widhtIn: newValue, areaFt: defaults.areaFt, areaIn: defaults.areaIn })
    }
    else if (event.target.value === '') {
      this.setState({ widhtIn: defaults.widthIn })
    }
  }

  handleRoomLengthFeet = (event) => {
    let curValue = this.state.lengthFt;
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      newValue = parseInt(event.target.value);
      if (newValue > 9999) newValue = curValue;
      this.setState({ lengthFt: newValue, areaFt: defaults.areaFt, areaIn: defaults.areaIn })
    }
    else if (event.target.value === '') {
      this.setState({ lengthFt: defaults.lengthFt })
    }
  }

  handleRoomLengthInches = (event) => {
    let curValue = this.state.lengthIn;
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      newValue = parseInt(event.target.value);
      if (newValue > 99) newValue = curValue;
      this.setState({ lengthIn: newValue, areaFt: defaults.areaFt, areaIn: defaults.areaIn })
    }
    else if (event.target.value === '') {
      this.setState({ lengthIn: defaults.lengthIn })
    }
  }

  render() {
    if (this.props.data.length > 0) {
      return <Redirect to='/rough-quote-product-details' />
    }

    return ( 
      <div>
          <Snackbar 
            anchorOrigin     = {{ vertical: 'top', horizontal: 'center', }}
            open             = {this.state.showMessage} 
            autoHideDuration = {5000} 
            onClose          = {this.handleMessage}
          >
            <Alert style={{backgroundColor: '#f50057'}}>
              <Typography variant="subtitle1" style={{color:'white',padding:5}} >
                <FaExclamationTriangle style={{marginBottom:5, marginRight:5}}/> { this.state.message }
              </Typography>  
            </Alert>
          </Snackbar>
          <Paper style={{padding:50}}>
            <Typography variant="h5" style={{marginBottom:30}} >
              Please enter the total square footage of the covered area
            </Typography>  
            <Row>
              <Col xs='10' sm='6' md='4' lg='2' xl='3'>
                <Typography variant="subtitle1">Total Covered Area</Typography>  
                <TextField label="Feet"   
                           variant="outlined"
                           style={{width:'50%', margin:5}} 
                           value={ this.state.areaFt }
                           onChange={ this.handleAreFeet }/> 
                <TextField id="outlined-basic" 
                           label="Inches" 
                           variant="outlined"
                           style={{width:70,margin:5}}
                           value={ this.state.areaIn }
                           onChange={ this.handleAreaInches }/>
              </Col>
              <Col xs='2' sm='1' md='1' lg='1' xl='1' style={{textAlign:'left'}}>
                <Typography variant="h5" style={{marginTop:40, }}>OR</Typography>  
              </Col>
              <Col xs='12' sm='6' md='4' lg='3' xl='2'>
                <Typography variant="subtitle1">Width</Typography>  
                <TextField label="Feet"   
                           variant="outlined"
                           style={{width:70, margin:5}} 
                           value={ this.state.widthFt }
                           onChange={ this.handleRoomWidthFeet }/> 
                <TextField id="outlined-basic" 
                           label="Inches" 
                           variant="outlined"
                           style={{width:70,margin:5}}
                           value={ this.state.widhtIn }
                           onChange={ this.handleRoomWidthInches }/>
              </Col>
              <Col xs='12' sm='6' md='4' lg='3' xl='2'>
                <Typography variant="subtitle1">Length</Typography>  
                <TextField label="Feet"   
                           variant="outlined"
                           style={{width:70, margin:5}} 
                           value={ this.state.lengthFt } 
                           onChange={ this.handleRoomLengthFeet }/> 
                <TextField label="Inches" 
                           variant="outlined"
                           style={{width:70, margin:5}}
                           value={ this.state.lengthIn }
                           onChange={ this.handleRoomLengthInches }/>
              </Col>
              <Col xs='12' sm='8' md='8' lg='3' xl='4'>
                <Typography variant="subtitle1">Flooring</Typography>
                <Select
                  value={ this.state.flooring }
                  onChange={this.handleSelection }
                  style={{width:'100%', marginTop:10}}
                >
                  <MenuItem value='none'>
                    Please Select
                  </MenuItem>
                  <MenuItem value='Rolled Carpet'>
                    Rolled Carpet
                  </MenuItem>
                  <MenuItem value='Rolled Vinyl'>
                    Rolled Vinyl
                  </MenuItem>
                  <MenuItem value='Vinyl Tile (VCT)'>
                    Vinyl Tile (VCT)
                  </MenuItem>
                  <MenuItem value='Vinyl Plank'>
                    Vinyl Plank
                  </MenuItem>
                  <MenuItem value='Laminate'>
                    Laminate
                  </MenuItem>
                  <MenuItem value='Laminate Tile'>
                    Laminate Tile
                  </MenuItem>
                  <MenuItem value='Hardwood'>
                    Hardwood
                  </MenuItem>
                  <MenuItem value='Engineered Hardwood'>
                    Engineered Hardwood
                  </MenuItem>
                  <MenuItem value='Ceramic Tile'>
                    Ceramic Tile
                  </MenuItem>
                </Select>
              </Col>
            </Row>
            <Row style={{marginTop:100}}>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
              <Col xs='8' sm='8' md='6' lg='4' xl='4' style={{textAlign:'center'}}>
                <Link to="/rough-quote" style={{textDecoration:'none'}}>
                  <Button variant='contained' color='secondary' style={{marginRight:5}}>
                    Cancel 
                  </Button>
                </Link>
                <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleNext}>
                  Next
                </Button>
              </Col>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
            </Row>
          </Paper>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  return { data: state.roughQuoteData }     
}

const mapDispatchToProps = dispatch => ({
  updateData: (data) => { return dispatch(actions.updateRoughQuoteData(data)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(RoughQuoteByProject);
