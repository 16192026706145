import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { logoutUser } from './../../../actions/userActions.js';

import { Badge,Media, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import RefLogo from './../refigurator-logo.png';
import lowesLogo from './../lowesHeaderLogo.PNG';
import { FaTachometerAlt, FaBatteryHalf, FaBook, FaWrench, FaChartBar, FaQuestion, FaSignOutAlt, FaCalendar, FaRulerCombined } from 'react-icons/fa';
import { IoIosLogOut } from "react-icons/io";
import { GoAlert } from "react-icons/go";
import { FaBell } from "react-icons/fa";
import IconButton from '@material-ui/core/IconButton';
//import Badge from '@material-ui/core/Badge';

const NavigationOptions = {
  Dashboard  : 'Dashboard',
  Projects   : 'Projects',
  Statistics : 'Rough Quote',
  Details   : 'Details',
  HelpCenter : 'Help',
  Alerts     : 'Alerts',
  Warnings   : 'Warnings',
  Settings   : 'Settings',
  Logout     : 'Logout',
};

const brandPrimary   = '#20a8d8';
const brandSuccess   = '#4dbd74';
const brandInfo      = '#63c2de';
const brandWarning   = '#ff9800';
const brandAlert     = '#f50057';
const brandNormal    = 'gray'

const styles = {
  badgeWarning            : { backgroundColor:brandWarning, borderRadius:10 },
  badgeAlert              : { backgroundColor:brandAlert  , borderRadius:10 },
  linkAlertNotification   : { fontSize:'17px', color: brandAlert   , textDecoration: 'none',padding:'10px',marginTop:'50px'},
  linkWarningNotification : { fontSize:'17px', color: brandWarning , textDecoration: 'none',padding:'10px',marginTop:'50px'},
  linkActive              : { fontSize:'17px', color: brandPrimary , textDecoration: 'none',padding:'10px',marginTop:'50px', fontWeight: '500'},
  linkNonActive           : { fontSize:'17px', color: '#012169'  , textDecoration: 'none',padding:'10px',marginTop:'50px', fontWeight: '500'},
};

const getAlertCount = (data) => {
  let filtData = data.filter(curData => curData.projectAlert.length > 0);
  return filtData.length;
}

const getWarningCount = (data) => {
  let filtData = data.filter(curData => curData.projectWarning.length > 0);
  return filtData.length;
}

class NavBar extends React.Component
{
  constructor(props)
  {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state  = { isOpen: false,
                    activeSelection : NavigationOptions.Dashboard,
                    dashboardData   :[]
                  };


  }

  // --- This is the component main load/mount
  componentDidMount = () => {
    this.setState({
      activeSelection : window.location.pathname.includes('projects') ? NavigationOptions.Projects : NavigationOptions.Dashboard,
      dashboardData   : this.props.dashboardData,
    })
  }

  // --- This is the component unload/unmount
  componentWillUnmount = () => {
    //clearTimeout(this.timerId);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState( { dashboardData : this.props.dashboardData,} )
    }
  }

  toggle() { this.setState({ isOpen: !this.state.isOpen }); }

  handleActiveSelection = (navigationOption,event) => {
    this.setState({activeSelection : navigationOption})
  }

  onLogoutClick = () => {
    this.props.dispatch(logoutUser())
      .then(() => {
        if (this.props.errorMessage) {
          // TODO: Show error message notification
          console.log(this.props.errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        <Navbar color="white" light expand="lg">
          <NavbarBrand href="#">
            <Media style={{height:'60px',width:'auto'}} object src={lowesLogo} alt="Generic placeholder image"/>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link style= { this.state.activeSelection === NavigationOptions.Dashboard ? styles.linkActive : styles.linkNonActive } to={`/dashboard/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Dashboard)}>
                <FaTachometerAlt style={{marginBottom:'3px'}}/> { NavigationOptions.Dashboard }
              </Link>
            </NavItem>
            <NavItem>
              <Link style= { this.state.activeSelection === NavigationOptions.Projects ? styles.linkActive : styles.linkNonActive } to={`/projects/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Projects)}>
                <FaBook style={{marginBottom:'3px'}}/> { NavigationOptions.Projects }
              </Link>
            </NavItem>
            <NavItem>
              <Link style= {this.state.activeSelection === NavigationOptions.Statistics ? styles.linkActive : styles.linkNonActive } to={`/rough-quote` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Statistics)}>
                <FaRulerCombined style={{marginBottom:'3px'}}/> { NavigationOptions.Statistics }
              </Link>
            </NavItem>
            <NavItem>
              <Link style= { this.state.activeSelection === NavigationOptions.Details ? styles.linkActive : styles.linkNonActive } to={`/details/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Details)}>
                <FaCalendar style={{marginBottom:'3px'}}/> { NavigationOptions.Details }
              </Link>
            </NavItem>
            <NavItem>
              <Link style= { this.state.activeSelection === NavigationOptions.HelpCenter ? styles.linkActive : styles.linkNonActive } to={`/dashboard/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.HelpCenter)}>
                <FaQuestion style={{marginBottom:'3px'}}/> { NavigationOptions.HelpCenter }
              </Link>
            </NavItem>
              <NavItem>
                <Link style = {((getAlertCount(this.state.dashboardData) > 0) ? styles.linkAlertNotification : (this.state.activeSelection === NavigationOptions.HelpCenter ? styles.linkActive : styles.linkNonActive)) } to={`/alerts/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Alerts)}>
                  <FaBell style={{marginBottom:'3px'}}/> { NavigationOptions.Alerts } <Badge style={styles.badgeAlert}> {getAlertCount(this.state.dashboardData)}</Badge>
                </Link>
              </NavItem>
              <NavItem>
                <Link style= {((getWarningCount(this.state.dashboardData) > 0) ? styles.linkWarningNotification : (this.state.activeSelection === NavigationOptions.Warnings ? styles.linkActive : styles.linkNonActive))} to={`/warnings/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Warnings)}>
                  <GoAlert style={{marginBottom:'3px'}}/> { NavigationOptions.Warnings } <Badge style={styles.badgeWarning}>{getWarningCount(this.state.dashboardData)}</Badge>
                </Link>
              </NavItem>
            <NavItem>
              <Link style = { this.state.activeSelection === NavigationOptions.Settings ? styles.linkActive : styles.linkNonActive } to={`/dashboard/` } onClick={this.handleActiveSelection.bind(this,NavigationOptions.Settings)}>
                <FaWrench style={{marginBottom:'3px'}}/> { NavigationOptions.Settings }
              </Link>
            </NavItem>
            <NavItem>
              <Link style= { this.state.activeSelection === NavigationOptions.Logout ? styles.linkActive : styles.linkNonActive } to={`/dashboard/` } onClick={this.onLogoutClick}>
                <FaSignOutAlt style={{marginBottom:'3px'}}/> { NavigationOptions.Logout }
              </Link>
            </NavItem>
            {/*<UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret> Options </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem> Option 1 </DropdownItem>
                <DropdownItem> Option 2 </DropdownItem>
                <DropdownItem divider /> <DropdownItem> Reset </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>*/}
          </Nav>
          </Collapse>
        </Navbar>
      </div> );
    }
  }

  function mapStateToProps(state) {
    const { auth } = state;
    const { isAuthenticated, errorMessage } = auth;
    return {
      isAuthenticated,
      errorMessage,
      dashboardData : state.dashboardData,
    };
  }

  export default connect(mapStateToProps)(NavBar);
