import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import * as refigureActions  from '../../actions/refigureActions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Badge,Table,Row, Col, Button, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { showWaitScreen } from '../common/WaitScreen';
//import delay from './delay';
import { FaEdit, FaBatteryHalf, FaSearch, FaEye, FaPhone, FaEnvelope, FaSms } from 'react-icons/fa';
import { MdTextsms } from 'react-icons/md';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import * as custom from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';


// -------------------------------------------
// --- Search Mechanism Constant Variables ---
// -------------------------------------------
const { SearchBar } = Search;

const brandWarning = '#ff9800';


// ------------------------------------
// --- Table Properties / Utilities ---
// ------------------------------------
const tableColumns = [
  "Lowe's Project ID",
  "Due Date",
  "Created By",
  "Prospect",
  "Description",
];

const rowActionOptions = [
  'Actions',
  'Create New Entry',
  'Change Status',
  'Make a Refigure',
];

const formatDate = (date) => {
  let valReturn = moment(date);
  valReturn = `${valReturn.format('MMM D YYYY')}`;
  return valReturn;
}

const statusUtilities = {
  WaitingForCustomerAttention : {
    display: 'Waiting for Customer Attention',
    value  : 'waiting customer attention'
  },
  WaitingForRefigurePreparation : {
    display: 'Waiting for Refigure Preparation',
    value  : 'waiting refig prep'
  },
  NoSalesAttention : {
    display: 'No Sales Attention',
    value  : 'no sales attention'
  },
  WaitingForSalesAttention : {
    display: 'Waiting for Sales Attention',
    value  : 'waiting on sales attention'
  },
  WaitingForSalesAttentionRefigure : {
    display: 'Waiting for Sales Attention - Refigure',
    value  : 'waiting refig review'
  },

}

const statusDisplay = (status) => {
  switch (status) {
    case statusUtilities.WaitingForCustomerAttention.value :
      return statusUtilities.WaitingForCustomerAttention.display;
      break;
    case statusUtilities.WaitingForRefigurePreparation.value :
      return statusUtilities.WaitingForRefigurePreparation.display;
      break;
    case statusUtilities.NoSalesAttention.value :
      return statusUtilities.NoSalesAttention.display;
      break;
    case statusUtilities.WaitingForSalesAttention.value :
      return statusUtilities.WaitingForSalesAttention.display;
      break;
    case statusUtilities.WaitingForSalesAttentionRefigure.value :
      return statusUtilities.WaitingForSalesAttentionRefigure.display;
      break;
    default:

  }
}

// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  cardHeader         : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  cardRow            : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  projectInfo        : { margin          : '0 -1.25rem 1.25rem -1.25rem', padding          : '0 1.25rem 1.25rem 1.25rem', borderBottom : '1px solid #e3e8ec' },
  versionBtn         : { marginRight     : '1.25rem'                    , borderRadius     : '2px',fontFamily  : 'Segoe Print'                , fontSize       : '12px'      },
  RefCardTitleStyle  : { fontFamily      : 'Segoe Print'                , fontSize         : '1.15rem' },
  RefButtonStyle     : { fontFamily      : 'Segoe Print'                , fontSize         : '12px', borderRadius   : '4px'  },
  RefCardStyle       : { backgroundColor : 'rgba(0,0,0,0.1)'            , marginTop        :'30px' , padding: '10px'},
  tableHeader        : { textAlign       : 'center'},
  tableRow           : { textAlign       : 'center'                     , color:brandWarning }
};

const getWarnings = (data) => {
  let totAlerts = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const curProject = data[i];
      for (let j = 0; j < curProject.projectWarning.length; j++) {
        totAlerts.push(curProject.projectWarning[j]);
      }
    }
  }
  return totAlerts;
}

const getCustomerDisplay = (data,projectId) => {
  let filtData = data.filter(curData => curData.projectId === projectId);
  filtData = filtData[0];
  return filtData.customerNameLast  + ", " + filtData.customerNameFirst ;
}

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class Alerts extends Component {

  constructor(props,context) {
    super(props,context);


    this.state = {
      dashboardData:[],
      isLoading:false,
      rowActionSelected:rowActionOptions[0]
    };
  }

  componentDidMount = () => {
    this.setState( { dashboardData : this.props.dashboardData } )
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState( { dashboardData : this.props.dashboardData } )
    }
  }

  pageRedirect = () => {
    if (!this.props.isDataLoaded) {
      return <Redirect to='/dashboard' />
    }
  }

  // --- Search Method Selection
  handleRowActionSelection = (event) => {
    this.setState({rowActionSelected : event.target.value})
  }

  // --- Render The Component
  render() {

    const { dashboardData } = this.props;
    console.log(this.props.dashboardData);
    // --- Redirect To Projects If Refigure List Is Empty. Mainly Done In Case They Reload From This Page
    if (dashboardData.length <= 0) {
      return <Redirect to='/dashboard' />
    }

    // --- This Set The Properties of thwe table when selected. It Also calls the The Action Handler When The Table Is Selected
    const selectRow = {
      mode             : 'checkbox',
      bgColor          : 'rgba(85, 201, 255, 0.2)',
      clickToSelect    : true,
      hideSelectAll    : true,
      hideSelectColumn : true,
      selected         : this.state.selectedRows,
      onSelect         : this.handleOnSelect,
    };

    const expandRow = {
      renderer: this.handleExpandRow,
      expanded: this.state.selectedRows, // This Represents The Current Selected Row
    };

    // --- Return The Formatted Page
    return ( this.state.isLoading ? showWaitScreen() :
      <div className='animated'>
        <Card>
            <CardBody>
              <div style={styles.projectInfo}>
                <h2 style={{textAlign:'center', fontWeight:'bold',fontStyle:'italic'}}>Warnings ( {getWarnings(this.state.dashboardData).length} )</h2>
                <Row style={{marginTop:"30px"}}>
                  <Table>
                    <thead>
                      <tr>
                        {
                          tableColumns.map((curColumn, i) =>
                            <th key = {i}  style={styles.tableHeader}> { curColumn } </th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                     {
                       getWarnings(this.state.dashboardData).map((curData,i) =>
                         <tr key = {i}>
                           <td style={styles.tableRow}>
                             { curData.projectId }
                           </td>
                           <td style={styles.tableRow}>
                             { curData.dueDate }
                           </td>
                           <td style={styles.tableRow}>
                            { curData.createNameLast } , { curData.createNameFirst} </td>
                           <td style={styles.tableRow}>
                             { getCustomerDisplay(this.state.dashboardData,curData.projectId)}
                           </td>
                           <td style={styles.tableRow}>
                             { curData.description }
                           </td>
                         </tr>
                       )
                     }
                    </tbody>
                  </Table>
                </Row>
              </div>
            </CardBody>
        </Card>

      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  let data = state.dashboardData.filter(curData => curData.projectWarning.length > 0);
  return { dashboardData : data,
           isDataLoaded  : data.length > 0
         };
}

const mapDispatchToProps = dispatch => ({
  loadRefigures: (projectId) => { return dispatch(refigureActions.loadRefigures(projectId)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(Alerts);
