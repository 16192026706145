import types from "./actionTypes";

export function updateDataSuccess(data) {
  return { type: types.ROUGH_QUOTE_UPDATE_SUCCESS, data };
}

export function updateDataFailure(message) {
  return { type: types.ROUGH_QUOTE_UPDATE_FAILURE, message };
}

export function updateRoughQuoteData(data) {
  return(dispatch) => {
    dispatch(updateDataSuccess(data))
  }
}
