import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as actions  from '../../actions/roughQuoteActions';
import { Row, Col } from 'reactstrap';
import { FaExclamationTriangle, FaCheck } from 'react-icons/fa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from 'reactstrap';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import { Object } from 'core-js';


const brandSuccess = '#a5d6a7';
const brandInfo    = '#90caf9';
const brandWarning = '#fff59d';
const brandDanger  = '#f86c6b';

const defaults = {
  flooringCost        : '',
  flooringCostUom     : 'SF',
  underlaymentCost    : '', 
  underlaymentCostUom : 'SF',
  normal              : { type: 'Normal'  , flooringCost: '2.99', flooringCostUom: 'SF', underlaymentCost: '1.99', underlaymentCostUom: 'SF' },
  premium             : { type: 'Premium' , flooringCost: '3.99', flooringCostUom: 'SF', underlaymentCost: '1.50', underlaymentCostUom: 'SF' },
  ultimate            : { type: 'Ultimate', flooringCost: '4.99', flooringCostUom: 'SF', underlaymentCost: '2.00', underlaymentCostUom: 'SF' }
}


const getLegalCommand = (curRoom) => {
  let command = '';
  if (curRoom.average === null) {
    let flooringCost     = parseFloat(curRoom.flooringCost    );
    let underlaymentCost = parseFloat(curRoom.underlaymentCost);
         if (isNaN(flooringCost    )             ) command += 'Flooring Cost';
    else if (isNaN(underlaymentCost)             ) command += 'Underlayment Cost';
    else if (curRoom.flooringCostUom === null    ) command += 'Flooring Cost Uom';
    else if (curRoom.underlaymentCostUom === null) command += 'Underlayment Cost Uom';
  }
  //if (command === '' && curRoom.average === null) command += 'Average';
  return command;
}

const navigateToSummary = (listRoom) => {
  for (let i = 0; i < listRoom.length; i++) {
    let curRoom = listRoom[i];
    if (getLegalCommand(curRoom) != '') return false;
  }
  return true;
}

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class RoughQuoteProductDetails extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      data              : this.props.data,
      index             : 0,
      showMessage       : false,
      message           : '',
    };
  }

  componentDidMount    = (         ) => {                                                          }
  componentWillUnmount = (         ) => {                                                          }
  componentDidUpdate   = (prevProps) => {                                                          }
  handleMessage        = (         ) => { this.setState({ showMessage : !this.state.showMessage }) }
  
  handleFlooringCostUomSelection = (event) => { 
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.flooringCostUom = event.target.value;
    tstRoom.average         = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }

  handlePadCostUomSelection = (event) => { 
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.underlaymentCostUom = event.target.value;
    tstRoom.average             = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }

  handleFlooringCost = (event) => { 
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.flooringCost = event.target.value;
    tstRoom.average      = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }

  handleUnderlaymentCost = (event) => { 
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.underlaymentCost = event.target.value;
    tstRoom.average          = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }

  handleNormal = () => {
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.average             = defaults.normal;
    tstRoom.flooringCost        = null;
    tstRoom.flooringCostUom     = null;
    tstRoom.underlaymentCost    = null;
    tstRoom.underlaymentCostUom = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }
  
  handlePremium = () => {
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.average             = defaults.premium;
    tstRoom.flooringCost        = null;
    tstRoom.flooringCostUom     = null;
    tstRoom.underlaymentCost    = null;
    tstRoom.underlaymentCostUom = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }

  handleUltimate = () => {
    let curRoom  = this.state.data[this.state.index];
    let listRoom = Object.assign([],this.state.data);
    let tstRoom  = Object.assign({},curRoom        );
    tstRoom.average             = defaults.ultimate;
    tstRoom.flooringCost        = null;
    tstRoom.flooringCostUom     = null;
    tstRoom.underlaymentCost    = null;
    tstRoom.underlaymentCostUom = null;
    listRoom.splice(this.state.index,1,tstRoom);
    this.setState({ data : listRoom }) 
  }

  handleNext = () => {
    let curIndex = this.state.index;
    if (curIndex >= this.props.data.length - 1) { this.setState({ index: this.props.data.length - 1 }) }
    else                                        { this.setState({ index: curIndex + 1               }) }
  }

  handlePrevious = () => {
    let curIndex = this.state.index;
    if (curIndex <= 0) { this.setState({ index: 0            }) }
    else               { this.setState({ index: curIndex - 1 }) }
  }

  handleDone = () => {
    let command = getLegalCommand(this.state.data[this.state.index]);
    console.log(command);
      if (command != '') this.setState({ showMessage: true, message: 'Missing cost information or average cost range.'});
      else               this.props.updateData(this.state.data);
  }

  getButtons = () => {
    if (this.state.index > 0) {
      return (
        <div>
          <Link to="/rough-quote" style={{textDecoration:'none'}}>
            <Button variant='contained' color='secondary' style={{marginRight:5}}>
              Cancel 
            </Button>
          </Link>
          <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handlePrevious}>
            Previous
          </Button>
          {
            this.state.index < this.props.data.length - 1 ? 
              <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleNext}>
                Next
              </Button>
            :
              <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleDone}>
                Done
              </Button>
          }
        </div>
      )
    }
    else if (this.state.index == 0) {
      return (
        <div>
          <Link to="/rough-quote" style={{textDecoration:'none'}}>
            <Button variant='contained' color='secondary' style={{marginRight:5}}>
              Cancel 
            </Button>
          </Link>
          {
            this.state.index < this.props.data.length - 1 ? 
              <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleNext}>
                Next
              </Button>
            :
              <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleDone}>
                Done
              </Button>
          }
        </div>
      )
    }
  }

  render() {
    // -----------------
    // --- Redirects ---
    // -----------------
         if (this.props.data.length == 0       ) { return <Redirect to='/rough-quote'         /> }
    else if (navigateToSummary(this.props.data)) { return <Redirect to='/rough-quote-summary' /> }
    
    // -------------------
    // --- Render Page ---
    // -------------------
    const listRoom = this.state.data;
    const room     = listRoom[this.state.index];
    return ( 
      <div style={{paddingBottom:15}}>
          <Snackbar 
            anchorOrigin     = {{ vertical: 'top', horizontal: 'center', }}
            open             = {this.state.showMessage} 
            autoHideDuration = {5000} 
            onClose          = {this.handleMessage}
          >
            <Alert style={{backgroundColor: '#f50057'}}>
              <Typography variant="subtitle1" style={{color:'white',padding:5}} >
                <FaExclamationTriangle style={{marginBottom:5, marginRight:5}}/> { this.state.message }
              </Typography>  
            </Alert>
          </Snackbar>
          <Paper style={{padding:50}}>
            <Typography variant="h5" style={{marginBottom:20}} >
              { room.flooring }
            </Typography>  
            <Typography variant="subtitle1" style={{marginBottom:30}} >
              Please enter the following cost information or select one of the average cost ranges:
            </Typography>  
            <Row >
              <Col xs='1' sm='1' md='2' lg='3' xl='3' style={{marginTop:15}}></Col>
              <Col xs='5' sm='5' md='4' lg='3' xl='3' >
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Flooring Cost</InputLabel>
                  <OutlinedInput
                    id             ="outlined-adornment-amount"
                    value          = { room.flooringCost !== null ? room.flooringCost : '' }
                    onChange       = { this,this.handleFlooringCost                        }
                    startAdornment = { <InputAdornment position="start">$</InputAdornment> }
                    labelWidth     = { 95                                                  }
                  />
                </FormControl>
              </Col>
              <Col xs='1' sm='1' md='1' lg='1' xl='1' style={{textAlign:'center', marginTop:10}}>
                <Typography variant="h5"> / </Typography>  
              </Col>
              <Col xs='3' sm='3' md='2' lg='2' xl='1'>
                <Select
                  value    = { room.flooringCostUom !== null ? room.flooringCostUom : '' }
                  displayEmpty
                  onChange = { this.handleFlooringCostUomSelection                       }
                  style    = { {width:'100%', marginTop:10}                              }
                >
                  <MenuItem value='SF'>
                    SF
                  </MenuItem>
                  <MenuItem value='LF'>
                    LF
                  </MenuItem>
                </Select>
              </Col>
              <Col xs='1' sm='1' md='2' lg='3' xl='3' style={{marginTop:15}}></Col>
            </Row>
            <Row style={{paddingTop:20}}>
              <Col xs='1' sm='1' md='2' lg='3' xl='3' style={{marginTop:15}}></Col>
              <Col xs='5' sm='5' md='4' lg='3' xl='3' >
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Underlayment Cost</InputLabel>
                  <OutlinedInput
                    id             = "outlined-adornment-amount"
                    value          = { room.underlaymentCost !== null ? room.underlaymentCost : '' }
                    onChange       = { this,this.handleUnderlaymentCost                            }
                    startAdornment = { <InputAdornment position="start">$</InputAdornment>         }
                    labelWidth     = { 145                                                         }
                  />
                </FormControl>
              </Col>
              <Col xs='1' sm='1' md='1' lg='1' xl='1' style={{textAlign:'center', marginTop:10}}>
                <Typography variant="h5"> / </Typography>  
              </Col>
              <Col xs='3' sm='3' md='2' lg='2' xl='1'>
                <Select
                  value    = { room.underlaymentCostUom !== null ? room.underlaymentCostUom : '' }
                  displayEmpty
                  onChange = { this.handlePadCostUomSelection                                        }
                  style    = { {width:'100%', marginTop:10}                                          }
                >
                  <MenuItem value='SF'>
                    SF
                  </MenuItem>
                  <MenuItem value='LF'>
                    LF
                  </MenuItem>
                </Select>
              </Col>
              <Col xs='1' sm='1' md='2' lg='3' xl='3' style={{marginTop:15}}></Col>
            </Row>
            <Typography variant="h4" style={{textAlign:'center', padding:50}}>OR</Typography>  
             <Row>
              <Col xs='12' sm='12' md='12' lg='4' xl='4'>
                <Paper>
                  <Typography variant="h5" style={{textAlign:'center', padding:10, backgroundColor:brandWarning}}>
                    Normal { room.average !== null && room.average.type === 'Normal' ? <FaCheck style={{marginBottom: 5}}/> : ""}
                  </Typography>
                  <div style={{padding:30}}>
                    <Typography variant="subtitle1" style={{textAlign:'center'}}> Flooring Cost: 2.99 / SF           </Typography>
                    <Typography variant="subtitle1" style={{textAlign:'center'}}> Pad / Underlayment Cost: 1.99 / SF </Typography>
                    <Row>
                      <Col xs='12' sm='12' md='12' lg='12' xl='12' style={{textAlign:'center', marginTop:50}}>
                        <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleNormal}>
                          Select
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Paper>
              </Col>
              <Col xs='12' sm='12' md='12' lg='4' xl='4'>
                <Paper>
                  <Typography variant="h5" style={{textAlign:'center', padding:10, backgroundColor:brandInfo}}>
                    Premium { room.average !== null && room.average.type === 'Premium' ? <FaCheck style={{marginBottom: 5}}/> : ""}
                  </Typography>
                  <div style={{padding:30}}>
                    <Typography variant="subtitle1" style={{textAlign:'center'}}> Flooring Cost: 3.99 / SF           </Typography>
                    <Typography variant="subtitle1" style={{textAlign:'center'}}> Pad / Underlayment Cost: 1.50 / SF </Typography>
                    <Row>
                      <Col xs='12' sm='12' md='12' lg='12' xl='12' style={{textAlign:'center', marginTop:50}}>
                        <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handlePremium}>
                          Select
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Paper>
              </Col>
              <Col xs='12' sm='12' md='12' lg='4' xl='4'>
                <Paper>
                  <Typography variant="h5" style={{textAlign:'center', padding:10, backgroundColor:brandSuccess}}>
                    Ultimate { room.average !== null && room.average.type === 'Ultimate' ? <FaCheck style={{marginBottom: 5}}/> : ""}
                  </Typography>
                  <div style={{padding:30}}>
                    <Typography variant="subtitle1" style={{textAlign:'center'}}> Flooring Cost: 4.99 / SF           </Typography>
                    <Typography variant="subtitle1" style={{textAlign:'center'}}> Pad / Underlayment Cost: 2.00 / SF </Typography>
                    <Row>
                      <Col xs='12' sm='12' md='12' lg='12' xl='12' style={{textAlign:'center', marginTop:50}}>
                        <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={this.handleUltimate}>
                          Select
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Paper>
              </Col>
              </Row>
            <Row style={{marginTop:100}}>
              <Col xs='12' sm='12' md='12' lg='12' xl='12' style={{textAlign:'center'}}>
                { this.getButtons() }
              </Col>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
            </Row>
          </Paper>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  return { data: state.roughQuoteData }     
}

const mapDispatchToProps = dispatch => ({
  updateData: (data) => { return dispatch(actions.updateRoughQuoteData(data)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(RoughQuoteProductDetails);
