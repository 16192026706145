import React from 'react';
//import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CardImg, } from 'reactstrap';
import photo from './refigurator-logo2.png';
import imgMain from './black-and-white-gray-grey-1050305.jpg';


const styles = {
  divMain : {
    position:'absolute',
    top:'0',
    left:'0',
    right:'0',
    bottom:'0',
    textAlign:'center',
    overflow: 'hidden',
    backgroundImage: `url(${imgMain})`
  },
  divInner : {
    position:'absolute',
    width:'50%',
    top:'35%',
    left:'25%',
    textAlign:'center',
    backgroundColor:'rgba(0,0,0,0)'
  },
};

export function showWaitScreen() {
  setTimeout(3000);
  return (
    <div style={styles.divMain}>
        <div style={styles.divInner}>
          <CardImg width = "100%" src = { photo } alt="Card image cap" />
          <LinearProgress color="primary"/>
        </div>
    </div>
  )
}
