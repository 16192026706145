import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as actions  from '../../actions/roughQuoteActions';
import { Row, Col } from 'reactstrap';
import { FaPaperPlane, FaPrint, FaExclamationTriangle } from 'react-icons/fa';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from 'reactstrap';

const colors = [ '#a5d6a7', '#90caf9', '#fff59d' , '#f86c6b' ]

const getColors = (listRoom) => {
  let index     = 0;
  let listColor = [];
  for (let i = 0; i < listRoom.length; i++) {
    if (index <= 3) {
      listColor.push(colors[index]);
      index++;  
    }
    else {
      listColor.push(colors[0]);
      index = 0;
    }
  }
  return listColor;
}

const getLink = (selection) => {
       if (selection == 'project') return '/rough-quote-by-project';
  else if (selection == 'room'   ) return '/rough-quote-by-room';
  else                             return '/rough-quote';
}

const getRoomArea = (curRoom) => {
  let areaFt = parseInt  (curRoom.areaFt                                                       );
  let areaIn = parseInt  (curRoom.areaIn !== '' && curRoom.areaIn !== null ? curRoom.areaIn : 0);
  let width  = parseFloat(curRoom.widthFt  + (curRoom.widthIn  !== '' && curRoom.widthIn  !== null ? "." + curRoom.widthIn  : ".0"));
  let length = parseFloat(curRoom.lengthFt + (curRoom.lengthIn !== '' && curRoom.lengthIn !== null ? "." + curRoom.lengthIn : ".0"));
       if (!isNaN(areaFt) && !isNaN(areaIn)) return parseFloat(areaFt + "." + areaIn     );
  else if (!isNaN(width ) && !isNaN(length)) return parseFloat((width * length) + "." + 0);
  else                                       return 0.0;
}

const getRoomMaterialCost = (curRoom) => {
  let areaFt = parseInt  (curRoom.areaFt                                                       );
  let areaIn = parseInt  (curRoom.areaIn !== '' && curRoom.areaIn !== null ? curRoom.areaIn : 0);
  let width  = parseFloat(curRoom.widthFt  + (curRoom.widthIn  !== '' && curRoom.widthIn  !== null ? "." + curRoom.widthIn  : ".0"));
  let length = parseFloat(curRoom.lengthFt + (curRoom.lengthIn !== '' && curRoom.lengthIn !== null ? "." + curRoom.lengthIn : ".0"));
  if (curRoom.average === null) {
    let flooringCost     = parseFloat(curRoom.flooringCost    );
    let underlaymentCost = parseFloat(curRoom.underlaymentCost);
    if (!isNaN(flooringCost) && !isNaN(underlaymentCost)) {
      let money = (flooringCost + underlaymentCost);
      if (!isNaN(areaFt) && !isNaN(areaIn)) {
        let area = parseFloat(areaFt + "." + areaIn);
        return (money * area);  
      }
      else if (!isNaN(width ) && !isNaN(length)) {
        let area = (width * length);
        return (money * area);
      }
      else return 0;
    }
    else return 0;
  }
  else {
    let flooringCost     = parseFloat(curRoom.average.flooringCost    );
    let underlaymentCost = parseFloat(curRoom.average.underlaymentCost);
    if (!isNaN(flooringCost) && !isNaN(underlaymentCost)) {
      let money = (flooringCost + underlaymentCost);
      if (!isNaN(areaFt) && !isNaN(areaIn)) {
        let area = parseFloat(areaFt + "." + areaIn);
        return (money * area);  
      }
      else if (!isNaN(width ) && !isNaN(length)) {
        let area = (width * length);
        return (money * area);
      }
      else return 0;
    }
    else return 0;
  }
}

const getRoomTotal = (curRoom) => {
  let materialCost = getRoomMaterialCost(curRoom);
  return materialCost + 2000;
}

const getProjectArea = (listRoom) => {
  let totArea = 0.0;
  for (let i = 0; i < listRoom.length; i++) {
    let curRoom = listRoom[i];
    totArea += getRoomArea(curRoom);
  }
  return totArea;
}

const getProjectMaterialCost = (listRoom) => {
  let totCost = 0.0;
  for (let i = 0; i < listRoom.length; i++) {
    let curRoom = listRoom[i];
    totCost += getRoomMaterialCost(curRoom);
  }
  return totCost;
}

const getProjectTotal = (listRoom) => {
  let totValue = 0.0;
  for (let i = 0; i < listRoom.length; i++) {
    let curRoom = listRoom[i];
    totValue += getRoomTotal(curRoom);
  }
  return totValue;
}

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class RoughQuoteSummary extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      selection   : 'none',
      showMessage : false,
      message     : '',
    };
  }

  componentDidMount    = (         ) => {                                                          }
  componentWillUnmount = (         ) => {                                                          }
  componentDidUpdate   = (prevProps) => {                                                          }
  handleMessage        = (         ) => { this.setState({ showMessage : !this.state.showMessage }) }
  handlePrint          = (         ) => { this.setState({ showMessage : !this.state.showMessage, message: 'Print Feature Coming Soon!',}) }
  handleEmail          = (         ) => { this.setState({ showMessage : !this.state.showMessage, message: 'Email Feature Coming Soon!' }) }

  handleSelection = (event) => {
    this.setState({selection: event.target.value})
  }

  render() {
    if (this.props.data.length == 0       ) { return <Redirect to='/rough-quote'/> }

    let listColor = this.props.data.length > 0 ? getColors(this.props.data) : [];
    return ( 
      <div>
          <Snackbar 
            anchorOrigin     = {{ vertical: 'top', horizontal: 'center', }}
            open             = {this.state.showMessage} 
            autoHideDuration = {5000} 
            onClose          = {this.handleMessage}
          >
            <Alert style={{backgroundColor: '#90caf9'}}>
              <Typography variant="subtitle1" style={{color:'white',padding:5}} >
                <FaExclamationTriangle style={{marginBottom:5, marginRight:5}}/> { this.state.message }
              </Typography>  
            </Alert>
          </Snackbar>
          <Paper style={{padding:50}}>
            <Typography variant="h5" style={{marginBottom:30}} >
              Project Summary
            </Typography>  
            <Row>
              { this.props.data.length === 1 ? 
                  <Col xs='12' sm='12' md='6' lg='6' xl='6'> 
                    <Paper>
                      <div style={{backgroundColor: listColor.length == 0 ? 'rgba(0,0,0,0)' : colors[0]}}>
                        <Typography variant="h5" style={{padding:10, textAlign:'center'}} > Project Total </Typography>  
                      </div>
                      <Row style={{padding:20}}>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Covered Area: </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > { getProjectArea(this.props.data) } SF</Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Material Cost: </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > $ { getProjectMaterialCost(this.props.data).toFixed(2) } </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Labor Cost: </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > $ 2000.00 </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'></Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Divider/>
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center', fontWeight:'bold'}} > Total </Typography>   
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center', fontWeight: 'bold'}} > $ { getProjectTotal(this.props.data).toFixed(2) } </Typography>  
                        </Col>
                      </Row>
                    </Paper>
                  </Col>
                :
                  this.props.data.map((curRoom, i) =>
                    <Col xs='12' sm='12' md='6' lg='6' xl='6' key={i} style={{marginTop:20}}>      
                      <Paper>
                        <div style={{backgroundColor: listColor.length == 0 ? 'rgba(0,0,0,0)' : listColor[i]}}>
                          <Typography variant="h5" style={{padding:10, textAlign:'center'}} > { curRoom.flooring } </Typography>  
                        </div>
                        <Row style={{padding:20}}>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Covered Area: </Typography>  
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > { getRoomArea(curRoom) } SF</Typography>  
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Material Cost: </Typography>  
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > $ { getRoomMaterialCost(curRoom).toFixed(2) } </Typography>  
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Labor Cost: </Typography>  
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > $ 2000.00 </Typography>  
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'></Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Divider/>
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center', fontWeight:'bold'}} > Total </Typography>   
                          </Col>
                          <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                            <Typography variant="subtitle1" style={{padding:5, textAlign:'center', fontWeight: 'bold'}} > $ { getRoomTotal(curRoom).toFixed(2) } </Typography>  
                          </Col>
                        </Row>
                      </Paper>
                    </Col>
                  )
              }
            </Row>
            {
              this.props.data.length > 1 ?
                <Row style={{marginTop:20}}>
                  <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                    <Paper>
                      <div style={{backgroundColor: colors[3]}}>
                        <Typography variant="h5" style={{padding:10, textAlign:'center'}} > Project Total </Typography>  
                      </div>
                      <Row style={{padding:20}}>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Covered Area: </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > { getProjectArea(this.props.data)} </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Material Cost: </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > $ { getProjectMaterialCost(this.props.data).toFixed(2) } </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > Est. Labor Cost: </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center'}} > $ 2000.00 </Typography>  
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'></Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Divider/>
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center', fontWeight:'bold'}} > Total </Typography>   
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                          <Typography variant="subtitle1" style={{padding:5, textAlign:'center', fontWeight: 'bold'}} > $ { getProjectTotal(this.props.data).toFixed(2) } </Typography>  
                        </Col>
                      </Row>
                    </Paper>
                  </Col>
                  <Col xs='6' sm='6' md='6' lg='6' xl='6'></Col>
                </Row>
              :
                <div></div>
            }
            <Row style={{marginTop:100}}>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
              <Col xs='8' sm='8' md='6' lg='4' xl='4' style={{textAlign:'center'}}>
                <Link to="/rough-quote" style={{textDecoration:'none'}}>
                  <Button variant='contained' color='secondary' style={{marginRight:5}}>
                    Close 
                  </Button>
                </Link>
                <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={ this.handlePrint}>
                  <FaPrint style={{marginRight:5}} /> Print
                </Button>
                <Button variant='contained' color='primary' style={{marginLeft:5}} onClick={ this.handleEmail}>
                  <FaPaperPlane style={{marginRight:5}} /> Email
                </Button>
              </Col>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
            </Row>
          </Paper>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  return { data: state.roughQuoteData }     
}

const mapDispatchToProps = dispatch => ({
  updateData: (data) => { return dispatch(actions.updateRoughQuoteData(data)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(RoughQuoteSummary);
