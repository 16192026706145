import React, { Component }                               from 'react';
import PropTypes                                          from 'prop-types';
import { Container, Row, Col, CardGroup, Card, CardBody } from 'reactstrap';
import LoginForm                                          from './LoginForm';
import Snackbar from '@material-ui/core/Snackbar';
import lowesLogo from "./lowesHeaderLogo.PNG";

// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  formStyle : { backgroundColor : 'rgba(0,0,0,0.1)' },
  logoStyle : { textAlign : 'center', backgroundColor : '#fff', paddingBottom: 25}
};

class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.onLoginClick    = this.onLoginClick.bind(this);
    this.updateUserState = this.updateUserState.bind(this);
    this.state = {
      user: { username: '', password: '', },
      showToast: false,
      toastMessage: '',
    };
  }

  // --- Notification Toast Action Listener
  handleToastClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    this.setState({ showToast: false });
  };

  // ------------------------------------------------------
  // --- Will Get Triggered After Clicking Login Button ---
  // ------------------------------------------------------
  onLoginClick(e) {
    e.preventDefault();
    this.props.onLoginUser(this.state.user)
      .then(() => {
        if (this.props.errorMessage) {
          let errorMessage = this.props.errorMessage;
               if (errorMessage === 'Session Logon Status Illegal Logon'   ) errorMessage = 'Wrong Username!';
          else if (errorMessage === 'Session Logon Status Illegal Password') errorMessage = 'Wrong Password!';
          console.log(errorMessage);
          this.setState ({
            showToast : true,
            toastMessage : `${errorMessage}`
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // -----------------------------------------------------------------------------------
  // --- Will Get Triggered Everytime Something Is Typed On Password/UserName Fields ---
  // -----------------------------------------------------------------------------------
  updateUserState(e) {
    const { name, value } = e.target;
    this.setState({ user: { ...this.state.user, [name]: value } });
  }

  render() {
    return (
      <div className='app flex-row align-items-center' style = { styles.formStyle }>
      <Snackbar
        anchorOrigin     = { { vertical: 'top', horizontal: 'center', } }
        open             = { this.state.showToast }
        autoHideDuration = { 6000 }
        onClose          = { this.handleToastClose }
        message          = { <span id="message-id">{ this.state.toastMessage }</span> }
      >
      </Snackbar>
        <Container>
          <Row className='justify-content-center'>
            <Col md='6'>
              <CardGroup className='mb-4'>
                <Card className='p-4'>
                  <CardBody>
                    <div style={styles.logoStyle}>
                      <img src={lowesLogo}  width="150" height="auto" alt="Lowe's" /> 
                    </div>
                    <h1>Login</h1>
                    <p className='text-muted'>Sign In to your account</p>
                    <LoginForm
                      user         = { this.state.user      }
                      handleChange = { this.updateUserState }
                      handleSubmit = { this.onLoginClick    }
                    />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

LoginPage.propTypes = {
  onLoginUser  : PropTypes.func.isRequired,
  errorMessage : PropTypes.string,
};

export default LoginPage;
