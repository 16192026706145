import React, { Component } from 'react';
import { connect } from "react-redux";
import * as dashboardActions from './../../actions/dashboardActions.js';
//import { GoAlert } from "react-icons/go";
//import Badge from '@material-ui/core/Badge';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from "./ChangingProgressProvider";
//import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';

import { FaArrowRight, FaChartLine, FaPrint, FaInfoCircle } from "react-icons/fa";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardDeck,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { styles } from 'material-ui-pickers/wrappers/InlineWrapper';

const cardStyle = {
  cardHeaderPROvider  : {backgroundColor:'#012169', marginLeft:0, marginRight:0, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', color:'#fff'},
  cardHeaderSales     : {backgroundColor:'#ff0000', marginLeft:0, marginRight:0, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', color:'#fff'},
  cardHeaderCustomer  : {backgroundColor:'#086612', marginLeft:0, marginRight:0, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', color:'#fff'},
  cardHeaderCol       : {textAlign:'center', marginLeft:10, paddingTop:8}
};

// ----------------------------
// --- PullDown PlaceHolder ---
// ----------------------------
const pullDownPlaceHolderStores = '0001';
const pullDownPlaceHolderTerritories = 'All';
const pullDownPlaceHolderPROvider = 'All';

// --------------------------
// --- Colors / Utilities ---
// --------------------------
//const brandPrimary = '#20a8d8';
const brandSuccess = '#4dbd74';
const brandInfo    = '#63c2de';
//const brandWarning = '#f8cb00';
//const brandDanger  = '#f86c6b';
//const chartColors = [ '#20a8d8', '#4dbd74', '#63c2de', '#f8cb00', '#f86c6b', '#f8906d', '#90f86d', '#6df8f8', '#ff66ff', '#003326', '#006666', '#666600'    ]

const convertHex = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
};

// ----------------------------
// --- Date Range Utilities ---
// ----------------------------
const getMomentDateRange = (dateRange) => {
  let startDate, endDate;
  switch (dateRange) {
    case 'Last 7 Days':
      endDate   = moment();
      startDate = moment().subtract(7, 'days');
      break;
    case 'Last 30 Days':
      endDate   = moment();
      startDate = moment().subtract(1, 'months');
      break;
    case 'Last 3 Months':
      endDate   = moment();
      startDate = moment().subtract(3, 'months');
      break;
    case 'Last 6 Months':
      endDate   = moment();
      startDate = moment().subtract(6, 'months');
      break;
    case 'Last 12 Months':
      endDate   = moment();
      startDate = moment().subtract(1, 'year');
      break;
    default:
      break;
  }
  return [startDate, endDate];
}

const getFormattedDateRange = (dateRange) => {
  const momentRange = getMomentDateRange(dateRange);
  let   startDate   = momentRange[0].format('MM/DD/YYYY');
  let   endDate     = momentRange[1].format('MM/DD/YYYY');
  return `${startDate} - ${endDate}`;
}

const dateRanges = [
  "Last 30 Days",
  "Last 60 Days",
  "Last 90 Days",
];

// -----------------------------
// --- Main PullDown Filters ---
// -----------------------------

/*const storeOptions = [
  "All",
  "0001",
  "0002",
  "0003",
  "0004",
];

const territoryOptions = [
  "All",
  "Divisions",
  " - 1",
  " - 2",
  " - 3",
  " - 4",
  "Regions",
  " - 1",
  " - 2",
  " - 3",
  " - 4",
  "Markets",
  " - 1234",
  " - 3456",
  " - 2312",
  " - 6543",
];

const installPartnerOptions = [
  "All",
  "Installation Services",
  "Keller",
  "Service Pros",
  "Ultimate",
];*/

// ----------------------------
// --- Measuring Performace ---
// ----------------------------
const cardTitle = {
  WaitingForMeasure : {
    display: 'Measures Pending',
    value  : 'Measure Pending'
  },
  WaitingForCustomerAttention : {
    display: 'Awaiting Customer Response',
    value  : 'Awaiting Customer Response'
  },
  WaitingForRefigurePreparation : {
    display: 'Refigures Pending',
    value  : 'Refigure Pending'
  },
  NoSalesAttention : {
    display: 'New Details in IMS',
    value  : 'New Detail in IMS'
  },
  WaitingForSalesAttention : {
    display: 'Sales Attention Required',
    value  : 'Sales Attention Required'
  },
  WaitingForSalesAttentionRefigure : {
    display: 'Completed Refigures',
    value  : 'Completed Refigure'
  },

}

const getCardTotal = (data,status) => {
  let disData = data.filter(curData => curData.projectStatus === status);
  return disData.length;
}

const getCardPercentage = (data,status) => {
  let   totData  = data;
  let   filtData = data.filter(curData => curData.projectStatus === status);
  const disValue = (filtData.length / totData.length) * 100;
  return disValue.toFixed(1);
}

// --------------------------------
// --- Available To Sell To Sold ---
// --------------------------------
const availableToSellToSoldValue = (timeframe) => {
  switch (timeframe) {
    case dateRanges[0] :
      return '6.3 Days';
    case dateRanges[1] :
      return '7.8 Days';
    case dateRanges[2] :
      return '9.2 Days';
    default:
  }
}

// -----------------
// --- Refigures ---
// -----------------
const refiguresValue = (timeframe) => {
  switch (timeframe) {
    case dateRanges[0] :
      return '0.2 Hours';
    case dateRanges[1] :
      return '8.3 Hours';
    case dateRanges[2] :
      return '8.1 Hours';
    default:
  }
}

// --------------------------
// --- Created To Measure ---
// --------------------------
const createdToMeasuredValue = (timeframe) => { // Display in Days
  switch (timeframe) {
    case dateRanges[0] :
      return 74.4;
    case dateRanges[1] :
      return 62.4;
    case dateRanges[2] :
      return 79.2;
    default:
  }
}

const createdToMeasuredValueDisplay = (createdToMeasuredValue) => { // Display in Days
  let totValue = createdToMeasuredValue / 24;
  return totValue.toFixed(1) + ' Days';
}

// ----------------------------
// --- Measured To Reviewed ---
// ----------------------------
const measuredToReviewedValue = (timeframe) => { // Display in Hours
  switch (timeframe) {
    case dateRanges[0] :
      return 4.6;
    case dateRanges[1] :
      return 5.2;
    case dateRanges[2] :
      return 6.1;
    default:
  }
}

// --------------------------------------
// --- Reviewed To Available To Sell ---
// --------------------------------------
/* const reviewedToAvailableToSellValue = (timeframe) => { // Display in Hours
  switch (timeframe) {
    case dateRanges[0] :
      return 4.6;
    case dateRanges[1] :
      return 5.2;
    case dateRanges[2] :
      return 4.2;
    default:
  }
} */

// -------------
// --- Total ---
// -------------
const total = (createdToMeasuredValue,measuredToReviewedValue) => { // Display in Days
  let totValue = createdToMeasuredValue + measuredToReviewedValue;
  totValue = totValue / 24;
  return totValue.toFixed(1) + ' Days';
}

// ------------------
// --- Close Rate ---
// ------------------

const closeRateMockDataCurrent = {
  mockData : [
    { Month: 'January'   ,Measures : 100 , Closes : 50  },
    { Month: 'February'  ,Measures : 120 , Closes : 70  },
    { Month: 'March'     ,Measures : 130 , Closes : 90  },
    { Month: 'April'     ,Measures : 140 , Closes : 110 },
    { Month: 'May'       ,Measures : 150 , Closes : 90  },
    { Month: 'June'      ,Measures : 160 , Closes : 70  },
    { Month: 'July'      ,Measures : 170 , Closes : 50  },
    { Month: 'August'    ,Measures : 180 , Closes : 70  },
    { Month: 'September' ,Measures : 190 , Closes : 90  },
    { Month: 'October'   ,Measures : 200 , Closes : 110 },
    { Month: 'November'  ,Measures : 220 , Closes : 130 },
    { Month: 'Decemeber' ,Measures : 230 , Closes : 150 },
  ]
}

const closeRateMockDataPrevious = {
  mockData : [
    { Month: 'January'   ,Measures : 50  , Closes : 25  },
    { Month: 'February'  ,Measures : 70  , Closes : 35  },
    { Month: 'March'     ,Measures : 90  , Closes : 45  },
    { Month: 'April'     ,Measures : 110 , Closes : 65  },
    { Month: 'May'       ,Measures : 130 , Closes : 85  },
    { Month: 'June'      ,Measures : 150 , Closes : 105 },
    { Month: 'July'      ,Measures : 170 , Closes : 25 },
    { Month: 'August'    ,Measures : 190 , Closes : 35 },
    { Month: 'September' ,Measures : 210 , Closes : 45 },
    { Month: 'October'   ,Measures : 230 , Closes : 55 },
    { Month: 'November'  ,Measures : 250 , Closes : 65 },
    { Month: 'Decemeber' ,Measures : 270 , Closes : 75 },
  ]
}

const yearMonths = [  'January', 'February', 'March', 'April', 'May', 'June','July','August','September','October','November','December' ]

const calculateCloseRate = (totalMeasure, totalCloses) =>
{
  return ((totalCloses / totalMeasure) * 100).toFixed(1);
}

const calculateMonthsToDate = () =>
{
  let   curYearToDate   = moment().startOf('year');
  let   monthDifference = moment(curYearToDate).diff(moment(),'month');
  const months          = yearMonths.filter((curMonth,i) => i <= (-monthDifference));
  return months;
}

const closeRateMixedChart = (currentYearData,previousYearData) => {
  let curYearData  = [];
  let prevYearData = [];
  let monthsToDate = calculateMonthsToDate();

  for (let i = 0; i < currentYearData.mockData.length; i++) {
    let item = currentYearData.mockData[i];
    for (let j = 0; j < monthsToDate.length; j++) {
      let month = monthsToDate[j];
      if (item.Month === month) {
        curYearData.push(calculateCloseRate(item.Measures,item.Closes));
      }
    }
  }

  previousYearData.mockData.map((item, i) => { prevYearData.push(calculateCloseRate(item.Measures,item.Closes)); });
  return {
    labels: yearMonths,
    datasets: [{
        data            : curYearData,
        label           : "Current Year Sales Rate (Percentage)",
        backgroundColor : convertHex(brandInfo, 10),
        borderColor     : "#3e95cd",
        borderWidth     : 1,
      },
      {
        type            : "line",
        data            : prevYearData,
        label           : "Previous Year Sales Rate (Percentage)",
        backgroundColor : convertHex(brandInfo, 10),
        borderColor     : brandSuccess,
        borderWidth     : 2,
        fill            :false
      }
    ]
  }
}

const closeRateChart = (dataList) => {
  let data         = [];
  let monthsToDate = calculateMonthsToDate();
  for (let i = 0; i < dataList.mockData.length; i++) {
    let item = dataList.mockData[i];
    for (let j = 0; j < monthsToDate.length; j++) {
      let month = monthsToDate[j];
      if (item.Month === month) {
        data.push(calculateCloseRate(item.Measures,item.Closes));
      }
    }
  }

  return {
    labels: ['January', 'February', 'March', 'April', 'May', 'June','July','August','September','October','November','December'],
    datasets: [{
        data            : data,
        label           : "Current Year Sales Rate (Percentage)",
        backgroundColor : convertHex(brandInfo, 10),
        borderColor     : "#3e95cd",
        borderWidth     : 1,
      },
    ]
  }
}

const closeRateChartOptions = {
  maintainAspectRatio: false,
  legend: { display: true, position: 'bottom' },
  scales: {
    xAxes: [{
      barThickness:'flex',
      gridLines: {
        drawOnChartArea: false,
        offSetGridlines: true,
        beginAtZero:true
      },
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
        maxTicksLimit: 100,
        stepSize: Math.ceil(100 / 10),
        max: 100
      }
    }]
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    }
  },
  //Customize chart options
  /*tooltips: {
      displayColors: true,
      titleFontSize: 16,
      bodyFontSize: 14,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
          label: (tooltipItem, data) => { return `${data.datasets[tooltipItem.datasetIndex].label}` },
          title: (                 ) => { return ``                                                 }
      }
  }*/
}

// -------------------------------
// --- Status Chart Properties ---
// -------------------------------
const yAxisLabels = () =>{
  let datValue = [];
  for (let i = 1; i < 32; i++) {
    datValue.push(i);
  }
  return datValue;
}

const statusChartData = (status) =>{
  switch (status) {
    case 'createdToMeasure':
      return [1.8, 2.1, 2.6, 2.7, 3.1, 3.2, 3.0, 2.9, 2.8, 2.6, 2.5, 2.7, 2.9, 3.1, 3.3, 3.5, 3.6, 3.7, 3.8, 3.9, 4.1, 4.2, 4.3, 4.5, 3.6, 3.4, 3.5, 3.8, 3.9, 3.1, 3.4]
    case 'measuredToReviewed':
      return [4.8, 4.6, 4.1, 4.4, 4.7, 5.1, 5.0, 5.4, 4.8, 4.6, 4.5, 4.7, 4.9, 5.1, 5.3, 5.3, 5.2, 5.1, 4.8, 4.5, 4.4, 4.2, 4.1, 4.9, 4.6, 4.4, 4.5, 4.8, 4.9, 4.1, 4.4]
    case 'reviewedToAvailableToSell':
      return [4.8, 4.6, 4.1, 4.4, 4.7, 5.1, 5.0, 5.4, 4.8, 4.6, 4.5, 4.7, 4.9, 5.1, 5.3, 5.3, 5.2, 5.1, 4.8, 4.5, 4.4, 4.2, 4.1, 4.9, 4.6, 4.4, 4.5, 4.8, 4.9, 4.1, 4.4]
    case 'refigures':
      return [6.8, 6.1, 6.6, 6.7, 7.1, 7.2, 7.0, 6.9, 6.8, 6.6, 6.5, 6.7, 0.2, 0.3, 0.25, 0.22, 0.31, 0.24, 0.33, 0.19, 0.20, 0.25, 0.30, 0.21, 0.22, 0.25, 0.19, 0.22, 0.35, 0.2, 0.24]
    case 'availableToSellToSold':
      return [4.8, 5.1, 5.6, 5.7, 6.1, 6.2, 6.0, 5.9, 5.8, 5.6, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.6, 6.7, 6.8, 6.9, 7.1, 7.2, 7.3, 7.5, 7.6, 7.4, 7.5, 6.8, 6.9, 6.1, 6.4]
    case 'total':
      return [3.8, 4.1, 4.6, 4.7, 5.1, 5.2, 5.0, 5.9, 5.8, 4.6, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.6, 5.7, 5.8, 5.9, 6.1, 6.2, 6.3, 6.5, 5.6, 5.4, 5.5, 5.8, 5.9, 5.1, 5.4]
    default:

  }
}

const statusChartHeader = (status) => {
  switch (status) {
    case 'createdToMeasure':
      return 'Created to Measure (Y: Average Time in Days, X: Last 30 Days)'
    case 'measuredToReviewed':
      return 'Measured to Reviewed (Y: Average Time in Hours, X: Last 30 Days)'
    case 'reviewedToAvailableToSell':
      return 'Reviewed to Available to Sell (Y: Average Time in Hours, X: Last 30 Days)'
    case 'refigures':
      return 'Refigures (Y: Average Time in Hours, X: Last 30 Days)'
    case 'availableToSellToSold':
      return 'Available to Sell to Sold (Y: Average Time in Days, X: Last 30 Days)'
    case 'total':
      return 'Total (Y: Average Time in Days, X: Last 30 Days)'
    default:
  }
}

const statusChartBorderColor = (status) => {
  switch (status) {
    case 'createdToMeasure':
      return brandSuccess;
    case 'measuredToReviewed':
      return brandSuccess;
    case 'reviewedToAvailableToSell':
      return brandSuccess;
    case 'refigures':
      return brandInfo;
    case 'availableToSellToSold':
      return brandInfo;
    case 'total':
      return brandSuccess;
    default:
  }
}

const statusChart = (status) => {
  return {
    labels: yAxisLabels(),
    datasets: [
      {
        data            : statusChartData(status),
        label           : statusChartHeader(status),
        backgroundColor : convertHex(brandInfo, 10),
        borderColor     : statusChartBorderColor(status),
        borderWidth     : 2,
        fill            :false
      }
    ]
  }
}

const statusChartOptions = (status) => {
  let yAxisMaxValue = 0;
  switch (status) {
    case 'createdToMeasure':
      yAxisMaxValue = 5
      break;
    case 'measuredToReviewed':
      yAxisMaxValue = 24
      break;
    case 'reviewedToAvailableToSell':
      yAxisMaxValue = 6
      break;
    case 'refigures':
      yAxisMaxValue = 8
      break;
    case 'availableToSellToSold':
      yAxisMaxValue = 10
      break;
    case 'total':
      yAxisMaxValue = 7
      break;
    default:

  }

  return {
    maintainAspectRatio: false,
    legend: { display: true, position: 'bottom' },
    scales: {
      xAxes: [{
        barThickness:'flex',
        gridLines: {
          drawOnChartArea: false,
          offSetGridlines: true,
          beginAtZero:true
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: yAxisMaxValue + 1,
          stepSize: 1,
          max: yAxisMaxValue,
          stacked: true
        }
      }]
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
  }
}

const helpModalOptions = (status) => {
  let body  = '';
  let title = '';
  switch (status) {
    case 'waitingForMeasure':
      body  += 'The project has been created but the Detail has not been uploaded to the IMS.';
      title += 'Measures Pending';
      break;
    case 'noSalesAttention':
      body  += 'The Detail has been uploaded to the IMS, but no sales activity has occurred.';
      title += 'New Details in IMS';
      break;
    case 'waitingForCustomerAttention':
      body += 'These projects are awaiting action from the customer.';
      title += 'Awaiting Customer Response';
      break;
    case 'waitingForSalesAttention':
      body += 'These projects require specific action from the sales team.  EX: The customer is awaiting a call to discuss alternative flooring producs.';
      title += 'Sales Attention Required';
      break;
    case 'waitingForRefigurePreparation':
      body += 'The customer has requested a refigure, but the refigure has not been completed. ';
      title += 'Refigures Pending';
      break;
    case 'waitingForSalesAttentionRefigure':
      body += 'The requested refigure has been completed, but the customer has not been contacted or presented with a revised quote.';
      title += 'Completed Refigures';
      break;
    case 'closeRate':
      body += 'Close Rate is defined as the percentage of completed Details (over a period of time) that resulted in a sale of products and/or installed services.';
      title += 'Close Rate';
      break;
    case 'availableToSellToSold':
      body += 'This is the average time elapsed from "Ready to Sell" status to "Sold" status.';
      title += 'Available to Sell to Sold';
      break;
    case 'refigures':
      body += 'This is the average time it takes the PROvider to complete the refigure and upload to the IMS.';
      title += 'Refigures';
      break;
    case 'createdToMeasured':
      body += 'This is the average time which elapses between Detail creation and completion of the measure.';
      title += 'Created to Measured';
      break;
    case 'measuredToReviewed':
      body += 'Once the measure is complete, this is the average time it takes the PROvider to upload the assessment to the IMS. ';
      title += 'Measured to Reviewed';
      break;
    case 'reviewedToAvailableToSell':
      body += 'reviewedToAvailableToSell Message';
      title += 'Reviewed to Available to Sell';
      break;
    case 'total':
      body += 'Beginning with initial Detail creation, this is the average time it takes before the project is Ready to Sell.';
      title += 'Total';
      break;
    default:

  }
  return { body: body, title: title }
}


class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      dashboardData      : [],
      closeRateChartData : {},
      closeRateTitle     : '',
      availableToSellToSoldTimeframe     : dateRanges[0],
      refiguresTimeframe                 : dateRanges[0],
      createdToMeasuredTimeframe         : dateRanges[0],
      measuredToReviewedTimeframe        : dateRanges[0],
      reviewedToAvailableToSellTimeframe : dateRanges[0],


      isModalOpen: false,

      statusChartData : [],
      statusChartOptions : {},
      statusChartTitle:'',
      storeOptions: pullDownPlaceHolderStores,
      territoryOptions : pullDownPlaceHolderTerritories,
      installPartnerOptions : pullDownPlaceHolderPROvider,


      isHelpModalOpen: false,
      helpModalTitle:'',
      helpModalBody:'',

    };
  }

  // --- This is the component main load/mount
  componentDidMount = () => {
    this.props.loadDashboardData();
  }

  // --- This is the component unload/unmount
  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      let varThis = closeRateChart(closeRateMockDataCurrent);
      this.setState({
        dashboardData : this.props.dashboardData,
        closeRateChartData : varThis,
        closeRateTitle : "Close Rate (" + moment().format("YYYY") + ")"
      })
    }
  }

  toggle = () => {
    this.setState( { isModalOpen : !this.state.isModalOpen } )
  }

  handleStatusChartView = (status,event) => {
    this.setState( { isModalOpen : !this.state.isModalOpen, statusChartData : statusChart(status), statusChartOptions : statusChartOptions(status),statusChartTitle:statusChartHeader(status) } )
  }

  toggleHelpModal = () => {
    this.setState( { isHelpModalOpen : !this.state.isHelpModalOpen } )
  }

  handleHelpModalView = (status,event) => {
    const options = helpModalOptions(status);
    this.setState({ isHelpModalOpen : !this.state.isHelpModalOpen, 
                    helpModalBody   : options.body, 
                    helpModalTitle  : options.title 
                  })
  }

  handleCurrentYear = () => {
    let varThis = closeRateChart(closeRateMockDataCurrent);
    this.setState( { closeRateChartData : varThis, closeRateTitle : "Close Rate (" + moment().format("YYYY") + ")" } )
  }

  handlePreviousYear = () => {
    let varThis = closeRateMixedChart(closeRateMockDataCurrent,closeRateMockDataPrevious);
    this.setState( { closeRateChartData : varThis, closeRateTitle: "Close Rate (" +  moment().subtract('1','year').format("YYYY") + " - " +  moment().format("YYYY") + ")"} )
  }

  handleAvailableToSellToSoldTimeframeSelection = (event) => {
    this.setState({availableToSellToSoldTimeframe : event.target.value})
  }

  handleRefiguresTimeframeSelection = (event) => {
    this.setState({refiguresTimeframe : event.target.value})
  }

  handleCreatedToMeasureTimeframeSelection = (event) => {
    this.setState({createdToMeasuredTimeframe : event.target.value})
  }

  handleMeasuredToReviewedTimeframeSelection = (event) => {
    this.setState({measuredToReviewedTimeframe : event.target.value})
  }

  handleReviewedToAvailableToSellTimeframeSelection = (event) => {
    this.setState({reviewedToAvailableToSellTimeframe : event.target.value})
  }

  handleStoreSelection = (event) => {
    this.setState({storeOptions : event.target.value})
  }

  handleTerritorySelection = (event) => {
    this.setState({territoryOptions : event.target.value})
  }

  handleInstallPartnerSelection = (event) => {
    this.setState({installPartnerOptions : event.target.value})
  }

  render() {
    return (
      <div className='animated fadeIn'>
        {/* ---------------- */}
        {/* Chart Data Modal */}
        {/* ---------------- */}
        <div>
          <Modal size="lg" isOpen={this.state.isModalOpen} toggle={this.toggle} >
            <ModalHeader> {this.state.statusChartTitle} </ModalHeader>
            <ModalBody>
              <div  style={{height: '350px', marginTop: 40 + 'px'}}>
                <Line
                  data={this.state.statusChartData}
                  options={this.state.statusChartOptions}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" ><FaPrint style={{color:'white',fontSize:'1rem',marginBottom:'2px'}}/></Button>
              <Button color="danger" onClick={this.toggle}>Close</Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* ----------------- */}
        {/* Help Screen Modal */}
        {/* ----------------- */}
        <div>
          <Modal size="md" isOpen={this.state.isHelpModalOpen} toggle={this.toggleHelpModal} >
            <ModalHeader> <h4>{this.state.helpModalTitle} </h4></ModalHeader>
            <ModalBody>
              <div style={{height: '250px', marginTop:40}}>
                <h5>
                  { this.state.helpModalBody }
                </h5>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" ><FaPrint style={{color:'white',fontSize:'1rem',marginBottom:'2px'}}/></Button>
              <Button color="danger" onClick={this.toggleHelpModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </div>
        <Row style={{marginBottom:'15px'}}>
          <Col lg="4" style={{fontSize:'1.2rem',fontWeight:'bold', fontStyle:'italic',textAlign:'center'}}>
            Stores: <Select
              value={this.state.storeOptions}
              onChange={this.handleStoreSelection}
              displayEmpty
            >
              <MenuItem value="All" > All  </MenuItem>
              <MenuItem value="0001"> 0001 </MenuItem>
              <MenuItem value="0002"> 0002 </MenuItem>
              <MenuItem value="0003"> 0003 </MenuItem>
              <MenuItem value="0003"> 0004 </MenuItem>
            </Select>
          </Col>
          <Col lg="4" style={{fontSize:'1.2rem',fontWeight:'bold', fontStyle:'italic',textAlign:'center'}}>
            Territories: <Select
              value={this.state.territoryOptions}
              onChange={this.handleTerritorySelection}
              displayEmpty
            >
              <MenuItem value="All"         > All       </MenuItem>
              <MenuItem value=""    disabled> Divisions </MenuItem>
              <MenuItem value="Division 1"  > 1         </MenuItem>
              <MenuItem value="Division 2"  > 2         </MenuItem>
              <MenuItem value="Division 3"  > 3         </MenuItem>
              <MenuItem value="Division 4"  > 4         </MenuItem>
              <MenuItem value=""    disabled> Regions   </MenuItem>
              <MenuItem value="Region 1"    > 1         </MenuItem>
              <MenuItem value="Region 2"    > 2         </MenuItem>
              <MenuItem value="Region 3"    > 3         </MenuItem>
              <MenuItem value="Region 4"    > 4         </MenuItem>
              <MenuItem value=""    disabled> Markets   </MenuItem>
              <MenuItem value="Market 1234" > 1234      </MenuItem>
              <MenuItem value="Market 3456" > 3456      </MenuItem>
              <MenuItem value="Market 2312" > 2312      </MenuItem>
              <MenuItem value="Market 6543" > 6543      </MenuItem>
            </Select>
          </Col>
          <Col lg="4" style={{fontSize:'1.2rem',fontWeight:'bold', fontStyle:'italic',textAlign:'center'}}>
            Provider:  <Select
              value={this.state.installPartnerOptions}
              onChange={this.handleInstallPartnerSelection}
              displayEmpty
            >
              <MenuItem value="All"                  > All                   </MenuItem>
              <MenuItem value="Installation Services"> Installation Services </MenuItem>
              <MenuItem value="Keller"               > Keller                </MenuItem>
              <MenuItem value="Service Pros"         > Service Pros          </MenuItem>
              <MenuItem value="Ultimate"             > Ultimate              </MenuItem>
            </Select>
          </Col>
        </Row>
        {/* ----------------------------- */}
        {/* --- Measuring Performance --- */}
        {/* ----------------------------- */}
        <Row style={{marginBottom:'15px', justifyContent:'center'}}>
          <Col lg="2">
            <Card style={{borderRadius:'10px', height:'90%'}}>
              <Row style={cardStyle.cardHeaderSales}>
                <Col lg="2" >
                  <Button color="link" onClick={this.handleHelpModalView.bind(this,'noSalesAttention')}>
                    <FaInfoCircle style={{fontSize:'1.25rem', color:'#fff'}}/>
                  </Button>
                </Col>
                <Col lg="8" style={cardStyle.cardHeaderCol} >
                  <h5>Sales</h5>
                </Col>
              </Row>
              <Link style= {{textDecoration:'none',color: 'black'}} to={`/contactLog/${cardTitle.NoSalesAttention.value }` }>
                <CardBody style={{height:200}}>
                  <Row>
                    <Col xs="3" lg="2">
                    </Col>
                    <Col xs="6" lg="8">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.NoSalesAttention.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.NoSalesAttention.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.NoSalesAttention.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="2"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h5 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.NoSalesAttention.display } 
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:10}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    { getCardTotal(this.state.dashboardData,cardTitle.NoSalesAttention.value) }
                  </Button>
                </CardFooter>
              </Link>
            </Card>
          </Col>

          <Col lg="2">
            <Card style={{borderRadius:'10px', height:'90%'}}>
              <Row style={cardStyle.cardHeaderSales}>
                <Col lg="2">
                  <Button color="link" onClick={this.handleHelpModalView.bind(this,'waitingForSalesAttentionRefigure')}>
                    <FaInfoCircle style={{fontSize:'1.25rem', color:'#fff'}}/>
                  </Button>
                </Col>
                <Col lg="8" style={cardStyle.cardHeaderCol} >
                  <h5>Sales</h5>
                </Col>
              </Row>
              <Link style= {{textDecoration:'none',color: 'black'}} to={`/contactLog/${cardTitle.WaitingForSalesAttentionRefigure.value}` }>
                <CardBody style={{height:200}}>
                  <Row>
                    <Col lg="2"></Col>
                    <Col lg="8">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="2"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h5 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForSalesAttentionRefigure.display }
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:10}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    { getCardTotal(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value) }
                  </Button>
                </CardFooter>
              </Link>
            </Card>
          </Col>

          <Col lg="2">
              <Card style={{borderRadius:'10px', height:'90%'}}>
                <Row style={cardStyle.cardHeaderSales}>
                  <Col lg="2">
                    <Button color="link" onClick={this.handleHelpModalView.bind(this,'waitingForSalesAttention')}>
                      <FaInfoCircle style={{fontSize:'1.25rem', color:'#fff'}}/>
                    </Button>
                  </Col>
                  <Col lg="8" style={cardStyle.cardHeaderCol} >
                   <h5>Sales</h5>
                  </Col>
                </Row>
                <Link style= {{textDecoration:'none',color: 'black'}} to={`/contactLog/${cardTitle.WaitingForSalesAttention.value}` }>
                  <CardBody style={{height:200}}>
                    <Row>
                      <Col lg="2"></Col>
                      <Col lg="8">
                        <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value)]}>
                          {value => (
                            <CircularProgressbar
                              value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value)}
                              text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value)}%`}
                              circleRatio={0.75}
                              styles={buildStyles({
                                rotation: 0.63,
                                strokeLinecap: "butt",
                                trailColor: "#eee",
                              })}
                            />
                          )}
                        </ChangingProgressProvider>
                      </Col>
                      <Col lg="2"></Col>
                    </Row>
                    <Row style={{textAlign:'center'}}>
                      <Col lg="12">
                        <h5 style={{fontWeight:'bold',fontStyle:'italic'}}>
                          { cardTitle.WaitingForSalesAttention.display }
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:10}}>
                    <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                      { getCardTotal(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value) }
                    </Button>
                  </CardFooter>
                </Link>
              </Card>
          </Col>

          <Col lg="2">
            <Card style={{borderRadius:'10px', height:'90%'}}>
              <Row style={cardStyle.cardHeaderPROvider}>
                <Col lg="2" >
                  <Button color="link" onClick={this.handleHelpModalView.bind(this,'waitingForMeasure')}>
                    <FaInfoCircle style={{fontSize:'1.25rem', color:'#fff'}}/>
                  </Button>
                </Col>
                <Col lg="8" style={cardStyle.cardHeaderCol} >
                  <h5>PROvider</h5>
                </Col>
              </Row>
              <Link style= {{textDecoration:'none',color: 'black'}} to={`/contactLog/${cardTitle.WaitingForMeasure.value }` }>
                <CardBody style={{height:200}}>
                  <Row>
                    <Col lg="2">
                    </Col>
                    <Col lg="8">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForMeasure.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForMeasure.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForMeasure.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="2"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h5 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForMeasure.display } 
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:10}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontSize:'1.7rem', color:'#012169',padding:'0px',margin:'0px', width:'40%'}}>
                    { getCardTotal(this.state.dashboardData,cardTitle.WaitingForMeasure.value) }
                  </Button>
                </CardFooter>
              </Link>
            </Card>
          </Col>
          
          <Col lg="2">
            <Card style={{borderRadius:'10px', height:'90%'}}>
              <Row style={cardStyle.cardHeaderPROvider}>
                <Col lg="2">
                  <Button color="link" onClick={this.handleHelpModalView.bind(this,'waitingForRefigurePreparation')}>
                    <FaInfoCircle style={{fontSize:'1.25rem', color:'#fff'}}/>
                  </Button>
                </Col>
                <Col lg="8" style={cardStyle.cardHeaderCol} >
                  <h5>PROvider</h5>
                </Col>
              </Row>
              <Link style= {{textDecoration:'none',color: 'black'}} to={`/contactLog/${cardTitle.WaitingForRefigurePreparation.value}` }>
                <CardBody style={{height:200}}>
                  <Row>
                    <Col lg="2"></Col>
                    <Col lg="8">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="2"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h5 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForRefigurePreparation.display }
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:10}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontSize:'1.7rem', color:'#012169',padding:'0px',margin:'0px', width:'40%'}}>
                    { getCardTotal(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value) }
                  </Button>
                </CardFooter>
              </Link>
            </Card>
          </Col>
          <Col lg="2">
            <Card style={{borderRadius:'10px', height:'90%'}}>
              <Row style={cardStyle.cardHeaderCustomer}>
                <Col lg="2">
                  <Button color="link" onClick={this.handleHelpModalView.bind(this,'waitingForCustomerAttention')}>
                    <FaInfoCircle style={{fontSize:'1.25rem', color:'#fff'}}/>
                  </Button>
                </Col>
                <Col lg="8" style={cardStyle.cardHeaderCol} >
                  <h5>Customer</h5>
                </Col>
              </Row>
              <Link style= {{textDecoration:'none',color: 'black'}} to={`/contactLog/${cardTitle.WaitingForCustomerAttention.value}` }>
                <CardBody style={{height:200}}>
                  <Row>
                    <Col lg="2"></Col>
                    <Col lg="8">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value) ]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="2"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h5 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForCustomerAttention.display }
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:10}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontSize:'1.7rem', color:'#086612',padding:'0px',margin:'0px', width:'40%'}}>
                    { getCardTotal(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value) }
                  </Button>
                </CardFooter>
              </Link>
            </Card>
          </Col>
          
          
          
          {/*<Col lg="12">
            <CardDeck>
              <Card style={{borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.NoSalesAttention.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.NoSalesAttention.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.NoSalesAttention.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="3"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.NoSalesAttention.display }
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:'0px'}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    <Link style= {{textDecoration:'none',color: 'Red'}} to={`/contactLog/${cardTitle.NoSalesAttention.value }` }>
                      { getCardTotal(this.state.dashboardData,cardTitle.NoSalesAttention.value) }
                    </Link>
                  </Button>
                </CardFooter>
              </Card>

              <Card style={{borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value) ]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="3"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForCustomerAttention.display }
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:'0px'}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    <Link style= {{textDecoration:'none',color: 'Red'}} to={`/contactLog/${cardTitle.WaitingForCustomerAttention.value}` }>
                      { getCardTotal(this.state.dashboardData,cardTitle.WaitingForCustomerAttention.value) }
                    </Link>
                  </Button>
                </CardFooter>

              </Card>

              <Card style={{borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="3"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForSalesAttention.display }
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:'0px'}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    <Link style= {{textDecoration:'none',color: 'Red'}} to={`/contactLog/${cardTitle.WaitingForSalesAttention.value}` }>
                      { getCardTotal(this.state.dashboardData,cardTitle.WaitingForSalesAttention.value) }
                    </Link>
                  </Button>
                </CardFooter>

              </Card>

              <Card style={{borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="3"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForRefigurePreparation.display }
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:'0px'}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    <Link style= {{textDecoration:'none',color: 'Red'}} to={`/contactLog/${cardTitle.WaitingForRefigurePreparation.value}` }>
                      { getCardTotal(this.state.dashboardData,cardTitle.WaitingForRefigurePreparation.value) }
                    </Link>
                  </Button>
                </CardFooter>
              </Card>

              <Card style={{borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                      <ChangingProgressProvider values={[0, getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value)]}>
                        {value => (
                          <CircularProgressbar
                            value={getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value)}
                            text={`${getCardPercentage(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value)}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 0.63,
                              strokeLinecap: "butt",
                              trailColor: "#eee",
                            })}
                          />
                        )}
                      </ChangingProgressProvider>
                    </Col>
                    <Col lg="3"></Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'bold',fontStyle:'italic'}}>
                        { cardTitle.WaitingForSalesAttentionRefigure.display }
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{background:'rgba(0,0,0,0)', textAlign:'center',padding:'0px'}}>
                  <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}}>
                    <Link style= {{textDecoration:'none',color: 'Red'}} to={`/contactLog/${cardTitle.WaitingForSalesAttentionRefigure.value}` }>
                      { getCardTotal(this.state.dashboardData,cardTitle.WaitingForSalesAttentionRefigure.value) }
                    </Link>
                  </Button>
                </CardFooter>
              </Card>

            </CardDeck>
          </Col> */}
        </Row>
        {/* ------------------ */}
        {/* --- Close Rate --- */}
        {/* ------------------ */}
        <Row>
          <Col lg="9">
            <Card style={{borderRadius:'10px'}}>
              <Row>
                <Col lg="2">
                  <Button color="link" onClick={this.handleHelpModalView.bind(this,'closeRate')}>
                    <FaInfoCircle style={{fontSize:'1.25rem',}}/>
                  </Button>
                </Col>
              </Row>
              <CardBody>
                <Row>
                  <Col sm="4">
                    <CardTitle className="mb-0" style={{fontSize:'1.25rem',fontWeight:'bold',fontStyle:'italic'}}>
                      {this.state.closeRateTitle}
                    </CardTitle>
                  </Col>

                  <Col lg="4" style={{textAlign:'center'}}>
                    <ButtonGroup style={{padding:'5px'}}>
                      <Button outline color="primary" onClick={this.handlePreviousYear}>
                        { moment().format("YYYY") } - { moment().subtract('1','year').format("YYYY") } Comparison
                      </Button>
                      <Button outline color="primary" onClick={this.handleCurrentYear }>
                        { moment().format("YYYY") } Only
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col lg="4"></Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="chart-wrapper" style={{height: '380px', marginTop: '5px'}}>
                      <Bar
                        data={this.state.closeRateChartData}
                        options={closeRateChartOptions}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" style={{paddingBottom:'2%'}}>
            <Row>
              <Col lg="12">
                <h2 style={{textAlign:'center',fontWeight:'bold',fontStyle:'italic',marginBottom:'10px'}}>Selling Timeline</h2>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card style={{backgroundColor:brandInfo, borderRadius:'10px'}}>
                  <CardBody>
                    <Row>
                      <Col lg="2">
                        <Button color="link" onClick={this.handleHelpModalView.bind(this,'availableToSellToSold')}>
                          <FaInfoCircle style={{color:'white', fontSize:'1.25rem',}}/>
                        </Button>
                      </Col>
                      <Col lg="10" style={{justifyContent:'right',textAlign:'right'}}>
                        <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}} onClick={this.handleStatusChartView.bind(this,'availableToSellToSold')}>
                            <FaChartLine style={{color:'white',fontSize:'2rem',marginBottom:'20px'}}/>
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{textAlign:'center'}}>
                      <Col lg="12">
                        <h4 style={{fontWeight:'normal',fontStyle:'italic', color:'white'}}>
                          Ready to Sell <FaArrowRight style={{color:'white',fontSize:'1.2rem',marginBottom:'3px',marginLeft:'5px', marginRight:'5px'}}/> <b>Sold</b>
                        </h4>
                        <h5 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'2rem', color:'white'}}>
                          {availableToSellToSoldValue(this.state.availableToSellToSoldTimeframe)}
                        </h5>
                        <h6 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'1rem', color:'white'}}>
                          (Per Project)
                        </h6>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card style={{backgroundColor:brandInfo, borderRadius:'10px'}}>
                  <CardBody>
                    <Row>
                      <Col lg="2">
                        <Button color="link" onClick={this.handleHelpModalView.bind(this,'refigures')}>
                          <FaInfoCircle style={{color:'white', fontSize:'1.25rem',}}/>
                        </Button>
                      </Col>
                      <Col lg="10" style={{justifyContent:'right',textAlign:'right'}}>
                        <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}} onClick={this.handleStatusChartView.bind(this,'refigures')}>
                            <FaChartLine style={{color:'white',fontSize:'2rem',marginBottom:'20px'}}/>
                        </Button>
                      </Col>
                      <Col lg="7"></Col>
                    </Row>
                    <Row style={{textAlign:'center'}}>
                      <Col lg="12">
                        <h4 style={{fontWeight:'bold',fontStyle:'italic', color:'white'}}>
                          Refigures
                        </h4>
                        <h5 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'2rem', color:'white'}}>
                          {refiguresValue(this.state.refiguresTimeframe)}
                        </h5>
                        <h6 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'1rem', color:'white'}}>
                          (Per Refigure)
                        </h6>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* --------------------------- */}
        {/* --- Selling Performance --- */}
        {/* --------------------------- */}
        <Row style={{marginBottom:'15px'}}>
          <Col>
            <h2 style={{textAlign:'center',fontWeight:'bold',fontStyle:'italic',marginBottom:'10px'}}>Measuring Timeline</h2>
          </Col>
          <Col lg="12">
            <CardDeck>
              <Card style={{backgroundColor:brandSuccess, borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="4">
                      <Button color="link" onClick={this.handleHelpModalView.bind(this,'createdToMeasured')}>
                        <FaInfoCircle style={{color:'white', fontSize:'1.25rem',}}/>
                      </Button>
                    </Col>
                    <Col lg="4" style={{textAlign:'center'}}>
                      <FaArrowRight style={{color:'white',fontSize:'2rem',marginBottom:'15px'}}/>
                    </Col>
                    <Col lg="4" style={{textAlign: 'right'}}>
                      <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}} onClick={this.handleStatusChartView.bind(this,'createdToMeasure')}>
                          <FaChartLine style={{color:'white',fontSize:'2rem',marginBottom:'20px'}}/>
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'normal',fontStyle:'italic', color:'white'}}>
                        Detail Created <FaArrowRight style={{color:'white',fontSize:'1.2rem',marginBottom:'3px',marginLeft:'5px', marginRight:'5px'}}/> <b>Measured</b>
                      </h4>
                      <h5 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'2rem', color:'white'}}>
                        { createdToMeasuredValueDisplay(createdToMeasuredValue(this.state.createdToMeasuredTimeframe)) }
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card style={{backgroundColor:brandSuccess, borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="4">
                      <Button color="link" onClick={this.handleHelpModalView.bind(this,'measuredToReviewed')}>
                        <FaInfoCircle style={{color:'white', fontSize:'1.25rem',}}/>
                      </Button>
                    </Col>
                    <Col lg="4" style={{textAlign:'center'}}>
                      <FaArrowRight style={{color:'white',fontSize:'2rem',marginBottom:'15px'}}/>
                    </Col>
                    <Col lg="4" style={{textAlign:'right'}}>
                      <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}} onClick={this.handleStatusChartView.bind(this,'measuredToReviewed')}>
                          <FaChartLine style={{color:'white',fontSize:'2rem',marginBottom:'20px'}}/>
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'normal',fontStyle:'italic', color:'white'}}>
                        Measured <FaArrowRight style={{color:'white',fontSize:'1.2rem',marginBottom:'3px',marginLeft:'5px', marginRight:'5px'}}/> <b>IMS</b>
                      </h4>
                      <h5 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'2rem', color:'white'}}>
                        {measuredToReviewedValue(this.state.measuredToReviewedTimeframe)} Hours
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* <Card style={{backgroundColor:brandSuccess, borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="4">
                      <Button color="link" onClick={this.handleHelpModalView.bind(this,'reviewedToAvailableToSell')}>
                        <FaInfoCircle style={{color:'white', fontSize:'1.25rem',}}/>
                      </Button>
                    </Col>
                    <Col lg="4" style={{textAlign:'center'}}>
                      <FaArrowRight style={{color:'white',fontSize:'2rem',marginBottom:'15px'}}/>
                    </Col>
                    <Col lg="4" style={{textAlign:'right'}}>
                      <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}} onClick={this.handleStatusChartView.bind(this,'reviewedToAvailableToSell')}>
                          <FaChartLine style={{color:'white',fontSize:'2rem',marginBottom:'20px'}}/>
                      </Button>
                    </Col>

                  </Row>
                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'normal',fontStyle:'italic', color:'white'}}>
                        Reviewed to <b>Available to Sell</b>
                      </h4>
                      <h5 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'2rem', color:'white'}}>
                        {reviewedToAvailableToSellValue(this.state.reviewedToAvailableToSellTimeframe)} Hours
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}

              <Card style={{backgroundColor:brandSuccess, borderRadius:'10px'}}>
                <CardBody>
                  <Row>
                    <Col lg="4">
                      <Button color="link" onClick={this.handleHelpModalView.bind(this,'total')}>
                        <FaInfoCircle style={{color:'white', fontSize:'1.25rem',}}/>
                      </Button>
                    </Col>
                  
                    <Col lg="8" style={{textAlign:'right'}}>
                      <Button color="link" style={{textDecoration:'none',backgroundColor:'rgba(0,0,0,0)',fontWeight:'bold',fontStyle:'italic',fontSize:'1.7rem', color:'red',padding:'0px',margin:'0px', width:'40%'}} onClick={this.handleStatusChartView.bind(this,'total')}>
                          <FaChartLine style={{color:'white',fontSize:'2rem',marginBottom:'20px'}}/>
                      </Button>
                    </Col>
                  </Row>

                  <Row style={{textAlign:'center'}}>
                    <Col lg="12">
                      <h4 style={{fontWeight:'normal',fontStyle:'italic', color:'white'}}>
                        Detail Created <FaArrowRight style={{color:'white',fontSize:'1.2rem',marginBottom:'3px',marginLeft:'5px', marginRight:'5px'}}/> <b>Ready to Sell</b>
                      </h4>
                      <h5 style={{fontWeight:'bold',fontStyle:'italic',fontSize:'2rem', color:'white'}}>
                        {total(createdToMeasuredValue(this.state.createdToMeasuredTimeframe),measuredToReviewedValue(this.state.measuredToReviewedTimeframe))}
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardDeck>
          </Col>
        </Row>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  return {  dashboardData : state.dashboardData };
}

const mapDispatchToProps = dispatch => ({
  loadDashboardData : () => { return dispatch(dashboardActions.getDashboardData()); },
});

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
