import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import * as actions  from '../../actions/roughQuoteActions';
import { Row, Col } from 'reactstrap';
import { FaRulerCombined, FaChartArea } from 'react-icons/fa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const getLink = (selection) => {
       if (selection == 'project') return '/rough-quote-by-project';
  else if (selection == 'room'   ) return '/rough-quote-by-room';
  else                             return '/rough-quote';
}

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class RoughQuoteMenu extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      selection: 'none',
    };
  }

  componentDidMount = () => {
    this.props.updateData([]);
  }

  componentWillUnmount = (         ) => {}
  componentDidUpdate   = (prevProps) => {}

  handleSelection = (event) => {
    this.setState({selection: event.target.value})
  }

  render() {
    return ( 
      <div>
          <Paper style={{padding:50}}>
            <Typography variant="h5" style={{marginBottom:100}} >
              Do you want to enter the measure dimensions for each room individually, or enter the total square footage for the flooring area?
            </Typography>  
            <Row>
              <Col xs='1'  sm='2' md='3' lg='4' xl='4'></Col>
              <Col xs='10' sm='8' md='6' lg='4' xl='4'>
                <Select
                  value={this.state.selection}
                  onChange={this.handleSelection}
                  style={{width:'100%'}}
                >
                  <MenuItem value='none'>
                    Please Select
                  </MenuItem>
                  <MenuItem value='room'>
                    <FaRulerCombined style={{marginRight:5, paddingBottom:2}}/> Measure each room individually
                  </MenuItem>
                  <MenuItem value='project'>
                    <FaChartArea style={{marginRight:5, paddingBottom:2}}/> Enter the total SF of flooring area
                  </MenuItem>
                </Select>
              </Col>
              <Col xs='1'  sm='2' md='3' lg='4' xl='4'></Col>
            </Row>
            <Row style={{marginTop:100}}>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
              <Col xs='8' sm='8' md='6' lg='4' xl='4' style={{textAlign:'center'}}>
                <Link to="/Dashboard" style={{textDecoration:'none'}}>
                  <Button variant='contained' color='secondary' style={{marginRight:5}}>
                    Cancel 
                  </Button>
                </Link>
                <Link to={ getLink(this.state.selection) } style={{textDecoration:'none'}}>
                  <Button variant='contained' color='primary' style={{marginLeft:5}}>
                    Next
                  </Button>
                </Link>
              </Col>
              <Col xs='2' sm='2' md='3' lg='4' xl='4'></Col>
            </Row>
          </Paper>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
}

const mapDispatchToProps = dispatch => ({
  updateData: (data) => { return dispatch(actions.updateRoughQuoteData(data)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(RoughQuoteMenu);
