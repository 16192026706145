//import ProjectApi from "../api/mockProjectApi";
import DashboardApi from "../api/dashboardApi"
//import RefigureApi from "../api/refigureApi";
//import { beginAjaxCall } from "./ajaxStatusActions";
import types from "./actionTypes";
import dashboardData from "./../api/DashboardMockDataReviewed.json";

/*export function loadProjectsError(projects) {
  return { type: types.LOAD_PROJECTS_FAILURE, projects };
}

export function loadProjectsSuccess(projects) {
  return { type: types.LOAD_PROJECTS_SUCCESS, projects };
}

export function updateProjectError(project) {
  return { type: types.UPDATE_PROJECT_FAILURE, project };
}

export function updateProjectSuccess(project) {
    return { type: types.UPDATE_PROJECT_SUCCESS, project };
}

export function createRefigureError(project) {
  return { type: types.CREATE_REFIGURE_FAILURE, project };
}

export function createRefigureSuccess(project) {
  return { type: types.CREATE_REFIGURE_SUCCESS, project };
}

export function loadSalesDocumentsError(documents) {
  return { type: types.LOAD_SALES_DOCUMENTS_FAILURE, documents };
}

export function loadSalesDocumentsSuccess(documents) {
  return { type: types.LOAD_SALES_DOCUMENTS_SUCCESS, documents };
}

export function loadFloorPlansError(floorPlans) {
  return { type: types.LOAD_FLOOR_PLANS_FAILURE, floorPlans };
}

export function loadFloorPlansSuccess(floorPlans) {
  return { type: types.LOAD_FLOOR_PLANS_SUCCESS, floorPlans };
}

export function loadRefiguresSuccess(refigures) {
  return { type: types.LOAD_REFIGURES_SUCCESS, refigures };
}*/

export function getDataSuccess(dashboardData) {
  return { type: types.DASHBOARD_DATA_FETCH_SUCCESS, dashboardData };
}

export function getDataFailure(message) {
  return { type: types.DASHBOARD_DATA_FETCH_FAILURE, message };
}

/*export function loadProjects(fromDate,toDate) {
    let from = fromDate ? fromDate : '';
    let to   = toDate   ? toDate   : '';
    return (dispatch) => {
        dispatch(beginAjaxCall());
        return ProjectApi.projectSearch(fromDate,toDate)
          .then((projects) => {
            dispatch(loadProjectsSuccess(projects));
          })
          .catch((error) => {
            throw(error);
          });
    };
}*/

/*export function loadRefigures(projectId) {
    return (dispatch) => {
        dispatch(beginAjaxCall());
        return RefigureApi.getRefigures(projectId)
          .then((refigures) => {
            dispatch(loadRefiguresSuccess(refigures));
          })
          .catch((error) => {
            throw(error);
          });
    };
}*/

export function getDashboardData() {
  // Dispatches "dataSuccess" function passing in the constructed array to update the orderInfo Reducer
  return (dispatch) => {
    dispatch(getDataSuccess(dashboardData));
  }
}
