import React     from 'react';
import PropTypes from 'prop-types';
import Home      from './Home';
import LoginPage from './common/LoginPage';


const App = ({ onLoginUser, isAuthenticated, errorMessage, ...props }) => {
  return (
    <div>
      { isAuthenticated ? ( <Home /> ) : ( <LoginPage onLoginUser = { onLoginUser } errorMessage = { errorMessage } /> ) }
    </div>
  );
};

App.propTypes = {
  onLoginUser     : PropTypes.func.isRequired,
  isAuthenticated : PropTypes.bool.isRequired,
  errorMessage    : PropTypes.string,
};

export default App;
