import React, { Component } from 'react';
//import PropTypes from 'prop-types';


class Statistics extends Component {
  //constructor(props, context) {
  //  super(props, context);
  //}

  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Statistics</h2>
      </div>
    );
  }
}

Statistics.propTypes = {

};

export default Statistics;
