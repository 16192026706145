//import ProjectApi from "../api/mockProjectApi";
import ProjectApi from "../api/projectApi"
//import RefigureApi from "../api/refigureApi";
//import { beginAjaxCall } from "./ajaxStatusActions";
import types from "./actionTypes";
import mockProjectData from '../api/full-refigurator-projects-no-null.json';
import moment from 'moment';


/*export function loadProjectsError(projects) {
  return { type: types.LOAD_PROJECTS_FAILURE, projects };
}

export function loadProjectsSuccess(projects) {
  return { type: types.LOAD_PROJECTS_SUCCESS, projects };
}

export function updateProjectError(project) {
  return { type: types.UPDATE_PROJECT_FAILURE, project };
}

export function updateProjectSuccess(project) {
    return { type: types.UPDATE_PROJECT_SUCCESS, project };
}

export function createRefigureError(project) {
  return { type: types.CREATE_REFIGURE_FAILURE, project };
}

export function createRefigureSuccess(project) {
  return { type: types.CREATE_REFIGURE_SUCCESS, project };
}

export function loadSalesDocumentsError(documents) {
  return { type: types.LOAD_SALES_DOCUMENTS_FAILURE, documents };
}

export function loadSalesDocumentsSuccess(documents) {
  return { type: types.LOAD_SALES_DOCUMENTS_SUCCESS, documents };
}

export function loadFloorPlansError(floorPlans) {
  return { type: types.LOAD_FLOOR_PLANS_FAILURE, floorPlans };
}

export function loadFloorPlansSuccess(floorPlans) {
  return { type: types.LOAD_FLOOR_PLANS_SUCCESS, floorPlans };
}

export function loadRefiguresSuccess(refigures) {
  return { type: types.LOAD_REFIGURES_SUCCESS, refigures };
}*/

export function projectSearchSuccess(projects) {
  return { type: types.PROJECT_SEARCH_SUCCESS, projects };
}

export function projectSearchFailure(message) {
  return { type: types.PROJECT_SEARCH_FAILURE, message };
}

/*export function loadProjects(fromDate,toDate) {
    let from = fromDate ? fromDate : '';
    let to   = toDate   ? toDate   : '';
    return (dispatch) => {
        dispatch(beginAjaxCall());
        return ProjectApi.projectSearch(fromDate,toDate)
          .then((projects) => {
            dispatch(loadProjectsSuccess(projects));
          })
          .catch((error) => {
            throw(error);
          });
    };
}*/

/*export function loadRefigures(projectId) {
    return (dispatch) => {
        dispatch(beginAjaxCall());
        return RefigureApi.getRefigures(projectId)
          .then((refigures) => {
            dispatch(loadRefiguresSuccess(refigures));
          })
          .catch((error) => {
            throw(error);
          });
    };
}*/

/*export function projectSearch(fromDate,toDate) {
  return (dispatch) => {
    return ProjectApi.projectSearch(fromDate,toDate)
      .then((response) => {
        const msg = response.lstMessages;
        if (msg.length > 0) {
          if (msg[0] === 'Successful projectSearchByDate Request') {
            dispatch(projectSearchSuccess(response.lstProjectSearch));
          }
          else {
            dispatch(projectSearchFailure(msg[0]));
          }
        }
      })
      .catch((err) => {
        throw(err);
      });
  }
}*/

export function projectSearch(fromDate,toDate) {
  const fromDateFormat = moment(fromDate, 'YYYY-MM-DD');
  const toDateFormat   = moment(toDate  , 'YYYY-MM-DD');
  const listProject    = mockProjectData.filter(curProject => moment(curProject.dateCreated, 'YYYY-MM-DD').isBetween(fromDate,toDate));
  
  // Dispatches "dataSuccess" function passing in the constructed array to update the orderInfo Reducer
  return (dispatch) => {
    dispatch(projectSearchSuccess(listProject));
  }
}

