//import delay from './delay';
//import moment from 'moment';
//import allProjects from './full-refigurator-projects-no-null.json';

const api_url = 'https://test4.floorsoft.com/FloorWizard/fwapi/refigure/projectrefigure';

function handleErrors (response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
}


class RefigureApi {
  /*static getRefigures(projectId) {
    const data = allProjects.filter(project => project.projectId === projectId);
    return new Promise((resolve, reject) => {
      setTimeout(() => { resolve(Object.assign([], data[0].refigures)); }, delay);
    });
  }*/

  static loadRefigures(projectId) {
    const options = {
      "sessionToken":localStorage.getItem('access_token'),
      "lstMessages":null,
      "projectId":projectId,
      "lstProjectRefigure":null
    }
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options)
    };

    return new Promise((resolve, reject) => {
      fetch(api_url, settings)
        .then(handleErrors)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }




  /*static login(user, override) {
    user = Object.assign({}, user);
    const url = `${api_url}/logon`;
    const status = override
      ? 'Session Logon Status Request Override'
      : 'Session Logon Status Request Log On';
    const options = {
      'sessionStatus': status,
      'sessionLogon': user.username,
      'sessionPassword': user.password,
      'sessionTime': getSessionTime(),
      'sessionComponent': 'Session Component Refigurator',
      'computerName': 'user',  // This should be removed.. but currently causes an error.
      'computerAddress': '', // This should be removed.. but currently causes an error.
      'softwareVersion': '',
      'active': null,
    }
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options)
    };

    return new Promise((resolve, reject) => {
      fetch(url, settings)
        .then(handleErrors)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }*/


}

export default RefigureApi;
