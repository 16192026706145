import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

//import { bindActionCreators } from "redux";
//import * as projectActions  from '../../actions/projectActions';
import { showWaitScreen } from '../common/WaitScreen';
//import { Row, Col, CardGroup/*, Button, ButtonGroup, Card, CardHeader, CardBody, CardImg, CardTitle, CardText, CardGroup, CardSubtitle,InputGroup, InputGroupAddon, InputGroupText, Input*/ } from 'reactstrap';
//import * as customUI from '@material-ui/core';

import { Redirect } from 'react-router-dom';
//import moment from 'moment';

//import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { Badge,Table,Row, Col, CardHeader, CardBody, CardTitle } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { FaAngleDoubleLeft, FaEdit, FaBatteryHalf, FaSearch, FaEye, FaPhone, FaEnvelope } from 'react-icons/fa';



//import { Alert } from 'reactStrap';


//import { Card, Button, CardImg, CardTitle, CardText, CardGroup,
 //CardSubtitle, CardBody } from 'reactstrap';
 const tableColumns = [
   { dataField: 'isUsed'     , text: 'Use'        , headerAlign:'center', align: 'center', sort: true },
   { dataField: 'description', text: 'Description', headerAlign:'center', align: 'center', sort: true },
 ];

 const styles = {
   cardRange : { backgroundColor : 'rgb(245, 245, 245)'                             },
   cardData  : { backgroundColor : 'rgb(245, 245, 245)'                             },
   cardDataLoading : { backgroundColor : 'rgba(0, 0, 0,0.7)'                             },
   rowRange  : { padding         : '20px'                                           },
   colRange  : { display         : 'flex'  , alignItems : 'center'                  },
   btnSearch : { borderRadius    : '5px'   , height     : '40px'  },
   h5Range   : { textAlign       : 'center', marginTop  : '20px'                    }
 };

 const defaultSorted = [{
   dataField: 'id',
   order: 'asc'
 }];

 const getProjectHeaderDisplay = (project) => {
   let   projectId   = (project.projectId != "" ? project.projectId : "");
   let   salesPerson = (project.salesPersonName    != "" ? project.salesPersonName    : "");
   let   storeName   = (project.owningAccountName  != "" ? project.owningAccountName  : "");
   const valReturn   = (projectId != "" ? projectId + " | " : "") + (salesPerson != "" ? salesPerson : "");
   return valReturn;
 }

 const customerNameDisplay  = (project) => { return (project.customerFirstName != "" ? project.customerFirstName : "") + (project.customerLastName != "" ? project.customerLastName : "") }
 const customerPhoneDisplay = (project) => { return (project.customerPhone     != "" ? project.customerPhone     : "");                                                                   }
 const customerEmailDisplay = (project) => { return (project.customerEmail     != "" ? project.customerEmail     : "");                                                                   }



 const rollWidthSelectionOptions = [
   { value: 'None', display:'N/A'      },
   { value: 144   , display:'12\''     },
   { value: 158   , display:'13\' 2\"' },
   { value: 162   , display:'13\' 6\"' },
   { value: 165   , display:'13\' 9\"' },
   { value: 180   , display:'15\''     },
 ];



 const laySelectionOptions = [
   'Straight',
   'Vertical',
   'Horizontal',
 ];

 const installMethodSelectionOptions = [
   'Method1',
   'Method2',
   'Method3',
 ];

 const tseamSelectionOptions = [
   '0',
   '1',
   '2',
   '3',
   '4',
   '5',
 ];

 const padSelectionOptions = [
   'pad1',
   'pad2',
   'pad3',
   'pad4',
   'pad5',
 ];


 class RefigureDetails extends Component {
   constructor(props,context) {
     super(props,context);
     let selProduct = this.props.products.length > 0        ? this.props.products[0] : null;
     let selRoom    = selProduct && selProduct.rooms.length ? selProduct.rooms[0]    : null;
     this.state = {
       isLoading              : true,
       productData            : this.props.products,
       selProduct             : selProduct,
       selRoom                : selRoom,
       rollWidthSelection     : rollWidthSelectionOptions[0].value,
       laySelection           : laySelectionOptions[0],
       installMethodSelection : installMethodSelectionOptions[0],
       tseamSelection         : tseamSelectionOptions[0],
       padSelection           : padSelectionOptions[0],
     };
   }

   // --- isLoading Flag Resets To False After Component Is Build/Mounted
   componentDidMount = () => {
     this.setState( { isLoading : false } )
   }

   handleOnSelect = (row, isSelect, rowIndex, e) => {
     if (isSelect) { this.setState({ selectedRows: [row.refigureId], }); }
     else          { this.setState({ selectedRows: [],               }); }
   }

   handleTableExpandRow = (row,isSelect) => {
     if (row) {
       return (
         <div style={{marginLeft:'20px'}}>
           <Row>

           </Row>
         </div>
       )
     }
   }

   handleProductClick = (product,event) => {
     this.setState({ selProduct : product, selRoom : product.rooms.length > 0 ? product.rooms[0] : null });
     /*console.log(product, ' - ' , room, ' - ' ,event);
     let stateCopy  = this.state.productData; // Makes Copy Of Current State (List OF Rooms Per Selected Refigure)
     let selProduct = stateCopy.filter(curProduct => curProduct.itemNumber === product.itemNumber); // Filter The List Of Rooms To Only Affect The Selected Product
     let roomCopy   = selProduct[0].lstRoom;
     let selRoom    = roomCopy.filter(curRoom => curRoom.roomName === room.roomName); // Filter The List Of Rooms To Only Affect The Selected Room
     selRoom[0].isUsed = event.target.checked;
     this.setState({ productData : stateCopy }); // Set The State Back With The Newly Added Data**/
   }

   handleRoomClick = (room,event) => {
     this.setState({ selRoom : room });
     /*console.log(product, ' - ' , room, ' - ' ,event);
     let stateCopy  = this.state.productData; // Makes Copy Of Current State (List OF Rooms Per Selected Refigure)
     let selProduct = stateCopy.filter(curProduct => curProduct.itemNumber === product.itemNumber); // Filter The List Of Rooms To Only Affect The Selected Product
     let roomCopy   = selProduct[0].lstRoom;
     let selRoom    = roomCopy.filter(curRoom => curRoom.roomName === room.roomName); // Filter The List Of Rooms To Only Affect The Selected Room
     selRoom[0].isUsed = event.target.checked;
     this.setState({ productData : stateCopy }); // Set The State Back With The Newly Added Data**/
   }

   // Listener for "Room Usage" Toggle
   handleRoomChange = (product,room,event) => {
     console.log(product, ' - ' , room, ' - ' ,event);
     //let stateCopy  = this.state.productData; // Makes Copy Of Current State (List OF Rooms Per Selected Refigure)
     //let selProduct = stateCopy.filter(curProduct => curProduct.itemNumber === product.itemNumber); // Filter The List Of Rooms To Only Affect The Selected Product
     //let roomCopy   = selProduct[0].lstRoom;
     //let selRoom    = roomCopy.filter(curRoom => curRoom.roomName === room.roomName); // Filter The List Of Rooms To Only Affect The Selected Room
     //selRoom[0].isUsed = event.target.checked;
     //this.setState({ productData : stateCopy }); // Set The State Back With The Newly Added Data
   }

   handleProductChange = (product,event) => {
     //let stateCopy = this.state.productData; // Makes Copy Of Current State (List OF Rooms Per Selected Refigure)
     //let selProduct   = stateCopy.filter(curProduct => curProduct.itemNumber === product.itemNumber); // Filter The List Of Rooms To Only Affect The Selected Room
     //let roomCopy   = selProduct[0].lstRoom;
     //roomCopy.map(curRoom => ( curRoom.isUsed = event.target.checked )) // Sync Rooms To Product Usage
     //selProduct[0].isUsed = event.target.checked; // This Is The Field To Be Changed (This Can Be Expanded According To The Number Of Necessary Changes)
     //this.setState({ productData : stateCopy }); // Set The State Back With The Newly Added Data
   }

   handleLaySelection = (event) => {
     this.setState({laySelection : event.target.value})
   }

   handleInstallMethodSelection = (event) => {
     this.setState({installMethodSelection : event.target.value})
   }

   handleTseamSelection = (event) => {
     this.setState({tseamSelection : event.target.value})
   }

   handlePadSelection = (event) => {
     this.setState({padSelection : event.target.value})
   }

   handleRollWidthSelection = (event) => {
     this.setState({rollWidthSelection : event.target.value})
   }


   /*handleDetailButton = () => {
    return (
      <Alert color='primary'>
        This is a notice
      </Alert>
    )
  }*/

   render() {
     const { refigure,project                            } = this.props;
     const { selProduct,selRoom,productData,selectedRows } = this.state;

     const selectRow = {
       mode             : '',
       bgColor          : 'rgba(85, 201, 255, 0.2)',
       clickToSelect    : true,
       hideSelectAll    : true,
       hideSelectColumn : true,
       selected         : this.state.selectedRows,
       onSelect         : this.handleOnSelect,
     };

     const expandRow = {
       renderer: this.handleTableExpandRow,
       expanded: selectedRows, // This Represents The Current Selected Row
     };



     // --- Redirect To Projects If Refigure List Is Empty. Mainly Done In Case They Reload From This Page
     if (refigure.length === 0) {
       return <Redirect to='/projects' />
     }

     return (this.state.isLoading ? showWaitScreen() :
       <div className='animated'>
         <Card>
           <CardHeader style={styles.cardRow}>
             <span><FaAngleDoubleLeft/>  <Link to={`/projectRefigures/${this.props.project.projectId}` }>Refigures</Link></span>
           </CardHeader>
           { refigure && productData.length > 0 &&
           <CardBody>
             <Row>
               <Col xs='12' style={styles.cardRow}>
                 <span>
                   <b>Project</b>:  { getProjectHeaderDisplay(this.props.project) }
                 </span>
               </Col>
             </Row>
             <Row>
               <Col xs='12' style={styles.cardRow}>
                 <span>
                   <b>Refigure</b>:  { refigure.name }
                 </span>
               </Col>
             </Row>
             <Row>
               <Col xs='12' style={styles.cardRow}>
                 <span>
                   <b>Customer</b>:  { customerNameDisplay (project) != "" ? customerNameDisplay (project) + " | " : ""    }
                         <FaPhone/>  { customerPhoneDisplay(project) != "" ? customerPhoneDisplay(project) + " | " : "N/A" }
                      <FaEnvelope/>  { customerEmailDisplay(project) != "" ? customerEmailDisplay(project) + " | " : "N/A" }
                 </span>
               </Col>
             </Row>





           <Row style={{marginTop:'30px'}}>
             <Col lg="4">
               <Card>
               {
                 productData.map((curProduct, index) => (
                   <Row style={{textAlign:'center',padding:'10px'}} key={index}>
                     <Col lg="12">
                       <Checkbox
                         checked  = { curProduct.isUsed                              }
                         onChange = { this.handleProductChange.bind(this,selProduct) }
                         value    = { curProduct.isUsed ? 'checked' : 'unchecked'    }
                         color    = "primary"
                         key      = {index}
                       />
                       <Button color={selProduct && selProduct.id === curProduct.id ? "secondary" : "primary"} onClick={this.handleProductClick.bind(this,curProduct)}>
                         { curProduct.description } products
                       </Button>
                     </Col>
                     {
                       curProduct.rooms.map((curRoom, i) => (
                         <Col lg="12" key = { i }>
                           <Checkbox
                             checked  = { false                                               }
                             onChange = { this.handleRoomChange.bind(this,selProduct,curRoom) }
                             value    = { 'unchecked'                                         }
                             color    = "primary"
                             key      = {index}
                           />
                           <Button color={ "primary"} onClick={this.handleRoomClick.bind(this,curRoom)}>
                             { curRoom.roomName }
                           </Button>
                         </Col>
                       ))
                     }
                   </Row>
                 ))
               }
               </Card>
             </Col>
             <Col lg="8">
             { selProduct &&
               <Card>
                 { selProduct.isRoll &&
                   <Row style={{padding:'10px'}}>
                     <Col lg="4" style={{textAlign:'right', marginTop:'5px'}}>
                       <h5>
                         Roll Width:
                       </h5>
                     </Col>
                     <Col lg="8" style={{textAlign:'left'}}>
                       <Select
                         value={this.state.rollWidthSelection}
                         onChange={this.handleRollWidthSelection}
                         displayEmpty
                       >
                       {
                         rollWidthSelectionOptions.map((curSelection, index) => (
                           <MenuItem value={curSelection.value} key={index}>
                             { curSelection.display }
                           </MenuItem>
                         ))
                       }
                       </Select>
                     </Col>
                   </Row>
                 }

                 { selProduct.isRoll &&
                   <Row style={{padding:'10px'}}>
                     <Col lg="4" style={{textAlign:'right', marginTop:'30px'}}>
                       <h5>
                         Pattern Repeat:
                       </h5>
                     </Col>
                     <Col lg="3" style={{textAlign:'left'}}>
                       <TextField
                         id="outlined-name"
                         label="Width in Inches"
                         value={ selProduct.prWidth }
                         margin="normal"
                         variant="outlined"
                       />
                     </Col>
                     <Col lg="3" style={{textAlign:'left'}}>
                       <TextField
                         id="outlined-name"
                         label="Length in Inches"
                         value={ selProduct.prLength }
                         margin="normal"
                         variant="outlined"
                       />
                     </Col>
                   </Row>
                 }

                 {  selProduct.isTile &&
                   <Row style={{padding:'10px'}}>
                     <Col lg="4" style={{textAlign:'right', marginTop:'30px'}}>
                       <h5>
                         Tile Size:
                       </h5>
                     </Col>
                     <Col lg="3" style={{textAlign:'left'}}>
                       <TextField
                         id="outlined-name"
                         label="Width in Inches"
                         value={selProduct.width}
                         margin="normal"
                         variant="outlined"
                       />
                     </Col>
                     <Col lg="3" style={{textAlign:'left'}}>
                       <TextField
                         id="outlined-name"
                         label="Length in Inches"
                         value={selProduct.length}
                         margin="normal"
                         variant="outlined"
                       />
                     </Col>
                   </Row>
                 }
                 { selProduct.isPlank &&
                   <Row style={{padding:'10px'}}>
                     <Col lg="4" style={{textAlign:'right', marginTop:'5px'}}>
                       <h5>
                         Description:
                       </h5>
                     </Col>
                     <Col lg="8" style={{textAlign:'left'}}>
                       <Select
                         value={this.state.laySelection}
                         onChange={this.handleLaySelection}
                         displayEmpty
                       >
                       {
                         laySelectionOptions.map((curSelection, index) => (
                           <MenuItem value={curSelection} key={index}>
                             {curSelection}
                           </MenuItem>
                         ))
                       }
                       </Select>
                     </Col>
                   </Row>
                 }




                 <Row style={{padding:'10px'}}>
                   <Col lg="4" style={{textAlign:'right', marginTop:'5px'}}>
                     <h5>
                       Lay:
                     </h5>
                   </Col>
                   <Col lg="8" style={{textAlign:'left'}}>
                     <Select
                       value={this.state.laySelection}
                       onChange={this.handleLaySelection}
                       displayEmpty
                     >
                     {
                       laySelectionOptions.map((curSelection, index) => (
                         <MenuItem value={curSelection} key={index}>
                           {curSelection}
                         </MenuItem>
                       ))
                     }
                     </Select>
                   </Col>
                 </Row>

                 <Row style={{padding:'10px'}}>
                   <Col lg="4" style={{textAlign:'right', marginTop:'5px'}}>
                     <h5>
                       Install Method:
                     </h5>
                   </Col>
                   <Col lg="8" style={{textAlign:'left'}}>
                     <Select
                       value={this.state.installMethodSelection}
                       onChange={this.handleInstallMethodSelection}
                       displayEmpty
                     >
                     {
                       installMethodSelectionOptions.map((curSelection, index) => (
                         <MenuItem value={curSelection} key={index}>
                           {curSelection}
                         </MenuItem>
                       ))
                     }
                     </Select>
                   </Col>
                 </Row>





                 <Row style={{padding:'10px'}}>
                   <Col lg="4" style={{textAlign:'right', marginTop:'5px'}}>
                     <h5>
                       T-Seams:
                     </h5>
                   </Col>
                   <Col lg="8" style={{textAlign:'left'}}>
                     <Select
                       value={this.state.tseamSelection}
                       onChange={this.handleTseamSelection}
                       displayEmpty
                     >
                     {
                       tseamSelectionOptions.map((curSelection, index) => (
                         <MenuItem value={curSelection} key={index}>
                           {curSelection}
                         </MenuItem>
                       ))
                     }
                     </Select>
                   </Col>
                 </Row>

                 <Row style={{padding:'10px'}}>
                   <Col lg="4" style={{textAlign:'right', marginTop:'5px'}}>
                     <h5>
                       Pads:
                     </h5>
                   </Col>
                   <Col lg="8" style={{textAlign:'left'}}>
                     <Select
                       value={this.state.padSelection}
                       onChange={this.handlePadSelection}
                       displayEmpty
                     >
                     {
                       padSelectionOptions.map((curSelection, index) => (
                         <MenuItem value={curSelection} key={index}>
                           {curSelection}
                         </MenuItem>
                       ))
                     }
                     </Select>
                   </Col>
                 </Row>

                 <Row style={{padding:'15px'}}>
                   <Col lg="1"></Col>
                   <Col lg="10" style={{textAlign:'center'}}>
                     <h5>
                       Furniture & Appliance Handling
                     </h5>
                     <Button color="primary">
                       <span><FaEdit/> Add / Edit Entries</span>
                     </Button>
                     <List style={{maxHeight:200, overflow:'auto', borderStyle:'groove', borderWidth:'1px'}}>
                       <ListItem>
                         <ListItemText primary="item 1" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 2" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 3" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                       <Divider/>
                       <ListItem>
                         <ListItemText primary="item 4" />
                       </ListItem>
                     </List>
                   </Col>
                   <Col lg="1"></Col>
                 </Row>

               </Card>
             }
             </Col>
           </Row>
           </CardBody>
        }
        </Card>

      </div>
     );
   }
 }

 const isRoll = (productType) => {
   return (productType === 'Carpet' || productType === 'Vinyl');
 }

 const isPlank = (productType) => {
   return (productType === 'Wood' || productType === 'Vinyl Plank' || productType === 'Laminate');
 }

 const isTile = (productType) => {
   return (productType.includes('Tile'));
 }

 const rooms = (refigure) => {
   let roomData  = refigure.rooms.length === 0 ? [] : refigure.rooms.map((curRoom,index) => {
       return {
         id           : index,
         roomName     : curRoom.roomName   ,
         isRoll       : isRoll (curRoom.productType),
         isTile       : isTile (curRoom.productType),
         isPlank      : isPlank(curRoom.productType),
         orderArea    : curRoom.quantity            ,
       }
     });
    return roomData;
 }



 function mapStateToProps (state, ownProps) {
   const parameterId  = ownProps.match.params.refigureId.toString();
   const refigureId   = parseInt(parameterId.charAt(parameterId.length - 1));
   const projectId    = parameterId.substring(0,parameterId.length - 1);
   const wrkProject   = state.projects.filter(curProject => curProject.projectId === projectId);
   const refigureList = wrkProject.length > 0 ? wrkProject[0].refigures : [];
   const wrkRefigure  = refigureList.length > 0 ? refigureList.filter(curRefigure => curRefigure.version === refigureId)
                                                : [];
   let productData  = wrkRefigure.length ===  0 ? [] : wrkRefigure[0].rooms.map((curRoom,index) => {
       return {
         id           : index                                                                             ,
         roomName     : curRoom.roomName                                                                  ,
         description  : `${ curRoom.productType } - ${ curRoom.productStyle } - ${ curRoom.productColor }`,
         type         : curRoom.productType                                                               ,
         style        : curRoom.productStyle                                                              ,
         color        : curRoom.productColor                                                              ,
         isRoll       : isRoll (curRoom.productType)                                                      ,
         isTile       : isTile (curRoom.productType)                                                      ,
         isPlank      : isPlank(curRoom.productType)                                                      ,
         orderArea    : curRoom.quantity                                                                  ,
         rooms        : rooms(wrkRefigure[0])                                                             ,
       }
     });

     console.log('products = ' , wrkRefigure);
   return {  refigure : wrkRefigure.length > 0 ? wrkRefigure[0] : [],
             products : productData,
             project  : wrkProject.length  > 0 ? wrkProject[0]  : [],
          };
 }

 const mapDispatchToProps = dispatch => ({
   //loadRefigures: (projectId) => { return dispatch(projectActions.loadRefigures(projectId)); }
 });

export default connect(mapStateToProps, mapDispatchToProps)(RefigureDetails);
