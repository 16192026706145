import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import * as refigureActions  from '../../actions/refigureActions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Badge,Table,Row, Col, Button, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { showWaitScreen } from '../common/WaitScreen';
//import delay from './delay';
import { FaEdit, FaBatteryHalf, FaSearch, FaEye, FaPhone, FaEnvelope, FaSms } from 'react-icons/fa';
import { MdTextsms } from 'react-icons/md';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import * as custom from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';


// -------------------------------------------
// --- Search Mechanism Constant Variables ---
// -------------------------------------------
const { SearchBar } = Search;
const brandAlert     = '#f50057';


// ------------------------------------
// --- Table Properties / Utilities ---
// ------------------------------------
const tableColumns = [
  "Job Age",
  "Measure Date",
  "Alerts",
  "Store #",
  "Prospect",
  "Sales Status",
  "Sales Associate",
  "Notes",
  "Actions"
];

const rowActionOptions = [
  'Actions',
  'Create New Entry',
  'Change Status',
  'Make a Refigure',
];

const formatDate = (date) => {
  let valReturn = moment(date);
  valReturn = `${valReturn.format('MMM D YYYY')}`;
  return valReturn;
}

const statusUtilities = {
  WaitingForMeasure : {
    display: 'Measure Pending',
    value  : 'Measure Pending'
  },
  WaitingForCustomerAttention : {
    display: 'Awaiting Customer Response',
    value  : 'Awaiting Customer Response'
  },
  WaitingForRefigurePreparation : {
    display: 'Refigure Pending',
    value  : 'Refigure Pending'
  },
  NoSalesAttention : {
    display: 'New Detail in IMS',
    value  : 'New Detail in IMS'
  },
  WaitingForSalesAttention : {
    display: 'Sales Attention Required',
    value  : 'Sales Attention Required'
  },
  WaitingForSalesAttentionRefigure : {
    display: 'Completed Refigure',
    value  : 'Completed Refigure'
  },

}

const statusDisplay = (status) => {
  switch (status) {
    case statusUtilities.WaitingForMeasure.value :
      return statusUtilities.WaitingForMeasure.display;
      break;
    case statusUtilities.WaitingForCustomerAttention.value :
      return statusUtilities.WaitingForCustomerAttention.display;
      break;
    case statusUtilities.WaitingForRefigurePreparation.value :
      return statusUtilities.WaitingForRefigurePreparation.display;
      break;
    case statusUtilities.NoSalesAttention.value :
      return statusUtilities.NoSalesAttention.display;
      break;
    case statusUtilities.WaitingForSalesAttention.value :
      return statusUtilities.WaitingForSalesAttention.display;
      break;
    case statusUtilities.WaitingForSalesAttentionRefigure.value :
      return statusUtilities.WaitingForSalesAttentionRefigure.display;
      break;
    default:

  }
}

// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  cardHeader         : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  cardRow            : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  projectInfo        : { margin          : '0 -1.25rem 1.25rem -1.25rem', padding          : '0 1.25rem 1.25rem 1.25rem', borderBottom : '1px solid #e3e8ec' },
  versionBtn         : { marginRight     : '1.25rem'                    , borderRadius     : '2px',fontFamily  : 'Segoe Print'                , fontSize       : '12px'      },
  RefCardTitleStyle  : { fontFamily      : 'Segoe Print'                , fontSize         : '1.15rem' },
  RefButtonStyle     : { fontFamily      : 'Segoe Print'                , fontSize         : '12px', borderRadius   : '4px'  },
  RefCardStyle       : { backgroundColor : 'rgba(0,0,0,0.1)'            , marginTop        :'30px' , padding: '10px'},
  tableHeader        : { textAlign       : 'center'},
  tableRow           : { textAlign       : 'center' , color:brandAlert}
};

const setRowStyle = (hasAlert) => {
  if (hasAlert) {
    return { textAlign :'center', color:brandAlert };
  }
  return { textAlign :'center' };
}


// ----------------------------
// --- Main Component Class ---
// ----------------------------
class ContactLog extends Component {

  constructor(props,context) {
    super(props,context);


    this.state = {
      dashboardData:[],
      isLoading:false,
      rowActionSelected:rowActionOptions[0]
    };
  }

  componentDidMount = () => {
    this.setState( { dashboardData : this.props.dashboardData } )
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState( { dashboardData : this.props.dashboardData } )
    }
  }

  pageRedirect = () => {
    if (this.props.refigures.length === 0) {
      return <Redirect to='/projects' />
    }
  }

  // --- Search Method Selection
  handleRowActionSelection = (event) => {
    //this.setState({rowActionSelected : event.target.value})
  }


  // --- Render The Component
  render() {
    // --- Redirect To Projects If Refigure List Is Empty. Mainly Done In Case They Reload From This Page
    if (!this.props.isDataLoaded) {
      return <Redirect to='/dashboard' />
    }

    // --- This Set The Properties of thwe table when selected. It Also calls the The Action Handler When The Table Is Selected
    const selectRow = {
      mode             : 'checkbox',
      bgColor          : 'rgba(85, 201, 255, 0.2)',
      clickToSelect    : true,
      hideSelectAll    : true,
      hideSelectColumn : true,
      selected         : this.state.selectedRows,
      onSelect         : this.handleOnSelect,
    };

    const expandRow = {
      renderer: this.handleExpandRow,
      expanded: this.state.selectedRows, // This Represents The Current Selected Row
    };

    // --- Return The Formatted Page
    return ( this.state.isLoading ? showWaitScreen() :
      <div className='animated'>
        <Card>
          <CardHeader style={styles.cardRow}>
            <i className='material-icons md-18'>chevron_left</i>
            <Link to='/dashboard'>Dashboard</Link>
          </CardHeader>
            <CardBody>
              <div style={styles.projectInfo}>
                <h2 style={{textAlign:'center', fontWeight:'bold',fontStyle:'italic'}}>Lowe's Prospects ( {this.state.dashboardData.length} )</h2>
                <Row style={{marginTop:"30px"}}>
                  <Table>
                    <thead>
                      <tr>
                        {
                          tableColumns.map((curColumn, i) =>
                            <th key = {i}  style={styles.tableHeader}> { curColumn } </th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                     {
                        this.state.dashboardData.map((curData,i) =>
                          <tr key = {i}>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              { curData.projectAge } {curData.projectAge > 1 ? 'Days' : 'Day'}
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              { curData.projectMeasureDate }
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              <Button color="link">
                                <Link style= {{textDecoration:'none'}} to={`/alerts` }>
                                  { curData.projectAlert.length > 0 ? 'Yes' : 'No' }
                                </Link>
                              </Button>
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              { curData.storeNumber }
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              <Button color="link" style={{padding:'5px'}}><FaPhone    style={{marginBottom:'5px'}}/></Button>
                              <Button color="link" style={{padding:'5px'}}><MdTextsms  style={{marginBottom:'5px'}}/></Button>
                              <Button color="link" style={{padding:'5px'}}><FaEnvelope style={{marginBottom:'5px'}}/></Button> { curData.customerNameLast } , { curData.customerNameFirst}
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              { statusDisplay(curData.projectStatus) }
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              { curData.salesPersonNumber }
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              <Button color="link">
                                {/*<Link style= {{textDecoration:'none'}} to={`/#/${'Waiting for Refigure Preparation'}` }>
                                  { 'No' }
                                </Link>*/}
                                { 'No' }
                              </Button>
                            </td>
                            <td style={setRowStyle(curData.projectAlert.length > 0)}>
                              <Select
                                value={this.state.rowActionSelected}
                                onChange={this.handleRowActionSelection}
                                displayEmpty
                              >
                                <MenuItem value='Actions'>
                                  Actions
                                </MenuItem>
                                <MenuItem value='CreateRefigure'>
                                  {/*<Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curData.projectId}` }>
                                    <FaEdit /> Create New Entry Log
                                  </Link>*/}
                                  <FaEdit /> Create New Entry Log
                                </MenuItem>
                                <MenuItem value='CreateRefigure'>
                                  {/*<Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curData.projectId}` }>
                                    <FaEdit /> Create Refigure
                                  </Link>*/}
                                  <FaEdit /> Create Refigure
                                </MenuItem>
                                <MenuItem value='ChangeStatus'>
                                  {/*<Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${curData.projectId}` }>
                                    <FaBatteryHalf /> Change Status
                                  </Link>*/}
                                  <FaBatteryHalf /> Change Status
                                </MenuItem>
                              </Select>
                            </td>
                          </tr>
                        )
                     }
                    </tbody>
                  </Table>
                </Row>
              </div>
            </CardBody>
        </Card>

      </div>
    );
  }
}

function compare(a, b) {
  const genreA = parseInt(a.projectAge, 10);
  const genreB = parseInt(b.projectAge, 10);
  let comparison = 0;
  if (genreA < genreB) {
    comparison = 1;
  }
  else if (genreA > genreB) {
    comparison = -1;
  }
  return comparison;
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state, ownProps) {
  let data = state.dashboardData.filter(curData => curData.projectStatus === ownProps.match.params.status);
  data.sort(compare);
  return { dashboardData : data,
           isDataLoaded  : data.length > 0
         };
}

const mapDispatchToProps = dispatch => ({
  loadRefigures: (projectId) => { return dispatch(refigureActions.loadRefigures(projectId)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(ContactLog);
