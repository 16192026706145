import React from 'react';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span><a href="https://floorsoft.com">Refigurator</a> &copy; 2018 FloorSoft, Inc.</span>
      <span className="ml-auto">Powered by <a href="https://floorsoft.com">FloorSoft</a></span>
    </footer>
  );
};

export default Footer;
