import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
//import { bindActionCreators } from "redux";
//import moment from 'moment';
import * as refigureActions  from '../../actions/refigureActions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Badge,Table,Row, Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { showWaitScreen } from '../common/WaitScreen';
import delay from './delay';
import { FaAngleDoubleLeft,FaEdit, FaBatteryHalf, FaSearch, FaEye, FaPhone, FaEnvelope } from 'react-icons/fa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import { ButtonGroup } from 'reactstrap';

import Button from '@material-ui/core/Button';


//import { withStyles } from '@material-ui/core/styles';
import * as custom from '@material-ui/core';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
//import * as customButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';

// -----------------------------------------
// --- This Are The Columns On The Table ---
// -----------------------------------------
const tableColumns = [
  { dataField: 'refigureId'  , text: 'ID'         , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'refigureName', text: 'Description', headerAlign:'center', align: 'center', sort: true },
  { dataField: 'cntFlooring' , text: 'Products'   , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'cntRoom'     , text: 'Rooms'      , headerAlign:'center', align: 'center', sort: true },
];

const tableColumns2 = [
  "Description",
  "Products",
  "Rooms",
  "",
];



const rowActionOptions = [
  'Actions',
  'Create Refigure',
  'Change Status',
  'View Sales Documents',
  'View FloorPlan',
];


// --------------------------------------------------
// --- Default Settings & Properties Of The Table ---
// --------------------------------------------------
const defaultSorted = [{
  dataField: 'refigureId',
  order: 'asc'
}];

const ExpandedRow = ({refigureId,isSelect}) => {
  return (
    <div>
      {refigureId &&
        <Button color="info">
          <Link style= {{color: 'white'}} to={`/refigureDetails/${refigureId}` }>
            View Refigure Details
          </Link>
        </Button>
      }
    </div>
  )
};

// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  cardHeader         : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  cardRow            : { display         : 'inline-flex'                , justifyContent   : 'flex-start'               , alignItems   : 'center' },
  projectInfo        : { margin          : '0 -1.25rem 1.25rem -1.25rem', padding          : '0 1.25rem 1.25rem 1.25rem', borderBottom : '1px solid #e3e8ec' },
  versionBtn         : { marginRight     : '1.25rem'                    , borderRadius     : '2px',fontFamily  : 'Segoe Print'                , fontSize       : '12px'      },
  RefCardTitleStyle  : { fontFamily      : 'Segoe Print'                , fontSize         : '1.15rem' },
  RefButtonStyle     : { fontFamily      : 'Segoe Print'                , fontSize         : '12px', borderRadius   : '4px'  },
  RefCardStyle       : { backgroundColor : 'rgba(0,0,0,0.1)'            , marginTop        :'30px' , padding: '10px'},
  tableHeader        : { textAlign       : 'center'},
  tableRow           : { textAlign       : 'center'}
};

const getProductCount = (rooms) => {
  let lstRoom = [];
  for (let i = 0; i < rooms.length; i++) {
    const curRoom = rooms[i];
    if (!lstRoom.includes(curRoom.productType))
      lstRoom.push(curRoom);
  }
  return lstRoom.length;
}

const getProjectHeaderDisplay = (project) => {
  let   projectId   = (project.projectId != "" ? project.projectId : "");
  let   salesPerson = (project.salesPersonName    != "" ? project.salesPersonName    : "");
  let   storeName   = (project.owningAccountName  != "" ? project.owningAccountName  : "");
  const valReturn   = (projectId != "" ? projectId + " | " : "") + (salesPerson != "" ? salesPerson : "");
  return valReturn;
}

const customerNameDisplay  = (project) => { return (project.customerFirstName != "" ? project.customerFirstName : "") + (project.customerLastName != "" ? project.customerLastName : "") }
const customerPhoneDisplay = (project) => { return (project.customerPhone     != "" ? project.customerPhone     : "");                                                                   }
const customerEmailDisplay = (project) => { return (project.customerEmail     != "" ? project.customerEmail     : "");                                                                   }

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class Project extends Component {
  constructor(props,context) {
    super(props,context);


    this.state = {
      selectedRows: [], // This Keeps Track Of The Selected Row(s)
      refigureData:[],
      isLoading:true,
      rowActionSelected:rowActionOptions[0],
      showToast: false,
    };
  }

  componentDidMount = () => {
    //this.props.loadRefigures(this.props.projectId);
    this.setState({refigureData : this.props.refigures})

  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState({refigureData : this.props.refigures})
      //this.setState( { refigureData : this.props.refigures, isLoading : this.props.refigures == 0 } )
    }
  }

  // --- This Handles The Action of The Table Everytime It Is Clicked
  handleOnSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) { this.setState({ selectedRows: [row.refigureId], }); }
    else          { this.setState({ selectedRows: [],               }); }
  }

  handleRowActionSelection = (event) => {
    this.setState({rowActionSelected : event.target.value})
  }


  // --- Render The Component
  render() {
    const { project, projectId } = this.props;
    const { refigureData       } = this.state;

    console.log('projectRefigure @@@@@@@@@@@@@data = ' , refigureData);

    // --- Redirect To Projects If Refigure List Is Empty. Mainly Done In Case They Reload From This Page
    if (!project || this.props.refigures.length == 0) {
      return <Redirect to='/projects' />
    }

    // --- This Set The Properties of thwe table when selected. It Also calls the The Action Handler When The Table Is Selected
    const selectRow = {
      mode             : 'checkbox',
      bgColor          : 'rgba(85, 201, 255, 0.2)',
      clickToSelect    : true,
      hideSelectAll    : true,
      hideSelectColumn : false,
      selected         : this.state.selectedRows,
      onSelect         : this.handleOnSelect,
    };

    // --- Return The Formatted Page
    return ( 
      <div className='animated'>
        <Card>
          <CardHeader style={styles.cardRow}>
            <span><FaAngleDoubleLeft/>  <Link to='/projects'>Projects</Link></span>
          </CardHeader>
          { project && refigureData.length > 0 &&
            <CardBody>
                <Row>
                  <Col xs='12' style={styles.cardRow}>
                    <span>
                      <b>Project</b>:  { getProjectHeaderDisplay(this.props.project) }
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs='12' style={styles.cardRow}>
                    <span>
                      <b>Customer</b>:  { customerNameDisplay (project) != "" ? customerNameDisplay (project) + " | " : ""    }
                            <FaPhone/>  { customerPhoneDisplay(project) != "" ? customerPhoneDisplay(project) + " | " : "N/A" }
                         <FaEnvelope/>  { customerEmailDisplay(project) != "" ? customerEmailDisplay(project) + " | " : "N/A" }
                    </span>
                  </Col>
                </Row>
                <Row style={{marginTop:"10px"}}>
                  <Table striped>
                    <thead>
                      <tr>
                        {
                          tableColumns.map((curColumn, i) =>
                            <th key = {i}  style={styles.tableHeader}> { curColumn.text } </th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                     {
                        refigureData.map((curRefigure,i) =>
                          <tr key = {i}>
                            <td style={styles.tableRow}> { curRefigure.version                } </td>
                            <td style={styles.tableRow}> { curRefigure.name                   } </td>
                            <td style={styles.tableRow}> { getProductCount(curRefigure.rooms) } </td>
                            <td style={styles.tableRow}> { curRefigure.rooms.length           } </td>
                            <td style={styles.tableRow}>
                              <Select
                                value={this.state.rowActionSelected}
                                onChange={this.handleRowActionSelection}
                                displayEmpty
                              >
                                <MenuItem value='Actions'>
                                  Actions
                                </MenuItem>
                                <MenuItem value='ChangeStatus'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${this.props.projectId}${curRefigure.version}` }>
                                    <FaEdit /> Edit Refigure
                                  </Link>
                                </MenuItem>
                                {/*<MenuItem value='CreateRefigure'>
                                  <Link style = {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} 
                                        to    = { `/refigureDetails/${this.props.projectId}${curRefigure.version}` }>
                                    <FaEdit /> Create Refigure
                                  </Link>
                                </MenuItem>
                                <MenuItem value='ChangeStatus'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${this.props.projectId}${curRefigure.version}` }>
                                    <FaBatteryHalf /> Change Status
                                  </Link>
                                </MenuItem>
                                <MenuItem value='ViewSalesDocs'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${this.props.projectId}${curRefigure.version}` }>
                                    <FaEye /> View Sales Documents
                                  </Link>
                                </MenuItem>
                                <MenuItem value='ViewFloorplan'>
                                  <Link style= {{color:'black', fontSize:'17px', textDecoration: 'none',padding:'10px'}} to={ `/refigureDetails/${this.props.projectId}${curRefigure.version}` }>
                                    <FaEye /> View FloorPlan
                                  </Link>
                                </MenuItem> */}
                              </Select>
                            </td>
                          </tr>
                        )
                     }
                    </tbody>
                  </Table>
                </Row>
            </CardBody>
          }
        </Card>

      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state, ownProps) {
  //const wrkProjectId = parseInt(ownProps.match.params.id, 10);
  const wrkProjectId = ownProps.match.params.id.toString();
  const wrkProject   = state.projects.filter(curProject => curProject.projectId === wrkProjectId);
  // --- This Will Create The Data That Will Fill The Rows Of The Table
  const refigureList = wrkProject.length > 0 ? wrkProject[0].refigures : [];
  return {  projectId : parseInt(wrkProjectId,10),
            project   : wrkProject[0],
            refigures : refigureList
         };
}

const mapDispatchToProps = dispatch => ({
  loadRefigures: (projectId) => { return dispatch(refigureActions.loadRefigures(projectId)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(Project);
