export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Projects',
      url: '/projects',
      icon: 'icon-layers',
    },
    {
      name: 'Statistics',
      url: '/stats',
      icon: 'icon-pie-chart',
    },
    {
      name: 'Statuses',
      url: '/status',
      icon: 'fa fa-battery-three-quarters',
    },
    //{
    //  name: 'Test',
    //  url: '/test',
    //  icon: 'fa fa-battery-three-quarters',
    //},
    {
      name: 'Help Center',
      url: '/help',
      icon: 'icon-question',
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'icon-wrench',
    },
  ]
};
