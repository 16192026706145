import React from 'react';
import PropTypes from 'prop-types'
// import Input from './TextInput';
import {
  Button,
  Form,
  FormGroup,
  // FormText,
  Label,
  Input,
} from 'reactstrap';

const LoginForm = ({ user, handleChange, handleSubmit }) => {
  return (
    <Form>
      <FormGroup>
        <Label for='username'>Username</Label>
        <Input
          type='text'
          name='username'
          id='username'
          placeholder='Username'
          value={user.username}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for='password'>Password</Label>
        <Input
          type='password'
          name='password'
          id='password'
          placeholder='Password'
          value={user.password}
          onChange={handleChange}
        />
      </FormGroup>
      <Button onClick={handleSubmit}>Login</Button>
    </Form>
  );
};

LoginForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default LoginForm;
