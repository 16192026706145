import { connect               } from 'react-redux'
//import { bindActionCreators    } from "redux";
//import { loginUser, logoutUser } from '../actions/userActions';
import { loginUser             } from '../actions/userActions';
import App                       from '../components/App';

// --------------------------------------
// --- Gets State To Get Data/Results ---
// --------------------------------------
const mapStateToProps = state => {
  const { auth                          } = state;
  const { isAuthenticated, errorMessage } = auth;
  return { isAuthenticated, errorMessage, };
};

// --------------------------------
// --- Dispatch Action To Props ---
// --------------------------------
const mapDispatchToProps = dispatch => ({
  onLoginUser: (user) => { return dispatch(loginUser(user)); }
});

/*const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      onLoginUser     : (user     ) => dispatch(loginUser     (user     )),
      onLogoutUser    : (         ) => dispatch(logoutUser    (         )),
      onGetProjectById: (projectId) => dispatch(getProjectById(projectId)),
    }, dispatch)
  }
};*/

const Refigurator = connect(mapStateToProps,mapDispatchToProps)(App);

export default Refigurator;
