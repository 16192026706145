import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import * as refigureActions from '../../actions/refigureActions';
import * as projectActions from '../../actions/projectActions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { Table , Row, Col, Card,Dropdown, DropdownToggle, DropdownMenu, DropdownItem,CardBody, Input } from 'reactstrap';
import { MuiPickersUtilsProvider, InlineDatePicker, DatePicker  } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Snackbar from '@material-ui/core/Snackbar';
import delay from './delay';
import { showWaitScreen } from './../common/WaitScreen';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FaSearch, FaEye, FaEdit, FaClipboardList, FaUserClock, FaBell } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


// -------------------------
// --- Search Mechanism  ---
// -------------------------
const projectsToLowerCase = (projects) => {
  const projectList = projects.map(curProject => {
    return {
      projectId          : curProject.projectId                               ,
      customerName       : `${ curProject.customerName                            }`,
      lowesProjectId     : `${ curProject.lowesProjectId                          }`,
      projectStatus      : `${ curProject.projectStatus                           }`,
      salesAssociate     : `${ curProject.salesAssociate                          }`,
      store              : `${ curProject.store                                   }`,
      refigureCount      : `${ curProject.refigureCount                           }`,
      filtCustomerName   : `${ curProject.customerName  .toString().toLowerCase() }`,
      filtLowesProjectId : `${ curProject.projectId     .toString().toLowerCase() }`,
      filtProjectStatus  : `${ curProject.projectStatus .toString().toLowerCase() }`,
      filtSalesAssociate : `${ curProject.salesAssociate.toString().toLowerCase() }`,
      filtStore          : `${ curProject.store         .toString().toLowerCase() }`,
    }
  });
  return projectList;
}

const searchMethodOptions = [
  'Customer Name',
  "Lowe's Project #",
  'Project Status',
  'Sales Associate',
  'Store',
];

// -----------------------------------------
// --- This Are The Columns On The Table ---
// -----------------------------------------
const tableColumns = [
  { dataField: 'customerName'  , text: 'Customer Name'    , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'projectId'     , text: "Lowe's Project #" , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'projectStatus' , text: 'Project Status'   , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'salesAssociate', text: 'Sales Associate'  , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'store'         , text: 'Store'            , headerAlign:'center', align: 'center', sort: true },
  { dataField: 'refigureCount' , text: 'Refigures'        , headerAlign:'center', align: 'center', sort: true },
];

// --------------------------------------------------
// --- Default Settings & Properties Of The Table ---
// --------------------------------------------------
const defaultSorted = [{
  dataField: 'latestActivity',
  order: 'asc'
}];

// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  cardRange : { backgroundColor : 'rgb(245, 245, 245)'                             },
  cardData  : { backgroundColor : 'rgb(245, 245, 245)'                             },
  cardDataLoading : { backgroundColor : 'rgba(0, 0, 0,0.7)'                             },
  rowRange  : { padding         : '20px'                                           },
  colRange  : { display         : 'flex'  , alignItems : 'center'                  },
  btnSearch : { borderRadius    : '5px'   , height     : '40px'  },
  h5Range   : { textAlign       : 'center', marginTop  : '20px'                    }
};

// ----------------------------
// --- Main Component Class ---
// ----------------------------
class Projects extends Component {
  constructor(props,context) {
    super(props,context);

    this.state = {
      selectedRows: [], // This Keeps Track Of The Selected Row(s)
      projectData: [],
      showToast: false,
      toastMessage: '',
      isLoading:true,
      searchMethodDropdownOpen  : false,
      searchMethodRadioSelected : 0,
      tabValue                  : 0,
      searchMethodSelected      :searchMethodOptions[0],
      fromDate                  : moment().tz(moment.tz.guess()).subtract(4,'years').format(), // Default From Date (30 Days Ago)
      toDate                    : moment().tz(moment.tz.guess()).format(), // Default Date (Current Date)
      soldCheckbox              : false,  // default value for sold projects checkbox
    };
  }

  // --- This is the component main load/mount
  componentDidMount = () => {
    this.props.loadProjects(moment(this.state.fromDate).format('YYYY-MM-DD'),moment(this.state.toDate).format('YYYY-MM-DD'));
  }

  // --- This is the component unload/unmount
  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState( { projectData : this.props.projects.filter(curProject => !curProject.projectStatus.includes('Sold')), isLoading : this.props.projects.length == 0 } )
      //this.props.projects.filter(curProject => !curProject.projectStatus.includes('Sold'));
//this.setState({ soldCheckbox: event.target.checked, projectData : projectList });

    }
  }

  // --- Table Action Listeners
  handleTableSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) { this.setState({ selectedRows: [row.projectId], }); }
    else          { this.setState({ selectedRows: [],              }); }
    //this.props.loadRefigures(row.projectId);
  }

  handleTableExpandRow = (row,isSelect) => {
    if (row) {
      console.log('row = ' , row);
      return (
        <div style={{marginLeft:'20px'}}>
          <Row>
            <Col lg="2">
              { row.refigureCount == 0 ?
                  <Button variant="contained" disabled>
                    <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }>
                      <span><FaEye/> View Refigures </span>
                    </Link>
                  </Button>
                :
                  <Button variant="contained" color="primary">
                    <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/projectRefigures/${row.projectId}` }>
                      <span><FaEye/> View Refigures </span>
                    </Link>
                  </Button>
              }
            </Col>
            <Col lg="2">
              <Button variant="contained" color="primary" className='w-100'>
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/create-refigure-menu/${row.projectId}` }>
                  <span><FaEdit/> Create Refigures</span>
                </Link>
              </Button>
            </Col>
            <Col lg="2">
              <Button variant="contained" color="primary" className='w-100' >
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/create-refigure-menu/${row.projectId}` }>
                  <span><FaUserClock/> Contact Log</span>
                </Link>
              </Button>
            </Col>
            <Col lg="2">
              <Button variant="contained" color="primary" className='w-100'>
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/create-refigure-menu/${row.projectId}` }>
                  <span><FaClipboardList/> Notes</span>
                </Link>
              </Button>
            </Col>
            <Col lg="2">
              <Button variant="contained" color="primary" className='w-100'>
                <Link style= {{color: 'white',textDecoration:'none', fontSize:'1em'}} to={`/create-refigure-menu/${row.projectId}` }>
                  <span><FaBell/> Alerts</span>
                </Link>
              </Button>
            </Col>
          </Row>
        </div>
      )
    }
  }

  // --- Date Pickers Action Listener
  handleDatePickerFromDateChange = (date) => {
    if (moment(this.state.toDate).isAfter(moment(date))) {
      this.setState({ fromDate : date.format() });
    }
    else {
      this.setState({showToast:true,toastMessage:'Start Date Has To Be Before End Date!'})
    }
  };

  handleDatePickerToDateChange = (date) => {
    if (moment(this.state.fromDate).isBefore(moment(date))) {
      this.setState({ toDate : date.format() });
    }
    else {
      this.setState({showToast:true,toastMessage:'Start Date Has To Be Before End Date!'})
    }
  };

  // --- Project Find Button Action Listener
  handleFindSelect = () => {
    let fromDate = moment(this.state.fromDate);
    let toDate   = moment(this.state.toDate  );
    this.props.loadProjects(fromDate.format('YYYY-MM-DD'),toDate.format('YYYY-MM-DD'));
    this.setState({ isLoading : true });
  };

  // --- Notification Toast Action Listener
  handleToastClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    this.setState({ showToast: false });
  };

  // --- Search Method Selection
  handleSearchMethodSelection = (event) => {
    this.setState({searchMethodSelected : event.target.value})
  }

  // --- Search TextFiled Listener
  handleSearchInput = (event) => {
    //let selFilterOption = this.state.searchMethodSelected;
    let projectList     = [];
    switch (this.state.searchMethodSelected) {
      case "Customer Name":
        projectList = projectsToLowerCase(this.props.projects).filter(curProject => curProject.filtCustomerName.includes(event.target.value.toLowerCase()));
        this.setState({ projectData : projectList });
        return;
      case "Lowe's Project #":
        projectList = projectsToLowerCase(this.props.projects).filter(curProject => curProject.filtLowesProjectId.includes(event.target.value.toLowerCase()));
        this.setState({ projectData : projectList });
        return;
      case "Project Status":
        projectList = projectsToLowerCase(this.props.projects).filter(curProject => curProject.filtProjectStatus.includes(event.target.value.toLowerCase()));
        this.setState({ projectData : projectList });
        return;
      case "Sales Associate":
        projectList = projectsToLowerCase(this.props.projects).filter(curProject => curProject.filtSalesAssociate.includes(event.target.value.toLowerCase()));
        this.setState({ projectData : projectList });
        return;
      case "Store":
        projectList = projectsToLowerCase(this.props.projects).filter(curProject => curProject.filtStore.includes(event.target.value.toLowerCase()));
        this.setState({ projectData : projectList });
        return;
      default:
        projectList = this.props.projects;
        this.setState({ projectData : projectList });
        return;
    }
  }

      // --- Sold Projects Checkbox
    handleCheckboxChange = (event) => {
      let projectList = (!event.target.checked) ? projectsToLowerCase(this.props.projects).filter(curProject => !curProject.projectStatus.includes('Sold'))
                                                : projectsToLowerCase(this.props.projects);
      this.setState({ soldCheckbox: event.target.checked, projectData : projectList });
    };


  // --- Render The Component
  render() {
    const { projects                  } = this.props;
    const { selectedRows, projectData } = this.state;
    console.log(projectData);
    // --- This Set The Properties of the table when selected. It Also calls the The Action Handler When The Table Is Selected
    const selectRow = {
      mode             : 'checkbox',
      bgColor          : 'rgba(85, 201, 255, 0.2)',
      clickToSelect    : true,
      hideSelectAll    : true,
      hideSelectColumn : false,
      selected         : selectedRows,
      onSelect         : this.handleTableSelect,
    };

    const expandRow = {
      renderer: this.handleTableExpandRow,
      expanded: selectedRows, // This Represents The Current Selected Row
    };



    // --- Return The Formatted Page
    return ( 
      <div className="animated">
        <Snackbar
          anchorOrigin     = { { vertical: 'top', horizontal: 'center', } }
          open             = { this.state.showToast }
          autoHideDuration = { 6000 }
          onClose          = { this.handleToastClose }
          message          = { <span id="message-id">{ this.state.toastMessage }</span> }
        >
        </Snackbar>
        <Card style = { styles.cardRange }>
          <h5 style = { styles.h5Range }> Project Range </h5>
          <Row style={{padding:"10px"}}>
            <Col lg="3"></Col>
            <Col lg="6">
              <MuiPickersUtilsProvider utils={ MomentUtils }>
                <Grid container>
                  <Grid item lg={5} style={{padding:"10px"}}>
                    <InlineDatePicker
                      keyboard
                      clearable
                      variant  = "outlined"
                      label    = "Start Date"
                      value    = { this.state.fromDate }
                      onChange = { this.handleDatePickerFromDateChange }
                      format   = 'MM/DD/YYYY'
                      mask     = { [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] }
                    />
                  </Grid>
                  <Grid item lg={5} style={{padding:"10px"}}>
                    <InlineDatePicker
                      keyboard
                      clearable
                      variant  = "outlined"
                      label    = "End Date"
                      value    = { this.state.toDate }
                      onChange = { this.handleDatePickerToDateChange }
                      format='MM/DD/YYYY'
                      mask={ [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] }
                    />
                  </Grid>
                  <Grid item lg={2} style={{padding:"15px 20px 0px 0px"}}>
                    <Button  variant="contained" color="primary" style = { styles.btnSearch } onClick = { this.handleFindSelect }>
                      <span><FaSearch/>  Find</span>
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Col>
            <Col lg="3"></Col>
          </Row>
        </Card>
        <Card style = {styles.cardData}>
          <CardBody>
            <Row>
              <Col lg="1" md="2" sm="2" style={{marginBottom:5, marginRight:30}}>
                <Select
                  value={this.state.searchMethodSelected}
                  onChange={this.handleSearchMethodSelection}
                  displayEmpty
                >
                  {
                    searchMethodOptions.map((option, i) =>
                      <MenuItem
                        key={i}
                        value={searchMethodOptions[i]}> {searchMethodOptions[i]} </MenuItem>
                    )
                  }
                </Select>
              </Col>
              <Col sm="2" style={{marginBottom:5, justifyContent:'right'}}>
                <FormControlLabel
                  control={<Checkbox style={{marginLeft:20}} checked={this.state.soldCheckbox} onClick={this.handleCheckboxChange} name="soldCheckbox" color="primary"/>}
                  label="Include Sold Projects"
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" style= {{marginBottom:"10px"}}>
                <Input placeholder="Search" onInput={this.handleSearchInput} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="12">
                <ToolkitProvider
                  bootstrap4
                  keyField = "projectId"
                  data     = { projectData }
                  columns  = { tableColumns }
                  >
                  {
                    props => (
                      <div>
                        <BootstrapTable
                          striped
                          hover
                          defaultSorted    = { defaultSorted }
                          bordered         = { false }
                          noDataIndication = "No projects!"
                          selectRow        = { selectRow }
                          expandRow        = { expandRow }
                          pagination       = { paginationFactory() }
                          { ...props.baseProps }
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "Projects" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  // --- This Will Create The Data That Will Fill The Rows Of The Table
  const projects = state.projects.map(curProject => {
    // --- Format The Date From Each Project Coming From The Store
    let date = moment(curProject.createDate);
    date = `${date.format('MMM D YYYY')} at ${date.format('h:mm:ss a')}`;
    // --- Return The Newly Formatted Array
    const projectId = parseInt(curProject.projectId, 10);
    return {
      customerName       : `${ curProject.customerLastName                                              } , ${curProject.customerFirstName}`,
      projectId          : projectId                                                                ,
      projectStatus      : `${ curProject.projectStatus                                                 }`,
      lowesProjectId     : `${ curProject.lowesProjectNumber ? curProject.lowesProjectNumber : '\u2014' }`,
      salesAssociate     : `${ curProject.salesPersonName    ? curProject.salesPersonName    : '\u2014' }`,
      store              : `${ curProject.owningAccountName  ? curProject.owningAccountName  : '\u2014' }`,
      dateCreated        : `${ date                                                                     }`,
      retailOrderNumber  : `${ curProject.retailOrderNumber  ? curProject.retailOrderNumber  : '\u2014' }`,
      refigureCount      : curProject.refigures.length
    }
  });

  return {  projects : projects };
}

const mapDispatchToProps = dispatch => ({
  loadRefigures : (projectId       ) => { return dispatch(refigureActions.loadRefigures(projectId     )); },
  loadProjects  : (fromDate, toDate) => { return dispatch(projectActions.projectSearch(fromDate,toDate)); }
});

export default connect(mapStateToProps,mapDispatchToProps)(Projects);
