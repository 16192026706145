import React from 'react';
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from 'reactstrap';
import { styles } from 'material-ui-pickers/wrappers/InlineWrapper';

const style = {
  storeNetButton : { backgroundColor: '#012169', marginTop:40  }
};

const LoginForm = ({ user, handleChange, handleSubmit }) => {
  return (
    <Form>
      <FormGroup>
        <InputGroup className='mb-3'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className='icon-user'></i>
            </span>
          </div>
          <Input
            type='text'
            name='username'
            id='username'
            placeholder='Username'
            value={user.username}
            onChange={handleChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className='mb-4'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className='icon-lock'></i>
            </span>
          </div>
          <Input
            type='password'
            name='password'
            id='password'
            placeholder='Password'
            value={user.password}
            onChange={handleChange}
          />
        </InputGroup>
      </FormGroup>
      <Row>
        <Col xs='6'>
          <Button color='primary' className='px-4' onClick={handleSubmit}>Login</Button>
        </Col>
        <Col xs='6' className='text-right'>
          <Button disabled color='link' className='px-0'>Forgot password?</Button>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <Button color='primary' style={style.storeNetButton} className='px-0 w-100'onClick={handleSubmit}>Sign In with StoreNet</Button>
        </Col>
      </Row>
    </Form>
  );
};

LoginForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default LoginForm;
